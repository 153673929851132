import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INTERNAL_SERVER_ERROR_MESSAGE, PERMISSION_ERROR_MESSAGE } from '../const'
import { push } from 'connected-react-router'
import { PolicyNumber } from '../types/policyNumber'
import {
  callFindPolicyNumber,
  callPostPolicyNumberInfo,
  callPutPolicyNumberInfo,
  PostPolicyNumberRequestParams,
  PutPolicyNumberRequestParams,
} from '../utils/apis/policyNumber'
import { ErrorResponse } from './error'
import { AppThunk } from './index'

export interface PolicyNumberState {
  isLoading: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  policyNumber?: PolicyNumber
  error?: ErrorResponse
}

export const PolicyNumberState: PolicyNumberState = {
  isLoading: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
}

const PolicyNumberModules = createSlice({
  name: 'policyNumber',
  initialState: PolicyNumberState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindPolicyNumberSuccess: (state, action: PayloadAction<PolicyNumber>) => ({
      ...state,
      isLoading: false,
      policyNumber: action.payload,
    }),
    callPutPolicyNumberInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callPostPolicyNumberInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isRegistSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetPolicyNumber: (state) => {
      const newState = { ...state }
      delete newState.policyNumber
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsRegistSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return PolicyNumberState
    },
  },
})

// 証券番号情報の取得処理
export const fetchGetPolicyNumber = (PolicyNumber: string): AppThunk => async (dispatch) => {
  try {
    dispatch(PolicyNumberModules.actions.resetError())
    dispatch(PolicyNumberModules.actions.callApiStart())
    const res = await callFindPolicyNumber(PolicyNumber)
    dispatch(PolicyNumberModules.actions.callFindPolicyNumberSuccess(res))

    dispatch(PolicyNumberModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    if (message == PERMISSION_ERROR_MESSAGE) {
      dispatch(push('/policy_numbers'))
      dispatch(PolicyNumberModules.actions.resetError())
      dispatch(PolicyNumberModules.actions.resetIsLoading())
    } else {
      dispatch(
        PolicyNumberModules.actions.callApiError({
          status: status,
          error: error,
          message: message,
        })
      )
    }
  }
}

// 証券番号情報の変更処理
export const fetchPutPolicyNumberInfo = (
  PolicyNumber: string,
  params: PutPolicyNumberRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(PolicyNumberModules.actions.resetError())
    dispatch(PolicyNumberModules.actions.callApiStart())
    await callPutPolicyNumberInfo(PolicyNumber, params)
    dispatch(PolicyNumberModules.actions.callPutPolicyNumberInfoSuccess())

    // 更新後に証券番号データをセットする
    const res = await callFindPolicyNumber(PolicyNumber)
    dispatch(PolicyNumberModules.actions.callFindPolicyNumberSuccess(res))
    dispatch(PolicyNumberModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutPolicyNumber error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      PolicyNumberModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 証券番号情報の登録処理
export const fetchPostPolicyNumberInfo = (
  params: PostPolicyNumberRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(PolicyNumberModules.actions.resetIsChangeSuccess())
    dispatch(PolicyNumberModules.actions.resetError())
    dispatch(PolicyNumberModules.actions.callApiStart())
    await callPostPolicyNumberInfo(params)
    dispatch(PolicyNumberModules.actions.callPostPolicyNumberInfoSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutPolicyNumber error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      PolicyNumberModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default PolicyNumberModules
