import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'

import { ErrorResponse } from './error'
import { QuestionAnswer } from '../types/questionAnswer'
import {
  callDeleteQuestionAnswer,
  callGetQuestionAnswer,
  callPostQuestionAnswer,
  callPutQuestionAnswer,
  CommonQuestionAnswerRequestParams,
  GetQuestionAnswerResponse,
} from '../utils/apis/questionAnswer'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'

export interface QuestionAnswerState {
  isLoading: boolean
  isCreateSuccess: boolean
  isUpdateSuccess: boolean
  isDeleteSuccess: boolean
  question_answer?: QuestionAnswer
  error?: ErrorResponse
}

const initialState: QuestionAnswerState = {
  isLoading: false,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  isDeleteSuccess: false,
}

const QuestionAnswerModules = createSlice({
  name: 'questionAnswer',
  initialState: initialState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetQuestionAnswerSuccess: (state, action: PayloadAction<GetQuestionAnswerResponse>) => ({
      ...state,
      isLoading: false,
      ...action.payload,
    }),
    callPostQuestionAnswerSuccess: (state) => ({
      ...state,
      isLoading: false,
      isCreateSuccess: true,
    }),
    callPutQuestionAnswerSuccess: (state) => ({
      ...state,
      isLoading: false,
      isUpdateSuccess: true,
    }),
    callDeleteQuestionAnswerSuccess: (state) => ({
      ...state,
      isLoading: false,
      isDeleteSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetIsCreateSuccess: (state) => ({
      ...state,
      isCreateSuccess: false,
    }),
    resetIsUpdateSuccess: (state) => ({
      ...state,
      isUpdateSuccess: false,
    }),
    resetIsDeleteSuccess: (state) => ({
      ...state,
      isDeleteSuccess: false,
    }),
  },
})

export const fetchGetQuestionAnswer = (questionAnswerId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(QuestionAnswerModules.actions.resetError())
    dispatch(QuestionAnswerModules.actions.callApiStart())
    const res = await callGetQuestionAnswer(questionAnswerId)
    dispatch(QuestionAnswerModules.actions.callGetQuestionAnswerSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetQuestionAnswer error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      QuestionAnswerModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchPostQuestionAnswer = (
  params: CommonQuestionAnswerRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(QuestionAnswerModules.actions.resetError())
    dispatch(QuestionAnswerModules.actions.callApiStart())
    await callPostQuestionAnswer(params)
    dispatch(QuestionAnswerModules.actions.callPostQuestionAnswerSuccess())

    // 一覧画面へ
    dispatch(push('/question_answers'))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostQuestionAnswer error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      QuestionAnswerModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchPutQuestionAnswer = (
  params: CommonQuestionAnswerRequestParams,
  questionAnswerId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(QuestionAnswerModules.actions.resetError())
    dispatch(QuestionAnswerModules.actions.callApiStart())
    await callPutQuestionAnswer(params, questionAnswerId)
    dispatch(QuestionAnswerModules.actions.callPutQuestionAnswerSuccess())

    // 一覧画面へ
    dispatch(push('/question_answers'))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutQuestionAnswer error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      QuestionAnswerModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchDeleteQuestionAnswer = (questionAnswerId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(QuestionAnswerModules.actions.resetError())
    dispatch(QuestionAnswerModules.actions.callApiStart())
    await callDeleteQuestionAnswer(questionAnswerId)
    dispatch(QuestionAnswerModules.actions.callDeleteQuestionAnswerSuccess())

    // 一覧画面へ
    dispatch(push('/question_answers'))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchDeleteQuestionAnswer error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      QuestionAnswerModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default QuestionAnswerModules
