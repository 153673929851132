import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { ChallengeNameEnum, CognitoGroupNameEnum } from '../types/login'
import {
  callPostAuthChallenge,
  callPostAuthChallengeSms,
  callPostConfirmForgotPassword,
  callPostForgotPassword,
  callPostLogin,
  PostAuthChallengeResponse,
  PostAuthChallengeSmsResponse,
  PostConfirmForgotPasswordRequestParams,
  PostLoginRequestParams,
  PostLoginResponse,
} from '../utils/apis/login'
import { decodeTokenPayload } from '../utils/utils'
import { ErrorResponse } from './error'
import { AppThunk } from './index'

export interface LoginState {
  isLoading: boolean
  id_token: string
  refresh_token: string
  // SMS認証 or パスワード再設定で使用
  session: string
  user_id: string
  // 代理店ID（代理店権限の場合のみ使用）
  agencies_id: string
  // プラットフォームID
  platform_id: number
  cognitoGroupName: CognitoGroupNameEnum
  // パスワードリセット（忘れた人）用
  email: string
  // パスワードリセット成功フラグ
  isForgotPasswordSuccess: boolean
  error?: ErrorResponse
}

const initialState: LoginState = {
  isLoading: false,
  id_token: '',
  refresh_token: '',
  session: '',
  user_id: '',
  agencies_id: '',
  platform_id: 0,
  // 初期値は代理店権限にしておく
  cognitoGroupName: CognitoGroupNameEnum.COGNITO_AGENCY_GROUP_NAME,
  email: '',
  isForgotPasswordSuccess: false,
}

const LoginModules = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callPostLoginSuccess: (state, action: PayloadAction<PostLoginResponse>) => ({
      ...state,
      isLoading: false,
      session: action.payload.session,
      user_id: action.payload.user_id,
    }),
    callPostAuthChallengeSmsSuccess: (
      state,
      action: PayloadAction<PostAuthChallengeSmsResponse>
    ) => {
      return {
        ...state,
        isLoading: false,
        session: '',
        ...action.payload,
      }
    },
    callPostAuthChallengeSuccess: (state, action: PayloadAction<PostAuthChallengeResponse>) => {
      return {
        ...state,
        isLoading: false,
        session: action.payload.session,
      }
    },
    callPostForgotPasswordSuccess: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        email: action.payload,
      }
    },
    callPostConfirmForgotPasswordSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        email: '',
        isForgotPasswordSuccess: true,
      }
    },
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetLogin: () => {
      return initialState
    },
    resetIsForgotPasswordSuccess: (state) => {
      return {
        ...state,
        isForgotPasswordSuccess: false,
      }
    },
    setCognitoGroupName: (state, action: PayloadAction<CognitoGroupNameEnum>) => {
      return {
        ...state,
        cognitoGroupName: action.payload,
      }
    },
    setPlatformID: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        platform_id: action.payload,
      }
    },
    setIdToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        id_token: action.payload,
      }
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        refresh_token: action.payload,
      }
    },
  },
})

export const fetchPostLogin = (params: PostLoginRequestParams): AppThunk => async (dispatch) => {
  try {
    dispatch(LoginModules.actions.resetError())
    dispatch(LoginModules.actions.callApiStart())
    const res = await callPostLogin(params)
    dispatch(LoginModules.actions.callPostLoginSuccess(res))

    if (res.challenge_name === ChallengeNameEnum.NEW_PASSWORD_REQUIRED) {
      // パスワード再設定
      dispatch(push('/auth_challenge'))
    } else if (res.challenge_name === ChallengeNameEnum.SMS_MFA) {
      // SMS認証ページ
      dispatch(push('/auth_challenge_sms'))
    } else {
      // 万が一上記以外のchallenge_nameレスポンスがあった場合
      throw 'None of the patterns apply.'
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostLogin error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      LoginModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// SMS認証
export const fetchPostAuthChallengeSms = (confirmation_code: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const loginState = getState().login
    dispatch(LoginModules.actions.resetError())
    dispatch(LoginModules.actions.callApiStart())
    const res = await callPostAuthChallengeSms({
      session: loginState.session,
      user_id: loginState.user_id,
      confirmation_code: confirmation_code,
    })
    dispatch(LoginModules.actions.callPostAuthChallengeSmsSuccess(res))

    const payload = decodeTokenPayload(res.id_token)
    const cognitoGroupName: CognitoGroupNameEnum = payload['cognito:groups'][0]
    dispatch(LoginModules.actions.setCognitoGroupName(cognitoGroupName))
    dispatch(LoginModules.actions.setPlatformID(res.platform_id))

    dispatch(push('/'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostAuthChallengeSms error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      LoginModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// パスワード再設定
export const fetchPostAuthChallenge = (password: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const loginState = getState().login
    dispatch(LoginModules.actions.resetError())
    dispatch(LoginModules.actions.callApiStart())
    const res = await callPostAuthChallenge({
      session: loginState.session,
      user_id: loginState.user_id,
      password: password,
    })
    dispatch(LoginModules.actions.callPostAuthChallengeSuccess(res))

    if (res.challenge_name === ChallengeNameEnum.SMS_MFA) {
      // SMS認証ページ
      dispatch(push('/auth_challenge_sms'))
    } else {
      // 万が一上記以外のchallenge_nameレスポンスがあった場合
      throw 'None of the patterns apply.'
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostAuthChallenge error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      LoginModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// パスワードリセット（忘れた人用）
export const fetchPostForgotPassword = (email: string): AppThunk => async (dispatch) => {
  try {
    dispatch(LoginModules.actions.resetError())
    dispatch(LoginModules.actions.callApiStart())
    await callPostForgotPassword({
      email: email,
    })
    dispatch(LoginModules.actions.callPostForgotPasswordSuccess(email))

    dispatch(push('/confirm_forgot_password'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostForgotPassword error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      LoginModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchPostConfirmForgotPassword = (
  params: PostConfirmForgotPasswordRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(LoginModules.actions.resetError())
    dispatch(LoginModules.actions.callApiStart())
    await callPostConfirmForgotPassword(params)
    dispatch(LoginModules.actions.callPostConfirmForgotPasswordSuccess())

    dispatch(push('/login'))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostConfirmForgotPassword error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      LoginModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default LoginModules
