import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchGetPlatforms, fetchTruncatePlatform } from 'store/platforms'
import { selectLogin } from 'store/selector'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import { selectPlatforms } from '../../store/selector'
import { CognitoGroupNameEnum } from '../../types/login'
import styles from './styles.module.scss'

export interface PlatformsState {
  platform: string
}

const Platforms = () => {
  const loginSelector = useSelector(selectLogin)
  const dispatch = useDispatch()
  const history = useHistory()
  const PlatformsSelector = useSelector(selectPlatforms)

  const moveNewPage = () => {
    dispatch(push('/platform/new'))
  }

  const moveEditPage = (id: number) => {
    dispatch(push(`/platform/${id}/edit`))
  }

  const moveToSalesOfficesPage = (id: number, platformName: string) => {
    history.push({
      pathname: `/sales_offices/${id}`,
      state: { platformName: platformName },
    })
  }

  const moveToAgenciesPage = (id: number, platformName: string) => {
    history.push({
      pathname: `/agencies/${id}`,
      state: { platformName: platformName },
    })
  }

  const moveToInsuranceOfficesPage = (id: number, platformName: string) => {
    history.push({
      pathname: `/insurance_offices/${id}`,
      state: { platformName: platformName },
    })
  }

  useEffect(() => {
    dispatch(fetchGetPlatforms())
  }, [dispatch])

  // 全削除ボタン
  const handleTruncateSubmit = async () => {
    dispatch(fetchTruncatePlatform())
    setTruncateModalOpen(false)
  }

  const [truncateModalOpen, setTruncateModalOpen] = useState(false)

  const handleCloseTruncateModal = () => {
    setTruncateModalOpen(false)
  }

  const handleOpenTruncateModal = () => {
    setTruncateModalOpen(true)
  }

  return (
    <div data-page="platforms">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="プラットフォーム一覧" isCenter={true} />
          </Grid>
        </Grid>
        <Box className={styles.newCreateBtn}>
          {loginSelector.cognitoGroupName === CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME &&
            ['local', 'development', 'staging'].includes(process.env.REACT_APP_ENV) && (
              <Button
                onClick={() => {
                  handleOpenTruncateModal()
                }}
                color="secondary"
                variant="contained"
                data-section="new"
              >
                全削除
              </Button>
            )}
          <Dialog
            open={truncateModalOpen}
            onClose={handleCloseTruncateModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'メール文書全削除'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                メール文書を全削除します。 よろしいでしょうか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTruncateModal} variant="contained" color="secondary">
                閉じる
              </Button>
              <Button onClick={handleTruncateSubmit} variant="contained" color="primary">
                全削除
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            onClick={moveNewPage}
            color="primary"
            variant="contained"
            data-section="new"
            disabled={
              loginSelector.cognitoGroupName === CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                ? false
                : true
            }
          >
            新規作成
          </Button>
        </Box>
        <Box>
          {PlatformsSelector.error ? (
            <div data-section="get-api-error">
              <ApiError
                error={PlatformsSelector.error.error}
                message={PlatformsSelector.error.message}
                status={PlatformsSelector.error.status}
              />
            </div>
          ) : PlatformsSelector.isLoading ? (
            <CircularProgress />
          ) : (
            <Box mt={3}>
              <Container maxWidth="lg">
                <TableContainer component={Paper} className={styles.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>プラットフォーム名</TableCell>
                        <TableCell>操作</TableCell>
                        <TableCell>営業店一覧</TableCell>
                        <TableCell>代理店一覧</TableCell>
                        <TableCell>保サ一覧</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!PlatformsSelector.platforms?.length ? (
                        <TableRow>
                          <TableCell align="center" colSpan={9}>
                            プラットフォームデータがありません
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {PlatformsSelector.platforms.map((data) => {
                            return (
                              <TableRow key={data.id} data-section={`row${data.id}`}>
                                <TableCell data-section="id">{data.id}</TableCell>
                                <TableCell data-section="platform-name">
                                  {data.platform_name}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => moveEditPage(data.id)}
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                    disabled={
                                      loginSelector.cognitoGroupName ===
                                      CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                                        ? false
                                        : true
                                    }
                                  >
                                    編集
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() =>
                                      moveToSalesOfficesPage(data.id, data.platform_name)
                                    }
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                    disabled={
                                      loginSelector.cognitoGroupName ===
                                      CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                                        ? false
                                        : true
                                    }
                                  >
                                    営業店一覧
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => moveToAgenciesPage(data.id, data.platform_name)}
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                  >
                                    代理店一覧
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() =>
                                      moveToInsuranceOfficesPage(data.id, data.platform_name)
                                    }
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                    disabled={
                                      loginSelector.cognitoGroupName ===
                                      CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                                        ? false
                                        : true
                                    }
                                  >
                                    保サ一覧
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  )
}

export default Platforms
