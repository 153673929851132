import React, { ChangeEvent, useCallback, useState, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, Container, Box, TextField, Button, CircularProgress, Grid } from '@material-ui/core'

import { fetchPostForgotPassword } from 'store/login'
import { getErrorAsObject, forgotPasswordSchema } from 'utils/validate'
import ApiError from 'components/ApiError'
import styles from './styles.module.scss'
import Title from '../../components/Title'
import { selectLogin } from '../../store/selector'
import { push } from 'connected-react-router'

export interface ForgotPasswordState {
  email: string
}

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const [errors, setErrors] = useState<{ [key in keyof ForgotPasswordState]?: string }>({})
  const [inputForgotPassword, setInputForgotPassword] = useState<ForgotPasswordState>({
    email: '',
  })

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      setErrors({
        ...errors,
        [key]: '',
      })
      setInputForgotPassword({
        ...inputForgotPassword,
        [key]: event.target.value,
      })
    },
    [inputForgotPassword, errors]
  )

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    try {
      await forgotPasswordSchema.validate(inputForgotPassword, { abortEarly: false })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      setErrors(getErrorAsObject(err))
      return
    }
    dispatch(fetchPostForgotPassword(inputForgotPassword.email))
  }

  const handleBack = () => {
    dispatch(push('/login'))
  }

  return (
    <div data-page="forgot-password">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="パスワードリセット" isCenter={true} />
            <p className={styles.text}>
              パスワードを忘れてしまった場合、パスワードをリセットし、新しいパスワードを設定します。
              <br />
              登録しているメールアドレスを入力してください。
              <br />
              メールアドレス宛にリセットに必要な検証コードを送信します。
              <br />
              ※仮パスワードのリセットはできません。
            </p>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit}>
          <div data-section="api-error">
            {loginSelector.error && (
              <ApiError
                error={loginSelector.error.error}
                message={loginSelector.error.message}
                status={loginSelector.error.status}
              />
            )}
          </div>
          {loginSelector.isLoading ? <CircularProgress /> : null}
          <Container className={styles.forms} maxWidth="sm" component={Paper} variant="outlined">
            <TextField
              fullWidth
              label="メールアドレス"
              margin="normal"
              value={inputForgotPassword.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'email')
              }}
              error={Boolean(errors.email)}
              helperText={errors.email}
              data-section="email"
            />
            <div className={styles.buttonWrap}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={loginSelector.isLoading}
                data-section="backButton"
                onClick={handleBack}
              >
                戻る
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loginSelector.isLoading}
                data-section="submitButton"
              >
                検証コードを送信する
              </Button>
            </div>
          </Container>
        </Box>
      </Container>
    </div>
  )
}

export default ForgotPassword
