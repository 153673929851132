/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios'

const axiosMiddleware = require('axios-middleware')
const service = new axiosMiddleware.Service(axios)

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
})

service.register({
  onResponseError(error: any) {
    if (error.response.status === 401) {
      location.href = '/login'
    }
    throw error
  },
})
