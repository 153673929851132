import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import axios from '../apis/axios'

export interface PolicyNumber {
  id: number
  policy_number: string
  insurance_id: string
  year: string
  month: string
  platform_name: string
  platform_id: string
}

export interface PolicyNumbersState {
  isLoading: boolean
  policyNumbers: PolicyNumber[]
  error?: ErrorResponse
}

export const policyNumbersState: PolicyNumbersState = {
  isLoading: false,
  policyNumbers: [],
}

export interface GetPolicyNumbersResponse {
  policy_numbers: PolicyNumber[]
}
export interface PutPolicyNumberRequestParams {
  policyNumberId: string
}

const PolicyNumbersModules = createSlice({
  name: 'policyNumbers',
  initialState: policyNumbersState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetPolicyNumbersSuccess: (state, action: PayloadAction<GetPolicyNumbersResponse>) => ({
      ...state,
      isLoading: false,
      policyNumbers: action.payload.policy_numbers,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
  },
})

const callGetPolicyNumbers = async () => {
  const { data }: AxiosResponse<GetPolicyNumbersResponse> = await axios.get('/policy_numbers')
  return data
}

export const fetchGetPolicyNumbers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(PolicyNumbersModules.actions.resetError())
    dispatch(PolicyNumbersModules.actions.callApiStart())
    const res = await callGetPolicyNumbers()
    dispatch(PolicyNumbersModules.actions.callGetPolicyNumbersSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetPolicyNumbers error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      PolicyNumbersModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default PolicyNumbersModules
