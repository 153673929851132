import React from 'react'
import { Grid } from '@material-ui/core'

import { ErrorResponse } from '../../store/error'
import styles from './styles.module.scss'
import { DUPLICATE_ENTRY_MESSAGE } from '../../const'

const ApiError = ({ status, message, error }: ErrorResponse) => {
  console.log('ApiError')
  console.log(error)
  const checkDuplicate = (error: string) => {
    return error.includes('Duplicate entry')
  }
  return (
    <Grid container className={styles.root}>
      <Grid item xs={12}>
        <h2 className={styles.status}>{status}</h2>
        {checkDuplicate(error) ? (
          <p className={styles.message}>{DUPLICATE_ENTRY_MESSAGE}</p>
        ) : (
          <p className={styles.message}>{message}</p>
        )}
      </Grid>
    </Grid>
  )
}

export default ApiError
