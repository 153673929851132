/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CircularProgress, Container, Grid, MenuItem, Select } from '@material-ui/core'
import ApiError from 'components/ApiError'
import Title from 'components/Title'
import { push } from 'connected-react-router'
import MaterialTable, { Column } from 'material-table'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CsvTemplatesModules, {
  fetchChangeTemplate,
  fetchDeleteTemplate,
  fetchFindCsvTemplates,
  fetchRegistTemplate,
  fetchResetTemplates,
} from '../../store/csvTemplates'
import { selectCsvTemplates } from '../../store/selector'
import { CsvTemplate } from '../../types/csvTemplate'
import { PostCsvTemplateRequestParams } from '../../utils/apis/csvTemplate'
import styles from './styles.module.scss'

const CsvTemplates = () => {
  const dispatch = useDispatch()
  const csvTemplatesSelector = useSelector(selectCsvTemplates)

  const [columns, setColums] = useState<Column<CsvTemplate>[]>([])

  useLayoutEffect(() => {
    dispatch(CsvTemplatesModules.actions.resetError())
    //dispatch(CsvTemplatesModules.actions.resetCsvTemplatesList())
    dispatch(CsvTemplatesModules.actions.resetError())
    console.log(csvTemplatesSelector.templatesList)
    console.log(csvTemplatesSelector.selectorMap)
    console.log([...Array(csvTemplatesSelector.selectorMap)])
    console.log('------')
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchFindCsvTemplates())
  }, [])

  useEffect(() => {
    setColums([
      {
        title: 'ID',
        field: 'id',
        editable: 'never',
        type: 'numeric',
        hidden: true,
        cellStyle: { width: 0 },
      },
      {
        title: '順序',
        field: 'seq',
        editable: 'always',
        type: 'numeric',
        width: 50,
        initialEditValue: 1,
        validate: (rowData) =>
          isNaN(parseInt(rowData.seq + '', 10)) ? '数値を入力してください' : { isValid: true },
      },
      {
        title: '項目コード',
        field: 'code',
        editable: 'onAdd',
        initialEditValue: 'NEW_CODE',
        validate: (rowData) => {
          if (!rowData.code?.trim()) {
            return '項目コードを入力してください。'
          }
          return { isValid: true }
        },
      },
      {
        title: '項目名称',
        field: 'label',
        editable: 'always',
        cellStyle: { width: 200 },
        initialEditValue: '',
      },
      {
        title: 'デフォルト値',
        field: 'fixed_value',
        editable: (columnDef: Column<CsvTemplate>, rowData: CsvTemplate) =>
          !rowData || !rowData.selector,
      },
      {
        title: 'セレクター',
        field: 'selector',
        editable: 'always',
        render: (data: CsvTemplate) => (
          <p>{csvTemplatesSelector.selectorMap[data.selector] || data.selector}</p>
        ),
        editComponent: (props) => (
          <Box className={styles.attributeSelect}>
            <Select autoWidth value={props.value} onChange={(e) => props.onChange(e.target.value)}>
              <MenuItem value="">選択して下さい</MenuItem>
              {Object.keys(csvTemplatesSelector.selectorMap).map((key) => (
                <MenuItem value={key}>{csvTemplatesSelector.selectorMap[key]}</MenuItem>
              ))}
            </Select>
          </Box>
        ),
      },
      {
        title: '属性',
        field: 'attribute',
        editable: 'onAdd',
        initialEditValue: '',
        editComponent: (props) => (
          <Box className={styles.attributeSelect}>
            <Select autoWidth value={props.value} onChange={(e) => props.onChange(e.target.value)}>
              <MenuItem value="">
                <span>NONE</span>
              </MenuItem>
              <MenuItem value="漢">漢</MenuItem>
              <MenuItem value="N">N</MenuItem>
              <MenuItem value="AN">AN</MenuItem>
              <MenuItem value="ZN">ZN</MenuItem>
            </Select>
          </Box>
        ),
      },
      {
        title: '最大桁数',
        field: 'max_length',
        editable: 'onAdd',
        initialEditValue: 10,
        validate: (rowData) =>
          !rowData.max_length ? '1以上の最大桁数を入力してください。' : { isValid: true },
      },
    ])
  }, [csvTemplatesSelector.selectorMap])

  // テンプレートリセット処理
  const handleReset = async () => {
    if (window.confirm('CSV出力設定をリセットしてよろしいですか？')) {
      await dispatch(fetchResetTemplates())
    }
  }

  // テンプレート追加処理
  const handleRegist = async (params: PostCsvTemplateRequestParams) => {
    await dispatch(fetchRegistTemplate(params))
  }

  // テンプレート変更処理
  const handleChange = async (code: string, params: PostCsvTemplateRequestParams) => {
    await dispatch(fetchChangeTemplate(code, params))
  }

  // テンプレート削除処理
  const handleDelete = async (code: string) => {
    await dispatch(fetchDeleteTemplate(code))
  }

  return (
    <div data-page="csv-template">
      <Container className={styles.root} maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <Title title="CSV出力設定" isCenter={true} />
          </Grid>
          <Box marginBottom={1} className={styles.headerBox} width="100%">
            <Grid container alignItems="flex-end" justifyContent="flex-end" spacing={3}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => dispatch(push('/csv_download'))}
                  data-section="back"
                >
                  戻る
                </Button>
              </Grid>
              <Grid item>
                {!csvTemplatesSelector.isLoading && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleReset}
                    data-section="reset"
                  >
                    初期化/リセット
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className={styles.tableBox}>
            <Grid container>
              {csvTemplatesSelector.error ? (
                <div data-section="api-error">
                  <ApiError
                    error={csvTemplatesSelector.error?.error}
                    message={csvTemplatesSelector.error?.message}
                    status={csvTemplatesSelector.error?.status}
                  />
                </div>
              ) : csvTemplatesSelector.isLoading ? (
                <Grid container>
                  <Grid item xs={12}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <div className={styles.table} data-section="table">
                  <MaterialTable
                    title="Disable Field Editable Preview"
                    columns={columns}
                    data={JSON.parse(JSON.stringify(csvTemplatesSelector.templatesList))}
                    // onChangePageが無いとWARNINGが表示される 時間を見つけて詳しく調査する
                    onChangePage={() => console.log('onChangePage')}
                    editable={{
                      isDeletable: (rowData) => !rowData.selector,
                      onRowAdd: (newData: any) =>
                        handleRegist({
                          seq: newData.seq || 0,
                          code: newData.code.trim(),
                          label: newData.label.trim(),
                          fixed_value: newData.fixed_value,
                          selector: newData.selector,
                          attribute: newData.attribute || null,
                          max_length: newData.max_length || 10,
                        }),
                      onRowUpdate: (newData: any, oldData: any) =>
                        handleChange(oldData.code, {
                          seq: newData.seq || 0,
                          code: newData.code.trim(),
                          label: newData.label.trim(),
                          fixed_value: newData.fixed_value,
                          selector: newData.selector,
                          attribute: newData.attribute || null,
                          max_length: newData.max_length || 10,
                        }),
                      //onRowDelete: (oldData: any) => handleDelete(oldData.code),
                    }}
                    options={{
                      showTitle: false,
                      pageSize: csvTemplatesSelector.templatesList.length,
                      addRowPosition: 'first',
                      maxBodyHeight: '600px',
                    }}
                  />
                </div>
              )}
            </Grid>
          </Box>
        </Grid>
      </Container>
    </div>
  )
}

export default CsvTemplates
