import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Container, Grid, IconButton, Paper } from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { endOfMonth, startOfMonth } from 'date-fns'
import { push } from 'connected-react-router'
import { fetchPostCsvDownloadInfo } from '../../store/download'
import { PostDownloadCsvRequestParams } from '../../utils/apis/download'
import { downloadCsvInfoSchema, getErrorAsObject } from '../../utils/validate'
// TODO: 解約カラムが追加されたら有効化
// import { checkExpirationTime, checkOnTheDay } from 'utils/utils'
import Title from '../../components/Title'
import styles from './styles.module.scss'

interface DateRange {
  from: Date
  to: Date
}

const CsvDownload = () => {
  const dispatch = useDispatch()

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PostDownloadCsvRequestParams]?: string
    }
  >({})
  const [validErrors, setValidErrors] = useState<
    { [key in keyof PostDownloadCsvRequestParams]?: string }
  >({})
  const today = new Date()
  const startLastMonth = startOfMonth(today)
  const endLastMonth = endOfMonth(today)
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startLastMonth,
    to: endLastMonth,
  })

  // 登録ボタン実行時のバリデーション
  const validationPostCheck = async (): Promise<boolean> => {
    const params = getPostParams()
    try {
      await downloadCsvInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  const getPostParams = (): PostDownloadCsvRequestParams => {
    return {
      from_date: dateRange.from,
      to_date: dateRange.to,
    }
  }

  // ダウンロードボタン
  const handlePostSubmit = async () => {
    // TODO ファイル名はタイムスタンプあたりを使用？
    const file_name = 'csvダウンロード.csv'
    const isValid = await validationPostCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPostParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPostCsvDownloadInfo(file_name, params))
    }
  }

  const handleDateChange = (target: keyof typeof dateRange) => (date: Date | null) => {
    let to = target === 'to' ? date : dateRange.to
    let from = target === 'from' ? date : dateRange.from
    if (!to || !from) {
      setValidErrors({ ...validErrors, [target + '_date']: '' })
      setDateRange({
        ...dateRange,
        [target]: date,
      })
    } else if (to < from) {
      if (target === 'to') {
        from = to
        setValidErrors({ ...validErrors, from_date: '' })
        setDateRange({
          ...dateRange,
          from: from,
        })
      } else {
        to = from
        setValidErrors({ ...validErrors, to_date: '' })
        setDateRange({
          ...dateRange,
          to: to,
        })
      }
    } else {
      setValidErrors({ ...validErrors, [target + '_date']: '' })
      setDateRange({
        ...dateRange,
        [target]: date,
      })
    }
  }

  return (
    <div data-page="csv-download">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="CSVダウンロード" isCenter={true} />
          </Grid>
        </Grid>
        <Grid container>
          <Container className={styles.forms} maxWidth="sm" component={Paper} elevation={3}>
            <Box className={styles.templateButtonContainer}>
              <IconButton
                aria-label="Open csv template settings"
                color="primary"
                onClick={() => dispatch(push('/csv_download/csv_templates'))}
                data-section="csv-template"
              >
                <TuneIcon />
              </IconButton>
            </Box>
            <KeyboardDatePicker
              variant="inline"
              format="yyyy-MM-dd"
              label="契約期間開始(from)"
              value={dateRange?.from || null}
              onChange={handleDateChange('from')}
              KeyboardButtonProps={{
                'aria-label': 'start date',
              }}
              autoOk
              data-section="start-date"
              error={Boolean(errors?.from_date)}
              helperText={errors?.from_date}
            />
            <KeyboardDatePicker
              variant="inline"
              format="yyyy-MM-dd"
              label="契約期間開始(to)"
              value={dateRange?.to || null}
              onChange={handleDateChange('to')}
              KeyboardButtonProps={{
                'aria-label': 'start date',
              }}
              autoOk
              data-section="end-date"
              error={Boolean(errors?.to_date)}
              helperText={errors?.to_date}
            />
            <div className={styles.buttonWrap}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePostSubmit()}
                data-section="download"
              >
                ダウンロード
              </Button>
            </div>
          </Container>
        </Grid>
      </Container>
    </div>
  )
}

export default CsvDownload
