import axios from 'apis/axios'
import { AxiosResponse } from 'axios'
import { GetContractsResponse } from '../../types/csvTemplate'

export const callGetCsvTemplates = async () => {
  const { data }: AxiosResponse<GetContractsResponse> = await axios.get('/csv_templates')
  return data
}

export const callResetCsvTemplates = async () => {
  const { data }: AxiosResponse<GetContractsResponse> = await axios.post('/csv_templates/reset')
  return data
}

/*
 * 項目一覧CSVダウンロード情報取得
 */
export interface PostCsvTemplateRequestParams {
  seq: number
  code: string
  label: string
  fixed_value: string
  attribute: string
  selector: string
  max_length: number
}

export const callPutCsvTemplate = async (code: string, newValue: PostCsvTemplateRequestParams) => {
  const { data }: AxiosResponse<GetContractsResponse> = await axios.put(`/csv_template/${code}`, {
    params: newValue,
  })
  return data
}

export const callPostCsvTemplate = async (newValue: PostCsvTemplateRequestParams) => {
  const { data }: AxiosResponse<GetContractsResponse> = await axios.post('/csv_template', {
    params: newValue,
  })
  return data
}

export const callDeleteCsvTemplate = async (code: string) => {
  const { data }: AxiosResponse<GetContractsResponse> = await axios.delete(`/csv_template/${code}`)
  return data
}
