import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import React from 'react'
import styles from './styles.module.scss'
interface Props {
  open: boolean
  onClose: () => void
}

const EmailTextRuleModal = ({ open, onClose }: Props) => {
  const handleCloseModal = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="email-text-rule-modal"
      fullWidth={true}
    >
      <div className={styles.root}>
        <DialogTitle id="email-text-rule-modal-title" className={styles.title}>
          メール文書の変数埋め込みルール
        </DialogTitle>
        <DialogContent>
          <h4>お客様用入力画面で入力した内容をメールのタイトルと本文に代入することが可能です</h4>
          <p>例：申し込み画面の企業名をメールに代入</p>
          <p>当画面のメール本文：企業名:%company_name%</p>
          <p>送信されるメール本文：企業名:申し込み画面で入力した企業名</p>
          <br />
          <h4>入力任意項目を代入する場合はメール文書の作成にご注意ください</h4>
          <h4>入力画面で入力任意項目が未入力だった場合</h4>
          <p>例：契約内容変更画面の電話番号をメールに代入</p>
          <p>当画面のメール本文：電話番号:%phone%</p>
          <p>送信されるメール本文：電話番号:*[電話番号]を入力いただいておりません</p>
          <br />
          <p>入力項目の必須任意に関しては、お客様用入力画面を参照してください</p>
          <br />
          <p>テキストエリアの入力項目に関しては、当画面で改行を意識してください</p>
          <br />
          <h4>各画面での項目名と代入変数は下記表を参照ください</h4>
          <br />
          <p>申し込み画面</p>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>画面上の項目名</TableCell>
                  <TableCell>代入変数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>企業名</TableCell>
                  <TableCell>%company_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>代表者名</TableCell>
                  <TableCell>%contractor_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>郵便番号</TableCell>
                  <TableCell>%zip%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>住所</TableCell>
                  <TableCell>%address%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ご担当者名</TableCell>
                  <TableCell>%manager_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>%email%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>電話番号</TableCell>
                  <TableCell>%phone%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>会員番号</TableCell>
                  <TableCell>%member_id%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>他の保険契約の有無</TableCell>
                  <TableCell>%have_other_insurance%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険会社名(他社)</TableCell>
                  <TableCell>%other_insurance_company%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険種類(他社)</TableCell>
                  <TableCell>%other_insurance_type%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>満期日(他社)</TableCell>
                  <TableCell>%other_insurance_period%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>加入申込者名</TableCell>
                  <TableCell>%company_name% %contractor_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>補償対象者名</TableCell>
                  <TableCell>%company_name% %contractor_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>業種</TableCell>
                  <TableCell>%industry%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>売上高</TableCell>
                  <TableCell>%sales_amount%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険始期</TableCell>
                  <TableCell>%esired_insurance_start_date%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険期間開始日</TableCell>
                  <TableCell>%contract_period_start%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険期間終了日</TableCell>
                  <TableCell>%contract_period_end%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険料払込方法・期日</TableCell>
                  <TableCell>%payment_deadline%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険料</TableCell>
                  <TableCell>%fee_amount%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>申込パターン名称</TableCell>
                  <TableCell>%insurance_product%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>申込日</TableCell>
                  <TableCell>%application_date%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <p>事故連絡画面</p>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>画面上の項目名</TableCell>
                  <TableCell>代入変数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>事故日</TableCell>
                  <TableCell>%accident_date%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>事故概要</TableCell>
                  <TableCell>%accident_summary%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>企業名</TableCell>
                  <TableCell>%company_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ご担当者名</TableCell>
                  <TableCell>%manager_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>%email%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>電話番号</TableCell>
                  <TableCell>%phone%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>会員番号</TableCell>
                  <TableCell>%member_id%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険加入者番号</TableCell>
                  <TableCell>%contractor_id%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <p>契約変更画面</p>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>画面上の項目名</TableCell>
                  <TableCell>代入変数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>変更内容</TableCell>
                  <TableCell>%changes%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>変更希望日</TableCell>
                  <TableCell>%desired_change_date%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>変更前の内容</TableCell>
                  <TableCell>%contents_before_change%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>変更後の内容</TableCell>
                  <TableCell>%contents_after_change%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>企業名</TableCell>
                  <TableCell>%company_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ご担当者名</TableCell>
                  <TableCell>%manager_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>%email%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>電話番号</TableCell>
                  <TableCell>%phone%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>会員番号</TableCell>
                  <TableCell>%member_id%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険加入者番号</TableCell>
                  <TableCell>%contractor_id%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <p>解約画面</p>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>画面上の項目名</TableCell>
                  <TableCell>代入変数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>企業名</TableCell>
                  <TableCell>%company_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ご担当者名</TableCell>
                  <TableCell>%manager_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>%email%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>電話番号</TableCell>
                  <TableCell>%phone%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>会員番号</TableCell>
                  <TableCell>%member_id%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険加入者番号</TableCell>
                  <TableCell>%contractor_id%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>解約理由</TableCell>
                  <TableCell>%cancel_reason%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>解約希望日</TableCell>
                  <TableCell>%desired_cancellation_date%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <p>お問い合わせ画面</p>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>画面上の項目名</TableCell>
                  <TableCell>代入変数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>お問い合わせ</TableCell>
                  <TableCell>%inquiry%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>企業名</TableCell>
                  <TableCell>%company_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ご担当者名</TableCell>
                  <TableCell>%manager_name%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>%email%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>電話番号</TableCell>
                  <TableCell>%phone%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>会員番号</TableCell>
                  <TableCell>%member_id%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険加入有無</TableCell>
                  <TableCell>%contract_insurance%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>保険加入者番号</TableCell>
                  <TableCell>%contractor_id%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default EmailTextRuleModal
