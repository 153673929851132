import axios from '../../apis/axios'
import { AxiosResponse } from 'axios'
import {
  SalesOfficeResponce,
  SalesOfficesResponce,
  PostSalesOfficeRequestParams,
  PutSalesOfficeRequestParams,
} from '../../types/salesOffice'

// 営業店一覧取得
export const callGetSalesOfficesByPlatformId = async (platformId: string) => {
  const { data }: AxiosResponse<SalesOfficesResponce> = await axios.get(
    `/sales_offices/${platformId}`
  )
  return data.sales_offices
}

// 営業店詳細取得
export const callFindSalesOffice = async (platformId: string, salesOfficeId: string) => {
  const { data }: AxiosResponse<SalesOfficeResponce> = await axios.get(
    `/sales_office/${platformId}/${salesOfficeId}`
  )
  return data.sales_office
}

// 営業店情報更新
export const callPutSalesOfficeInfo = async (
  platformId: string,
  salesOfficeId: string,
  params: PutSalesOfficeRequestParams
) => {
  await axios.put(`/sales_office/${platformId}/${salesOfficeId}`, {
    params: params,
  })
}

// 営業店情報登録
export const callPostSalesOfficeInfo = async (
  platformId: string,
  params: PostSalesOfficeRequestParams
) => {
  await axios.post(`/sales_office/${platformId}`, {
    params: params,
  })
}
