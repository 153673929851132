import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import {
  callGetAdminGetUsers,
  callPostAdminCreateUser,
  callPostAdminDeleteUser,
  GetAdminGetUsersResponse,
  PostAdminCreateUserRequestParams,
  PostAdminDeleteUserRequestParams,
} from '../utils/apis/admin'
import { CognitoUserInterface } from '../types/admin'

export interface AdminState {
  isLoading: boolean
  // ユーザー作成成功フラグ
  isCreateUserSuccess: boolean
  // ユーザー削除成功フラグ
  isDeleteUserSuccess: boolean
  users: CognitoUserInterface[]
  error?: ErrorResponse
}

const initialState: AdminState = {
  isLoading: false,
  isCreateUserSuccess: false,
  isDeleteUserSuccess: false,
  users: [],
}

const AdminModules = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callCreateUserSuccess: (state) => ({
      ...state,
      isLoading: false,
      isCreateUserSuccess: true,
    }),
    callGetUsersSuccess: (state, action: PayloadAction<GetAdminGetUsersResponse>) => ({
      ...state,
      isLoading: false,
      ...action.payload,
    }),
    callDeleteUserSuccess: (state) => ({
      ...state,
      isLoading: false,
      isDeleteUserSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetAdmin: () => {
      return initialState
    },
    resetIsCreateUserSuccess: (state) => ({
      ...state,
      isCreateUserSuccess: false,
    }),
    resetIsDeleteUserSuccess: (state) => ({
      ...state,
      isDeleteUserSuccess: false,
    }),
  },
})

// ユーザー作成
export const fetchPostAdminCreateUser = (
  params: PostAdminCreateUserRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(AdminModules.actions.resetError())
    dispatch(AdminModules.actions.callApiStart())
    await callPostAdminCreateUser(params)
    dispatch(AdminModules.actions.callCreateUserSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostAdminCreateUser error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      AdminModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// ユーザー一覧取得
export const fetchGetAdminGetUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(AdminModules.actions.resetError())
    dispatch(AdminModules.actions.callApiStart())
    const res = await callGetAdminGetUsers()
    dispatch(AdminModules.actions.callGetUsersSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetAdminGetUsers error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      AdminModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// ユーザー作成
export const fetchPostAdminDeleteUser = (
  params: PostAdminDeleteUserRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(AdminModules.actions.resetError())
    dispatch(AdminModules.actions.callApiStart())
    await callPostAdminDeleteUser(params)
    dispatch(AdminModules.actions.callDeleteUserSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostAdminDeleteUser error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      AdminModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default AdminModules
