import { useDispatch } from 'react-redux'
import {
  Button,
  Dialog,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import styles from './styles.module.scss'
import { SalesStatusEnum, InsuranceIsSalesEnum } from '../../types/insurance'
import { INSURANCE_IS_SALES_NAME } from '../../const'
import { fetchChangeInsurancesStatus } from '../../store/insurances'

interface Props {
  open: boolean
  onClose: () => void
  disableButton: (val: boolean) => void
  insurance_ids: Array<number>
  setInsurances: (val: Array<number>) => void
}

const IsSalesModal = ({ open, onClose, disableButton, insurance_ids, setInsurances }: Props) => {
  const dispatch = useDispatch()
  const [changeIsSales, setChangeIsSales] = useState<SalesStatusEnum>(
    SalesStatusEnum.TEMPORARY_SALES
  )

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setChangeIsSales((e.target.value as unknown) as SalesStatusEnum)
  }

  const handleInsuranceChangeStatus = () => {
    const insuranceStatusChangeParams = {
      insurance_ids: insurance_ids,
      is_sales: Number(changeIsSales) || 0,
    }
    dispatch(fetchChangeInsurancesStatus(insuranceStatusChangeParams))
    // 成功したらボタンを非活性にする
    disableButton(true)
    // 選択配列も空にする ※Storeで管理する変数に選択契約を加えるのがベスト 時間が無いのでこんな修正にする
    setInsurances([])
    onClose()
  }

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="insurances-status-modal"
      fullWidth={true}
    >
      <div className={styles.root}>
        <DialogTitle id="insurances-status-modal-title">ステータス変更</DialogTitle>
        <FormControl component="fieldset">
          <FormLabel component="legend">ステータス</FormLabel>
          <RadioGroup
            name="radio-buttons-group"
            value={changeIsSales}
            onChange={handleChangeStatus}
          >
            <FormControlLabel
              value={SalesStatusEnum.TEMPORARY_SALES}
              control={<Radio />}
              label={INSURANCE_IS_SALES_NAME[InsuranceIsSalesEnum.TEMPORARY_SALES]}
            />
            <FormControlLabel
              value={SalesStatusEnum.ACTUAL_SALES}
              control={<Radio />}
              label={INSURANCE_IS_SALES_NAME[InsuranceIsSalesEnum.ACTUAL_SALES]}
            />
          </RadioGroup>
        </FormControl>
        <div className={styles['button-group']}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            style={{ marginRight: '15px' }}
            color={'secondary'}
          >
            キャンセル
          </Button>
          <Button onClick={handleInsuranceChangeStatus} variant="contained" color={'primary'}>
            変更する
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default IsSalesModal
