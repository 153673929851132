import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Paper,
  Snackbar,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ApiError from 'components/ApiError'
import { push } from 'connected-react-router'
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import loginModules, { fetchPostLogin } from 'store/login'
import { getErrorAsObject, loginSchema } from 'utils/validate'
import Title from '../../components/Title'
import { selectLogin } from '../../store/selector'
import styles from './styles.module.scss'

export interface LoginState {
  email: string
  password: string
}

const Login = () => {
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const [errors, setErrors] = useState<{ [key in keyof LoginState]?: string }>({})
  const [inputLogin, setInputLogin] = useState<LoginState>({
    email: '',
    password: '',
  })

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      setErrors({
        ...errors,
        [key]: '',
      })
      setInputLogin({
        ...inputLogin,
        [key]: event.target.value,
      })
    },
    [inputLogin, errors]
  )

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    // emailにdegbugを入力するとemail&password認証をスキップしてSMS認証に進む
    if (inputLogin.email !== 'debug')
      try {
        await loginSchema.validate(inputLogin, { abortEarly: false })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err)
        setErrors(getErrorAsObject(err))
        return
      }
    dispatch(fetchPostLogin(inputLogin))
  }

  const handleLink = async (event: SyntheticEvent) => {
    event.preventDefault()
    dispatch(push('/forgot_password'))
  }

  useEffect(() => {
    if (!loginSelector.isForgotPasswordSuccess) {
      dispatch(loginModules.actions.resetLogin())
    }
  }, [])

  // パスワードリセット成功フラグのリセット
  useEffect(() => {
    if (loginSelector.isForgotPasswordSuccess) {
      setTimeout(() => {
        dispatch(loginModules.actions.resetIsForgotPasswordSuccess())
      }, 5000)
    }
  }, [loginSelector.isForgotPasswordSuccess])

  return (
    <div data-page="login">
      {/* パスワードリセットが成功してログインページに来た場合 */}
      {loginSelector.isForgotPasswordSuccess && (
        <div>
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success">パスワードリセットに成功しました。</Alert>
          </Snackbar>
        </div>
      )}
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="ログイン" isCenter={true} />
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit}>
          <div data-section="api-error">
            {loginSelector.error && (
              <ApiError
                error={loginSelector.error.error}
                message={loginSelector.error.message}
                status={loginSelector.error.status}
              />
            )}
          </div>
          {loginSelector.isLoading ? <CircularProgress /> : null}
          <Container className={styles.forms} maxWidth="sm" component={Paper} variant="outlined">
            <TextField
              fullWidth
              label="メールアドレス"
              margin="normal"
              value={inputLogin.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'email')
              }}
              error={Boolean(errors.email)}
              helperText={errors.email}
              data-section="email"
            />
            <TextField
              fullWidth
              type="password"
              label="パスワード"
              margin="normal"
              value={inputLogin.password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'password')
              }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              data-section="password"
            />
            <div className={styles.buttonWrap}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loginSelector.isLoading}
                data-section="loginButton"
              >
                Login
              </Button>
            </div>
            <div className={styles.textWrap}>
              <p>
                パスワードを忘れてしまった人は
                <Link href="/forgot_password" onClick={handleLink}>
                  こちら
                </Link>
              </p>
            </div>
          </Container>
        </Box>
      </Container>
    </div>
  )
}

export default Login
