import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE, PERMISSION_ERROR_MESSAGE } from '../const'
import { push } from 'connected-react-router'
import { callFindPlatform, callPutPlatformInfo, callPostPlatformInfo } from '../utils/apis/platform'
import { Platform, PutPlatformRequestParams, PostPlatformRequestParams } from '../types/platform'

export interface PlatformState {
  isLoading: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  platform?: Platform
  error?: ErrorResponse
}

export const PlatformState: PlatformState = {
  isLoading: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
}

const PlatformModules = createSlice({
  name: 'platform',
  initialState: PlatformState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindPlatformSuccess: (state, action: PayloadAction<Platform>) => ({
      ...state,
      isLoading: false,
      platform: action.payload,
    }),
    callPutPlatformInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callPostPlatformInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isRegistSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetPlatform: (state) => {
      const newState = { ...state }
      delete newState.platform
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsRegistSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return PlatformState
    },
  },
})

// プラットフォーム情報の取得処理
export const fetchGetPlatform = (Platform: string): AppThunk => async (dispatch) => {
  try {
    dispatch(PlatformModules.actions.resetError())
    dispatch(PlatformModules.actions.callApiStart())
    const res = await callFindPlatform(Platform)
    dispatch(PlatformModules.actions.callFindPlatformSuccess(res))

    dispatch(PlatformModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    if (message == PERMISSION_ERROR_MESSAGE) {
      dispatch(push('/platforms'))
      dispatch(PlatformModules.actions.resetError())
      dispatch(PlatformModules.actions.resetIsLoading())
    } else {
      dispatch(
        PlatformModules.actions.callApiError({
          status: status,
          error: error,
          message: message,
        })
      )
    }
  }
}

// プラットフォーム情報の変更処理
export const fetchPutPlatformInfo = (
  Platform: string,
  params: PutPlatformRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(PlatformModules.actions.resetError())
    dispatch(PlatformModules.actions.callApiStart())
    await callPutPlatformInfo(Platform, params)
    dispatch(PlatformModules.actions.callPutPlatformInfoSuccess())

    // 更新後にプラットフォームデータをセットする
    const res = await callFindPlatform(Platform)
    dispatch(PlatformModules.actions.callFindPlatformSuccess(res))
    dispatch(PlatformModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutPlatform error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      PlatformModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// プラットフォーム情報の登録処理
export const fetchPostPlatformInfo = (params: PostPlatformRequestParams): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(PlatformModules.actions.resetIsChangeSuccess())
    dispatch(PlatformModules.actions.resetError())
    dispatch(PlatformModules.actions.callApiStart())
    await callPostPlatformInfo(params)
    dispatch(PlatformModules.actions.callPostPlatformInfoSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutPlatform error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      PlatformModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default PlatformModules
