import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { push } from 'connected-react-router'
import { format } from 'date-fns'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { fetchGetPlatforms } from 'store/platforms'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import { DATETIME_FORMAT, EMAI_TEXT_DESTINATION_ARRAY, EMAI_TEXT_TYPE_ARRAY } from '../../const'
import EmailTextModules, {
  fetchCheckEmailTextExist,
  fetchGetEmailText,
  fetchPostEmailTextInfo,
  fetchPutEmailTextInfo,
} from '../../store/emailText'
import { selectEmailText, selectPlatforms } from '../../store/selector'
import { PostEmailTextRequestParams, PutEmailTextRequestParams } from '../../types/emailText'
import { scrollTop } from '../../utils/utils'
import {
  changeEmailTextSchema,
  getErrorAsObject,
  registerEmailTextSchema,
} from '../../utils/validate'
import EmailTextRuleModal from '../EmailTextRuleModal/index'
import styles from './styles.module.scss'

const EmailTextInput = () => {
  const dispatch = useDispatch()
  const { emailTextId } = useParams<{ emailTextId: string }>()
  const emailTextSelector = useSelector(selectEmailText)
  const PlatformsSelector = useSelector(selectPlatforms)
  const location = useLocation()

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PostEmailTextRequestParams]?: string
    }
  >({})

  // 新規作成フラグ
  const newFlag = location.pathname.match('/new') ? 1 : 0

  const [platformId, setPlatformId] = useState(0)
  const [destination, setDestination] = useState(0)
  const [type, setType] = useState(0)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  // プラットフォームID TODO:ログインユーザーのプラットフォームに修正 admin権限は全選択可能
  useEffect(() => {
    dispatch(fetchGetPlatforms())
  }, [dispatch])

  useEffect(() => {
    if (emailTextId) {
      dispatch(fetchGetEmailText(emailTextId))
    }
  }, [dispatch])

  // 取得した後にパラメータを設定
  useEffect(() => {
    if (!newFlag && emailTextSelector.emailText) {
      setPlatformId(emailTextSelector.emailText.platform_id)
      setDestination(emailTextSelector.emailText.destination)
      setType(emailTextSelector.emailText.type)
      setTitle(emailTextSelector.emailText.title || '')
      setBody(emailTextSelector.emailText.body || '')
    }
  }, [emailTextSelector.emailText])

  // メール文書の登録済みチェック
  useEffect(() => {
    if (newFlag && platformId && destination && type) {
      dispatch(fetchCheckEmailTextExist(platformId, destination, type))
    }
  }, [newFlag, platformId, destination, type])

  // 画面遷移時に登録済みメッセージをリセットする
  useLayoutEffect(() => {
    dispatch(EmailTextModules.actions.resetEmailTextCount())
  }, [location.pathname])

  // メール文書変更成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (emailTextSelector.isChangeSuccess) {
      setTimeout(() => {
        dispatch(EmailTextModules.actions.resetIsChangeSuccess())
      }, 5000)
    }
  }, [emailTextSelector.isChangeSuccess])

  // メール文書登録成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (emailTextSelector.isRegistSuccess) {
      setTimeout(() => {
        dispatch(EmailTextModules.actions.resetIsRegistSuccess())
      }, 5000)
    }
  }, [emailTextSelector.isRegistSuccess])

  const moveListPage = () => {
    // 画面遷移時にはstoreのメール文書をリセット
    dispatch(EmailTextModules.actions.resetEmailText())
    dispatch(push({ pathname: '/email_texts' }))
  }

  const getPostParams = (): PostEmailTextRequestParams => {
    return {
      platform_id: platformId,
      title: title,
      body: body,
      destination: destination,
      type: type,
    }
  }

  const getPutParams = (): PutEmailTextRequestParams | boolean => {
    if (!emailTextSelector.emailText) {
      return false
    }
    return {
      title: title,
      body: body,
    }
  }

  // 登録ボタン
  const handlePostSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPostCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPostParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPostEmailTextInfo(params))
    }
    scrollTop()
  }

  // 更新ボタン
  const handlePutSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPutCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPutParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPutEmailTextInfo(emailTextId, params))
    }
    scrollTop()
  }

  // 登録ボタン実行時のバリデーション
  const validationPostCheck = async (): Promise<boolean> => {
    const params = getPostParams()
    try {
      await registerEmailTextSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  // 更新ボタン実行時のバリデーション
  const validationPutCheck = async (): Promise<boolean> => {
    const params = getPutParams()
    try {
      await changeEmailTextSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  const [modalOpen, setModalOpen] = useState(false)

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  return (
    <div data-page="email-text-input">
      <Container className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            {newFlag ? (
              <Title title="メール文書登録" isCenter={true} />
            ) : (
              <Title title="メール文書編集" isCenter={true} />
            )}
          </Grid>
        </Grid>
        <Box component="form" className={styles.formBox}>
          {emailTextSelector.error ? (
            <div data-section="api-error">
              <ApiError
                error={emailTextSelector.error.error}
                message={emailTextSelector.error.message}
                status={emailTextSelector.error.status}
              />
            </div>
          ) : (
            <>
              {emailTextSelector.isLoading && <CircularProgress />}
              {!newFlag && !emailTextSelector.emailText ? (
                <p data-section="no-items">メール文書情報が見つかりません</p>
              ) : (
                <Grid container>
                  {emailTextSelector.isChangeSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>変更が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {emailTextSelector.isRegistSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>登録が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {emailTextSelector.emailTextCount !== 0 && (
                    <Grid container>
                      <Grid item xs={12} className={styles.error}>
                        <p data-section="exist">
                          <ErrorIcon />
                          <span>
                            選択したプラットフォーム、送信先、メールタイプのメール文書は登録済みです
                          </span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={12} className={styles.emailTextRadioGroup}>
                      <Box className={styles.emailTextRadio}>
                        <InputLabel>プラットフォーム</InputLabel>
                        <Select
                          label="プラットフォーム"
                          labelId="platform-id-label"
                          id="platform-id-select"
                          value={platformId}
                          disabled={!newFlag}
                          onChange={(e) => setPlatformId(Number(e.target.value))}
                          error={!!errors.platform_id}
                        >
                          <MenuItem key={0} value={0}>
                            選択してください
                          </MenuItem>
                          {PlatformsSelector.platforms.map((data) => {
                            return (
                              <MenuItem key={data.id} value={data.id}>
                                {data.platform_name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {Boolean(errors.platform_id) && (
                          <FormHelperText className={styles.selectError}>
                            {errors.platform_id}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box className={styles.emailTextRadio}>
                        <InputLabel>送信先</InputLabel>
                        <Select
                          label="送信先"
                          labelId="destination-label"
                          id="destination-select"
                          value={destination}
                          disabled={!newFlag}
                          onChange={(e) => setDestination(Number(e.target.value))}
                          error={!!errors.destination}
                        >
                          <MenuItem key={0} value={0}>
                            選択してください
                          </MenuItem>
                          {EMAI_TEXT_DESTINATION_ARRAY.map((data) => {
                            return (
                              <MenuItem key={data.id} value={data.id}>
                                {data.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {Boolean(errors.destination) && (
                          <FormHelperText className={styles.selectError}>
                            {errors.destination}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box className={styles.emailTextRadio}>
                        <InputLabel>メールタイプ</InputLabel>
                        <Select
                          label="メールタイプ"
                          labelId="destination-label"
                          id="destination-select"
                          value={type}
                          disabled={!newFlag}
                          onChange={(e) => setType(Number(e.target.value))}
                          error={!!errors.type}
                        >
                          <MenuItem key={0} value={0}>
                            選択してください
                          </MenuItem>
                          {EMAI_TEXT_TYPE_ARRAY.map((data) => {
                            return (
                              <MenuItem key={data.id} value={data.id}>
                                {data.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {Boolean(errors.type) && (
                          <FormHelperText className={styles.selectError}>
                            {errors.type}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={styles.rule}>
                        <Button
                          className={styles.changeButton}
                          onClick={handleOpenModal}
                          variant="contained"
                          data-section="change-button"
                        >
                          メール文書の変数埋め込みルールはこちら
                        </Button>
                        <EmailTextRuleModal open={modalOpen} onClose={handleCloseModal} />
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          className={
                            !newFlag && title !== emailTextSelector.emailText?.title
                              ? styles.isChange
                              : ''
                          }
                          label="メールタイトル"
                          type="text"
                          data-section="title"
                          variant="outlined"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          error={!!errors.title}
                          helperText={errors.title}
                          fullWidth
                        />
                        {emailTextSelector.emailText?.title &&
                          title !== emailTextSelector.emailText?.title && (
                            <small data-section="prev-email-text">
                              <br />
                              変更前メールタイトル：{emailTextSelector.emailText?.title}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          className={
                            !newFlag && body !== emailTextSelector.emailText?.body
                              ? styles.isChange
                              : ''
                          }
                          label="メール本文"
                          type="text"
                          value={body}
                          multiline
                          rows={20}
                          onChange={(e) => setBody(e.target.value)}
                          error={Boolean(errors.body)}
                          helperText={errors.body}
                          data-section="body"
                          fullWidth
                        />
                        {emailTextSelector.emailText?.body &&
                          body !== emailTextSelector.emailText?.body && (
                            <TextField
                              className={styles.bodyBeforeChange}
                              label="変更前メール本文"
                              type="text"
                              value={emailTextSelector.emailText?.body}
                              multiline
                              rows={20}
                              fullWidth
                              disabled={true}
                            />
                          )}
                      </Box>
                      {!newFlag && (
                        <Grid container className={styles.timeInfoContainer}>
                          <Grid item xs={12}>
                            <p data-section="created_at">
                              <span>作成日時：</span>
                              {format(
                                new Date(emailTextSelector.emailText?.created_at || ''),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p data-section="updated_at">
                              <span>更新日時：</span>
                              {format(
                                new Date(emailTextSelector.emailText?.updated_at || ''),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button onClick={() => moveListPage()} variant="contained" data-section="back">
                戻る
              </Button>
              {!emailTextSelector.error && newFlag ? (
                <Button
                  onClick={() => {
                    handlePostSubmit()
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  value="post"
                  data-section="register"
                  disabled={emailTextSelector.emailTextCount == 0 ? false : true}
                >
                  登録する
                </Button>
              ) : (
                ''
              )}
              {!emailTextSelector.error && !newFlag && (
                <Button
                  onClick={() => {
                    handlePutSubmit()
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  value="post"
                  data-section="update"
                >
                  更新する
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default EmailTextInput
