import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGetPolicyNumbers } from 'store/policyNumbers'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import { selectPolicyNumbers } from '../../store/selector'
import styles from './styles.module.scss'

export interface PolicyNumbersState {
  policyNumber: string
}

const PolicyNumbers = () => {
  const dispatch = useDispatch()
  const PolicyNumbersSelector = useSelector(selectPolicyNumbers)

  const moveNewPage = () => {
    dispatch(push('/policy_number/new'))
  }

  const moveEditPage = (id: number) => {
    dispatch(push(`/policy_number/${id}/edit`))
  }

  useEffect(() => {
    dispatch(fetchGetPolicyNumbers())
  }, [dispatch])

  return (
    <div data-page="policy-numbers">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="証券番号一覧" isCenter={true} />
          </Grid>
        </Grid>
        <Box className={styles.newCreateBtn}>
          <Button onClick={moveNewPage} color="primary" variant="contained" data-section="new">
            新規作成
          </Button>
        </Box>
        <Box>
          {PolicyNumbersSelector.error ? (
            <div data-section="get-api-error">
              <ApiError
                error={PolicyNumbersSelector.error.error}
                message={PolicyNumbersSelector.error.message}
                status={PolicyNumbersSelector.error.status}
              />
            </div>
          ) : PolicyNumbersSelector.isLoading ? (
            <CircularProgress />
          ) : (
            <Box mt={3}>
              <Container maxWidth="sm">
                <TableContainer component={Paper} className={styles.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>プラットフォーム</TableCell>
                        <TableCell>証券番号</TableCell>
                        <TableCell>対象年月</TableCell>
                        <TableCell>操作</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!PolicyNumbersSelector.policyNumbers?.length ? (
                        <TableRow>
                          <TableCell align="center" colSpan={9}>
                            証券番号データがありません
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {PolicyNumbersSelector.policyNumbers.map((data) => {
                            return (
                              <TableRow key={data.id} data-section={`row${data.id}`}>
                                <TableCell data-section="id">{data.id}</TableCell>
                                <TableCell data-section="platform">{data.platform_name}</TableCell>
                                <TableCell data-section="policy-number">
                                  {data.policy_number}
                                </TableCell>
                                <TableCell data-section="month">
                                  {moment(data.year + data.month).format('YYYY年MM月')}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => moveEditPage(data.id)}
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                  >
                                    編集
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  )
}

export default PolicyNumbers
