import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import axios from '../apis/axios'
import { INTERNAL_SERVER_ERROR_MESSAGE, ISO_YYYYMMDD } from '../const'
import { InsuranceInterface } from '../types/insurance'
import { ErrorResponse } from './error'
import { AppThunk } from './index'

export interface SortOrderParams {
  memberId?: 'asc' | 'desc'
  contractorId?: 'asc' | 'desc'
  policyNumber?: 'asc' | 'desc'
  platformName?: 'asc' | 'desc'
  insuranceProduct?: 'asc' | 'desc'
  applicationDate?: 'asc' | 'desc'
  contractPeriodStart?: 'asc' | 'desc'
  contractPeriodEnd?: 'asc' | 'desc'
  startFrom?: 'asc' | 'desc'
  startTo?: 'asc' | 'desc'
  cancelledAt?: 'asc' | 'desc'
  companyName?: 'asc' | 'desc'
  contractState?: 'asc' | 'desc'
  paymentState?: 'asc' | 'desc'
}
// 加入者一覧検索時のリクエストパラメータ
export interface InsuranceSearchParams {
  companyName: string // 法人名
  contractPeriodStartFrom: Date | null
  contractPeriodStartTo: Date | null
  contractPeriodEndFrom: Date | null
  contractPeriodEndTo: Date | null
  policyNumberId: number // 証券番号ID
  platformId: number // プラットフォームID
  contractorId: string // 契約者ID
  insuranceProduct: number // 保険プラン {ライトプラン | スタンダードプラン | プレミアムプラン}
  pageNumber: number // Paginationの現在ページ番号
  perPage: number // 1ページあたりの加入者レコード数
  sortOrderParams: SortOrderParams // メンバーIDのソート順
}

export interface InsuranceStatusChangeParams {
  insurance_ids: Array<number>
  is_sales: number
}

export interface InsurancesState {
  isLoading: boolean
  insurancesList: InsuranceInterface[]
  error?: ErrorResponse
  nObject: number
}

export const insurancesState: InsurancesState = {
  isLoading: false,
  insurancesList: [],
  nObject: 0,
}

const InsurancesModules = createSlice({
  name: 'insurances',
  initialState: insurancesState,
  reducers: {
    callFindInsurancesStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindInsurancesSuccess: (state, action: PayloadAction<InsuranceInterface[]>) => ({
      ...state,
      isLoading: false,
      insurancesList: action.payload,
    }),
    callSetNObject: (state, action: PayloadAction<number>) => ({
      ...state,
      nObject: action.payload,
    }),
    callFindInsurancesError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetInsurancesList: (state) => ({
      ...state,
      insurancesList: [],
    }),
    updateInsuranceContractStart: (state, action: PayloadAction<{ id: string; date: string }>) => ({
      ...state,
      insurancesList: state.insurancesList.map((insurance) =>
        insurance.id === action.payload.id
          ? { ...insurance, contract_period_start: action.payload.date }
          : insurance
      ),
    }),
    updateInsuranceContractEnd: (state, action: PayloadAction<{ id: string; date: string }>) => ({
      ...state,
      insurancesList: state.insurancesList.map((insurance) =>
        insurance.id === action.payload.id
          ? { ...insurance, contract_period_end: action.payload.date }
          : insurance
      ),
    }),
    callUpdateInsurancePeriodSuccess: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
})

export interface FindInsurancesResponse {
  insurances: InsuranceInterface[]
  nObject: number
}

const callFindInsurances = async (params: InsuranceSearchParams) => {
  const { data }: AxiosResponse<FindInsurancesResponse> = await axios.get('/insurances', {
    params: {
      ...params,
      contractPeriodStartFrom: params.contractPeriodStartFrom
        ? format(params.contractPeriodStartFrom, ISO_YYYYMMDD)
        : null,
      contractPeriodStartTo: params.contractPeriodStartTo
        ? format(params.contractPeriodStartTo, ISO_YYYYMMDD)
        : null,
      contractPeriodEndFrom: params.contractPeriodEndFrom
        ? format(params.contractPeriodEndFrom, ISO_YYYYMMDD)
        : null,
      contractPeriodEndTo: params.contractPeriodEndTo
        ? format(params.contractPeriodEndTo, ISO_YYYYMMDD)
        : null,
    },
  })

  return data
}

const callChangeInsurancesStatus = async (params: InsuranceStatusChangeParams) => {
  const { data }: AxiosResponse<FindInsurancesResponse> = await axios.post(
    '/insurances/status',
    JSON.stringify({
      params: params,
    })
  )
  return data.insurances
}

export const fetchFindInsurances = (params: InsuranceSearchParams): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(InsurancesModules.actions.resetError())
    // dispatch(InsurancesModules.actions.callFindInsurancesStart()) // 画面が動いてしまうので使わないことを検討
    const res = await callFindInsurances(params)
    dispatch(InsurancesModules.actions.callFindInsurancesSuccess(res.insurances))
    dispatch(InsurancesModules.actions.callSetNObject(res.nObject))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurances error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsurancesModules.actions.callFindInsurancesError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchChangeInsurancesStatus = (
  params: InsuranceStatusChangeParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(InsurancesModules.actions.resetError())
    dispatch(InsurancesModules.actions.callFindInsurancesStart())
    const res: InsuranceInterface[] = await callChangeInsurancesStatus(params)
    dispatch(InsurancesModules.actions.callFindInsurancesSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurances error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsurancesModules.actions.callFindInsurancesError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default InsurancesModules
