import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { callGetSalesOfficesByPlatformId } from '../utils/apis/salesOffice'
import { SalesOffice } from '../types/salesOffice'

export interface SalesOfficesState {
  isLoading: boolean
  salesOffices: SalesOffice[]
  error?: ErrorResponse
}

export const salesOfficesState: SalesOfficesState = {
  isLoading: false,
  salesOffices: [],
}

export interface PutSalesOfficeRequestParams {
  salesOfficeId: string
}

const SalesOfficesModules = createSlice({
  name: 'salesOffices',
  initialState: salesOfficesState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetSalesOfficesSuccess: (state, action: PayloadAction<SalesOffice[]>) => ({
      ...state,
      isLoading: false,
      salesOffices: action.payload,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
  },
})

export const fetchGetSalesOffices = (platformId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(SalesOfficesModules.actions.resetError())
    dispatch(SalesOfficesModules.actions.callApiStart())
    const res = await callGetSalesOfficesByPlatformId(platformId)
    dispatch(SalesOfficesModules.actions.callGetSalesOfficesSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetSalesOffices error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      SalesOfficesModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default SalesOfficesModules
