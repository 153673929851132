import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Paper,
  Container,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { format } from 'date-fns'

import ApiError from 'components/ApiError'
import styles from './styles.module.scss'
import Title from 'components/Title'
import { selectAdmin, selectLogin } from 'store/selector'
import AdminModules, { fetchGetAdminGetUsers, fetchPostAdminDeleteUser } from '../../../store/admin'
import { CognitoGroupNameEnum } from '../../../types/login'
import { useHistory } from 'react-router-dom'

const AdminUsers = () => {
  const dispatch = useDispatch()
  const adminSelector = useSelector(selectAdmin)
  const loginSelector = useSelector(selectLogin)

  const [deleteUserEmail, setDeleteUserEmail] = useState('')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const history = useHistory()

  useLayoutEffect(() => {
    if (loginSelector.cognitoGroupName !== CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME) {
      moveErrorPage('権限がありません')
    }
  }, [location.pathname])

  const moveErrorPage = (errorMessage: string) => {
    history.push({
      pathname: '/error',
      state: { errorMessage: errorMessage },
    })
  }

  // 削除確認ダイアログを表示
  const handleDeleteDialogOpen = (userEmail: string) => {
    setDeleteUserEmail(userEmail)
    setIsDeleteDialogOpen(true)
  }

  // 削除確認ダイアログを非表示
  const handleDeleteDialogClose = () => {
    setDeleteUserEmail('')
    setIsDeleteDialogOpen(false)
  }

  // ユーザー削除実行
  const handleDeleteUser = () => {
    // ダイアログを非表示
    setIsDeleteDialogOpen(false)
    dispatch(fetchPostAdminDeleteUser({ email: deleteUserEmail }))
  }

  // 削除成功ダイアログの非表示
  const handleDeleteUserSuccessDialogClose = () => {
    // ユーザー情報をリセット
    setDeleteUserEmail('')
    // 削除成功フラグをリセット
    dispatch(AdminModules.actions.resetIsDeleteUserSuccess())
    // 最新のユーザー一覧取得
    dispatch(fetchGetAdminGetUsers())
  }

  useEffect(() => {
    // ユーザー一覧取得
    dispatch(fetchGetAdminGetUsers())
  }, [])

  return (
    <div data-page="admin-users">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="管理者用ユーザー一覧" isCenter={true} />
          </Grid>
        </Grid>
        <div data-section="api-error">
          {adminSelector.error && (
            <ApiError
              error={adminSelector.error.error}
              message={adminSelector.error.message}
              status={adminSelector.error.status}
            />
          )}
        </div>
        {adminSelector.isLoading && <CircularProgress />}
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ユーザーID</TableCell>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>電話番号</TableCell>
                    <TableCell>ステータス</TableCell>
                    <TableCell>権限</TableCell>
                    <TableCell>プラットフォーム名</TableCell>
                    <TableCell>代理店名</TableCell>
                    <TableCell>作成日時</TableCell>
                    <TableCell>更新日時</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminSelector.users.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={9} data-section="noItems">
                        ユーザーが存在しません。
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {adminSelector.users.map((data, i) => {
                        return (
                          <TableRow key={i} data-section={`row${i}`}>
                            <TableCell data-section="user_name">{data.Username}</TableCell>
                            <TableCell data-section="email">{data.email}</TableCell>
                            <TableCell data-section="phone_number">{data.phone_number}</TableCell>
                            <TableCell data-section="status">{data.UserStatus}</TableCell>
                            <TableCell data-section="group_name">{data.GroupName}</TableCell>
                            <TableCell data-section="platform_name">{data.platform_name}</TableCell>
                            <TableCell data-section="agency_name">{data.agency_name}</TableCell>
                            <TableCell data-section="created_date">
                              {format(Date.parse(data.UserCreateDate), 'yyyy-MM-dd')}
                            </TableCell>
                            <TableCell data-section="updated_date">
                              {format(Date.parse(data.UserLastModifiedDate), 'yyyy-MM-dd')}
                            </TableCell>
                            <TableCell data-section="control">
                              {/* ユーザー一覧を管理者権限のみ表示する様に調整中 書き分岐は不要になる */}
                              {/* 管理者権限のみ表示 */}
                              {loginSelector.cognitoGroupName ===
                                CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME && (
                                <Button
                                  onClick={() => {
                                    handleDeleteDialogOpen(data.email)
                                  }}
                                  variant="contained"
                                  color="primary"
                                  disabled={adminSelector.isLoading}
                                >
                                  削除
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      {/* 削除確認ダイアログ */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ユーザー削除</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteUserEmail}
            を削除します。実行すると元には戻せません。
            <br />
            よろしいでしょうか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} variant="contained" color="secondary">
            閉じる
          </Button>
          <Button onClick={handleDeleteUser} variant="contained" color="primary">
            実行
          </Button>
        </DialogActions>
      </Dialog>
      {/* 削除完了ダイアログ */}
      <Dialog
        open={adminSelector.isDeleteUserSuccess}
        onClose={handleDeleteUserSuccessDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ユーザー削除完了</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteUserEmail}の削除が成功しました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteUserSuccessDialogClose}
            variant="contained"
            color="secondary"
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AdminUsers
