import axios from '../../apis/axios'

/*
 * Truncate
 */
export const callTruncateTable = async (name: string) => {
  if (['local', 'development', 'staging'].includes(process.env.REACT_APP_ENV)) {
    await axios.delete(`truncate/${name}`)
  }
}
