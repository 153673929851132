import { createSlice } from '@reduxjs/toolkit'

import { AppThunk } from './index'
import LoginModules from './login'
import { callPostUserRefreshToken } from '../utils/apis/user'

export interface UserState {
  isLoading: boolean
}

const initialState: UserState = {
  isLoading: false,
}

const UserModules = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callApiSuccess: (state) => ({
      ...state,
      isLoading: false,
    }),
    callApiError: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
})

// トークンリフレッシュ
export const fetchPostUserRefreshToken = (): AppThunk => async (dispatch, getState) => {
  try {
    const loginState = getState().login
    dispatch(UserModules.actions.callApiStart())
    const res = await callPostUserRefreshToken({
      refresh_token: loginState.refresh_token,
    })
    dispatch(LoginModules.actions.setIdToken(res.id_token))
    dispatch(UserModules.actions.callApiSuccess())
  } catch (e) {
    console.log('fetchPostUserRefreshToken error')
    console.log(e)

    // エラーが起きた場合はログインページにリダイレクト
    window.location.replace('/login')
  }
}

export default UserModules
