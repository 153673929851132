import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ApiError from '../../components/ApiError'
import styles from './styles.module.scss'
import InsuranceOfficeModules, {
  fetchGetInsuranceOffice,
  fetchPutInsuranceOfficeInfo,
  fetchPostInsuranceOfficeInfo,
} from '../../store/insuranceOffice'
import Title from '../../components/Title'
import { selectInsuranceOffice, selectLogin } from '../../store/selector'
import {
  PutInsuranceOfficeRequestParams,
  PostInsuranceOfficeRequestParams,
} from '../../types/insuranceOffice'
import {
  changeInsuranceOfficeInfoSchema,
  registerInsuranceOfficeInfoSchema,
  getErrorAsObject,
} from '../../utils/validate'
import { DATETIME_FORMAT } from 'const'
import { format } from 'date-fns'
import { CognitoGroupNameEnum } from '../../types/login'
import { push } from 'connected-react-router'

export interface LocationState {
  platformName: string
}

const InsuranceOffice = () => {
  const { platformId, insuranceOfficeId } = useParams<{
    platformId: string
    insuranceOfficeId: string
  }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { platformName } = location.state ? (location.state as LocationState) : { platformName: '' }
  // 新規作成フラグ
  const newFlag = location.pathname.match('/new') ? 1 : 0

  // 保険サービス課名
  const [insuranceOfficeName, setInsuranceOfficeName] = useState('')
  // 保険サービス課名カナ
  const [insuranceOfficeNameKana, setInsuranceOfficeNameKana] = useState('')
  // 契約用メールアドレス
  const [contractEmail, setContractEmail] = useState('')
  // 契約用メールアドレス有効フラグ
  const [isNotificateContract, setIsNotificateContract] = useState(newFlag ? '1' : '')
  // 事故連絡用メールアドレス
  const [accidentEmail, setAccidentEmail] = useState('')
  // 事故連絡用メールアドレス有効フラグ
  const [isNotificateAccident, setIsNotificateAccident] = useState(newFlag ? '1' : '')

  const insuranceOfficeSelector = useSelector(selectInsuranceOffice)
  const loginSelector = useSelector(selectLogin)

  useLayoutEffect(() => {
    if (loginSelector.cognitoGroupName !== CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME) {
      dispatch(push({ pathname: '/platforms' }))
    }
  }, [location.pathname])

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PostInsuranceOfficeRequestParams]?: string
    }
  >({})

  const isNotificateContractDefaultValue = newFlag
    ? '1'
    : String(insuranceOfficeSelector?.insuranceOffice?.is_notificate_contract)
  const isNotificateAccidentDefaultValue = newFlag
    ? '1'
    : String(insuranceOfficeSelector?.insuranceOffice?.is_notificate_accident)

  useEffect(() => {
    if (!newFlag && platformId && insuranceOfficeId) {
      dispatch(fetchGetInsuranceOffice(platformId, insuranceOfficeId))
    }
  }, [dispatch])

  // 保険サービス課変更成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (insuranceOfficeSelector.isChangeSuccess) {
      setTimeout(() => {
        dispatch(InsuranceOfficeModules.actions.resetIsChangeSuccess())
      }, 5000)
    }
  }, [insuranceOfficeSelector.isChangeSuccess])

  // 保険サービス課登録成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (insuranceOfficeSelector.isRegistSuccess) {
      setTimeout(() => {
        dispatch(InsuranceOfficeModules.actions.resetIsRegistSuccess())
      }, 5000)
    }
  }, [insuranceOfficeSelector.isRegistSuccess])

  // 取得した後にパラメータを設定
  useEffect(() => {
    if (!newFlag && insuranceOfficeSelector) {
      setInsuranceOfficeName(insuranceOfficeSelector?.insuranceOffice?.insurance_office_name || '')
      setInsuranceOfficeNameKana(
        insuranceOfficeSelector?.insuranceOffice?.insurance_office_name_kana || ''
      )
      setContractEmail(insuranceOfficeSelector?.insuranceOffice?.contract_email || '')
      setIsNotificateContract(
        String(insuranceOfficeSelector?.insuranceOffice?.is_notificate_contract) || ''
      )
      setAccidentEmail(insuranceOfficeSelector?.insuranceOffice?.accident_email || '')
      setIsNotificateAccident(
        String(insuranceOfficeSelector?.insuranceOffice?.is_notificate_accident) || ''
      )
    }
  }, [newFlag, insuranceOfficeSelector.insuranceOffice])

  const moveListPage = (platformId: string) => {
    // 画面遷移時にはstoreの保険サービス課をリセット
    dispatch(InsuranceOfficeModules.actions.resetInsuranceOffice())
    history.push({
      pathname: `/insurance_offices/${platformId}`,
      state: { platformName: platformName },
    })
  }

  const getPostParams = (): PostInsuranceOfficeRequestParams => {
    return {
      insurance_office_name: insuranceOfficeName,
      insurance_office_name_kana: insuranceOfficeNameKana,
      contract_email: contractEmail,
      is_notificate_contract: Number(isNotificateContract),
      accident_email: accidentEmail,
      is_notificate_accident: Number(isNotificateAccident),
    }
  }

  const getPutParams = (): PutInsuranceOfficeRequestParams | boolean => {
    if (!insuranceOfficeSelector.insuranceOffice) {
      return false
    }
    return {
      insurance_office_name: insuranceOfficeName,
      insurance_office_name_kana: insuranceOfficeNameKana,
      contract_email: contractEmail,
      is_notificate_contract: Number(isNotificateContract),
      accident_email: accidentEmail,
      is_notificate_accident: Number(isNotificateAccident),
    }
  }

  // 登録ボタン
  const handlePostSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPostCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPostParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPostInsuranceOfficeInfo(platformId, params))
    }
  }

  //更新ボタン
  const handlePutSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPutCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPutParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPutInsuranceOfficeInfo(platformId, insuranceOfficeId, params))
    }
  }

  // 登録ボタン実行時のバリデーション
  const validationPostCheck = async (): Promise<boolean> => {
    const params = getPostParams()
    console.log(params)
    try {
      await registerInsuranceOfficeInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  // 更新ボタン実行時のバリデーション
  const validationPutCheck = async (): Promise<boolean> => {
    const params = getPutParams()
    try {
      await changeInsuranceOfficeInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  return (
    <div data-page="insurance-office-input">
      <Container className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            {newFlag ? (
              <Title title="保険サービス課登録" isCenter={true} />
            ) : (
              <Title title="保険サービス課編集" isCenter={true} />
            )}
          </Grid>
        </Grid>
        <Box component="form" className={styles.formBox}>
          {insuranceOfficeSelector.error ? (
            <div data-section="api-error">
              <ApiError
                error={insuranceOfficeSelector.error.error}
                message={insuranceOfficeSelector.error.message}
                status={insuranceOfficeSelector.error.status}
              />
            </div>
          ) : (
            <>
              {insuranceOfficeSelector.isLoading && <CircularProgress />}
              {!newFlag && !insuranceOfficeSelector.insuranceOffice ? (
                <p data-section="no-items">保険サービス課情報が見つかりません</p>
              ) : (
                <Grid container>
                  {insuranceOfficeSelector.isChangeSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>変更が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {insuranceOfficeSelector.isRegistSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>登録が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} className={styles.info}>
                    <p data-section="platform_name">
                      <span>プラットフォーム：</span>
                      <strong>{platformName}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <form>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="保険サービス課名"
                          type="text"
                          data-section="insurance_office_name"
                          variant="outlined"
                          value={insuranceOfficeName}
                          onChange={(e) => setInsuranceOfficeName(e.target.value)}
                          error={!!errors.insurance_office_name}
                          helperText={errors.insurance_office_name}
                          fullWidth
                        />
                        {!newFlag &&
                          insuranceOfficeName !==
                            insuranceOfficeSelector?.insuranceOffice?.insurance_office_name && (
                            <small data-section="prev_insurance_office_name">
                              変更前保険サービス課名：
                              {insuranceOfficeSelector?.insuranceOffice?.insurance_office_name}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="保険サービス課名カナ"
                          type="text"
                          data-section="insurance_office_name_kana"
                          variant="outlined"
                          value={insuranceOfficeNameKana}
                          onChange={(e) => setInsuranceOfficeNameKana(e.target.value)}
                          error={!!errors.insurance_office_name_kana}
                          helperText={errors.insurance_office_name_kana}
                          fullWidth
                        />
                        {!newFlag &&
                          insuranceOfficeNameKana !==
                            insuranceOfficeSelector?.insuranceOffice
                              ?.insurance_office_name_kana && (
                            <small data-section="prev_insurance_office_name_kana">
                              変更前保険サービス課名カナ：
                              {insuranceOfficeSelector?.insuranceOffice?.insurance_office_name_kana}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="契約用メールアドレス"
                          type="text"
                          data-section="contract_email"
                          variant="outlined"
                          value={contractEmail}
                          onChange={(e) => setContractEmail(e.target.value)}
                          error={!!errors.contract_email}
                          helperText={errors.contract_email}
                          fullWidth
                        />
                        {!newFlag &&
                          contractEmail !==
                            insuranceOfficeSelector?.insuranceOffice?.contract_email && (
                            <small data-section="prev_contract_email">
                              変更前契約用メールアドレス：
                              {insuranceOfficeSelector?.insuranceOffice?.contract_email}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.radioWrap}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">契約用メールアドレス有効フラグ</FormLabel>
                          <RadioGroup
                            row={true}
                            aria-label="gender"
                            name="controlled-radio-buttons-group"
                            defaultValue={isNotificateContractDefaultValue}
                            onChange={(e) => setIsNotificateContract(e.target.value)}
                            style={{ margin: '0 0 -10px 0' }}
                          >
                            <FormControlLabel value="1" control={<Radio />} label="有効" />
                            <FormControlLabel value="0" control={<Radio />} label="無効" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {!newFlag &&
                        isNotificateContract !==
                          String(
                            insuranceOfficeSelector?.insuranceOffice?.is_notificate_contract
                          ) && (
                          <small data-section="prev_contract_email" className={styles.beforChange}>
                            変更前契約用メールアドレス有効フラグ：
                            {insuranceOfficeSelector?.insuranceOffice?.is_notificate_contract == 1
                              ? '有効'
                              : '無効'}
                          </small>
                        )}
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="事故連絡用メールアドレス"
                          type="text"
                          data-section="accident_email"
                          variant="outlined"
                          value={accidentEmail}
                          onChange={(e) => setAccidentEmail(e.target.value)}
                          error={!!errors.accident_email}
                          helperText={errors.accident_email}
                          fullWidth
                        />
                        {!newFlag &&
                          accidentEmail !==
                            insuranceOfficeSelector?.insuranceOffice?.accident_email && (
                            <small data-section="prev_accident_email">
                              変更前契約用メールアドレス：
                              {insuranceOfficeSelector?.insuranceOffice?.accident_email}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.radioWrap}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            事故連絡用メールアドレス有効フラグ
                          </FormLabel>
                          <RadioGroup
                            row={true}
                            aria-label="gender"
                            name="controlled-radio-buttons-group"
                            defaultValue={isNotificateAccidentDefaultValue}
                            onChange={(e) => setIsNotificateAccident(e.target.value)}
                            style={{ margin: '0 0 -10px 0' }}
                          >
                            <FormControlLabel value="1" control={<Radio />} label="有効" />
                            <FormControlLabel value="0" control={<Radio />} label="無効" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {!newFlag &&
                        isNotificateAccident !==
                          String(
                            insuranceOfficeSelector?.insuranceOffice?.is_notificate_accident
                          ) && (
                          <small data-section="prev_contract_email" className={styles.beforChange}>
                            変更前事故連絡用メールアドレス有効フラグ：
                            {insuranceOfficeSelector?.insuranceOffice?.is_notificate_accident == 1
                              ? '有効'
                              : '無効'}
                          </small>
                        )}
                      {!newFlag && (
                        <Grid container className={styles.timeInfoContainer}>
                          <Grid item xs={12}>
                            <p data-section="created_at">
                              <span>作成日時：</span>
                              {format(
                                new Date(
                                  insuranceOfficeSelector?.insuranceOffice?.created_at || ''
                                ),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p data-section="updated_at">
                              <span>更新日時：</span>
                              {format(
                                new Date(
                                  insuranceOfficeSelector?.insuranceOffice?.updated_at || ''
                                ),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </form>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button
                onClick={() => moveListPage(platformId)}
                variant="contained"
                data-section="back"
              >
                戻る
              </Button>
              {!insuranceOfficeSelector.error && (
                <>
                  {newFlag ? (
                    <Button
                      onClick={() => {
                        handlePostSubmit()
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="post"
                      data-section="register"
                    >
                      登録する
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handlePutSubmit()
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="post"
                      data-section="register"
                    >
                      更新する
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default InsuranceOffice
