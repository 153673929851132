import { AxiosResponse } from 'axios'

import axios from '../../apis/axios'

export interface PostUserRefreshTokenRequestParams {
  refresh_token: string
}

export interface PostUserRefreshTokenRequestResponse {
  id_token: string
}

// ユーザー作成
export const callPostUserRefreshToken = async (params: PostUserRefreshTokenRequestParams) => {
  const { data }: AxiosResponse<PostUserRefreshTokenRequestResponse> = await axios.post(
    '/user/refresh_token',
    JSON.stringify({
      params: params,
    })
  )
  return data
}
