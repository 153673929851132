import axios from '../../apis/axios'
import { AxiosResponse } from 'axios'
import {
  InsuranceOfficeResponce,
  InsuranceOfficesResponce,
  PostInsuranceOfficeRequestParams,
  PutInsuranceOfficeRequestParams,
} from '../../types/insuranceOffice'

// 保険サービス課一覧取得
export const callGetInsuranceOfficesByPlatformId = async (platformId: string) => {
  const { data }: AxiosResponse<InsuranceOfficesResponce> = await axios.get(
    `/insurance_offices/${platformId}`
  )
  return data.insurance_offices
}

// 保険サービス課詳細取得
export const callFindInsuranceOffice = async (platformId: string, insuranceOfficeId: string) => {
  const { data }: AxiosResponse<InsuranceOfficeResponce> = await axios.get(
    `/insurance_office/${platformId}/${insuranceOfficeId}`
  )
  return data.insurance_office
}

// 保険サービス課情報更新
export const callPutInsuranceOfficeInfo = async (
  platformId: string,
  insuranceOfficeId: string,
  params: PutInsuranceOfficeRequestParams
) => {
  await axios.put(`/insurance_office/${platformId}/${insuranceOfficeId}`, {
    params: params,
  })
}

// 保険サービス課情報登録
export const callPostInsuranceOfficeInfo = async (
  platformId: string,
  params: PostInsuranceOfficeRequestParams
) => {
  await axios.post(`/insurance_office/${platformId}`, {
    params: params,
  })
}
