import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import axios from '../apis/axios'
import { InsuranceAdminChangeHistories } from '../types/insuranceAdminChangeHistories'

export interface InsuranceAdminChangeHistoriesState {
  insuranceAdminChangeHistories: InsuranceAdminChangeHistories[]
}

const insuranceAdminChangeHistoriesState: InsuranceAdminChangeHistoriesState = {
  insuranceAdminChangeHistories: [],
}

const InsuranceAdminChangeHistoriesModules = createSlice({
  name: 'insuranceAdminChangeHistories',
  initialState: insuranceAdminChangeHistoriesState,
  reducers: {
    setInsuranceAdminChangeHistories: (
      state,
      action: PayloadAction<InsuranceAdminChangeHistories[]>
    ) => ({
      ...state,
      insuranceAdminChangeHistories: action.payload,
    }),
    resetInsuranceAdminChangeHistories: (state) => ({
      ...state,
      insuranceAdminChangeHistories: [],
    }),
  },
})

export interface GetInsuranceAdminChangeHistoriesResponse {
  insurance_admin_change_histories: InsuranceAdminChangeHistories[]
}

export const callGetInsuranceAdminChangeHistories = async (insuranceId: string) => {
  const { data }: AxiosResponse<GetInsuranceAdminChangeHistoriesResponse> = await axios.get(
    `/insurance_admin_change_histories/${insuranceId}`
  )
  return data.insurance_admin_change_histories
}

export default InsuranceAdminChangeHistoriesModules
