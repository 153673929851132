export enum InsuranceProductEnum {
  LITE = 1,
  STANDARD,
  PREMIUM,
}

export enum InsuranceIsSalesEnum {
  // 未入金
  TEMPORARY_SALES,
  // 入金済
  ACTUAL_SALES,
  // 解約済
  CANCEL,
}

// ラジオボタンの設定 ※numberが使用できないために下記の記述
export enum SalesStatusEnum {
  // 未入金
  TEMPORARY_SALES = '0',
  // 入金済
  ACTUAL_SALES = '1',
  // 解約済
  CANCEL = '2',
}

export enum EsiredInsuranceStartDateEnum {
  // 当月始期
  TODAY = 1,
  // 翌月始期
  NEXT_MONTH = 2,
}

/*
 * 証券番号情報
 */
export interface PolicyNumber {
  id: number
  policy_number: string
  platform_id: number
  year: string
  month: string
}

export interface InsuranceCommonInterface {
  // 会員番号
  member_id: string
  // 加入者番号
  contractor_id: string
  // 証券番号
  policy_number: PolicyNumber
  // プラットフォームID
  platform_id: number
  // プラットフォーム名
  platform_name: string
  // 保険商品(1 or 2 or 3)
  insurance_product: InsuranceProductEnum
  // 代理店コード
  agency_id: number
  // 保険料
  fee_amount: number
  // 申込日
  application_date: string
  // 契約期間開始
  contract_period_start: string
  // 契約期間終了
  contract_period_end: string
  // 実売上計上フラグ
  is_sales: InsuranceIsSalesEnum
  // 氏名(漢字)
  contractor_name: string
  // メールアドレス
  email: string
  // 電話番号
  phone: string
  // 企業名/屋号
  company_name: string
  // 業種
  industry: string
  // 売上高
  sales_amount: number
  // 郵便番号
  zip: string
  // 住所
  address: string
  // 他社保険会社名
  other_insurance_company: string
  // 他社保険種類
  other_insurance_type: string
  // 他社満期日
  other_insurance_period: string
  // 解約日時
  cancelled_at: string | null
  created_at: string
  updated_at: string
  // 削除日時
  deleted_at: string | null
  // 保険始期
  esired_insurance_start_date: EsiredInsuranceStartDateEnum
  // ご担当者名
  manager_name: string
  // ステータス
  contract_state: string
  payment_state: string
}

export interface InsuranceInterface extends InsuranceCommonInterface {
  // 保険商品ID
  id: string
}
