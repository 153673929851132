import { AxiosResponse } from 'axios'
import axios from '../../apis/axios'
import { AgencyResponce, PostAgencyRequestParams, PutAgencyRequestParams } from '../../types/agency'

// 代理店詳細取得
export const callFindAgency = async (platformId: string, agencyId: string) => {
  const { data }: AxiosResponse<AgencyResponce> = await axios.get(
    `/agency/${platformId}/${agencyId}`
  )
  return data.agency
}

// 代理店情報更新
export const callPutAgencyInfo = async (
  platformId: string,
  agencyId: string,
  params: PutAgencyRequestParams
) => {
  await axios.put(`/agency/${platformId}/${agencyId}`, {
    params: params,
  })
}

// 代理店情報登録
export const callPostAgencyInfo = async (platformId: string, params: PostAgencyRequestParams) => {
  await axios.post(`/agency/${platformId}`, {
    params: params,
  })
}
