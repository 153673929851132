import React, { ChangeEvent, useCallback, useState, useEffect, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, Container, Box, TextField, Button, CircularProgress, Grid } from '@material-ui/core'

import { fetchPostConfirmForgotPassword } from 'store/login'
import { getErrorAsObject, confirmForgotPasswordSchema } from 'utils/validate'
import ApiError from 'components/ApiError'
import styles from './styles.module.scss'
import Title from '../../components/Title'
import { selectLogin } from '../../store/selector'
import { push } from 'connected-react-router'

export interface ConfirmForgotPasswordState {
  confirmation_code: string
  password: string
  confirmationPassword: string
}

const ConfirmForgotPassword = () => {
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const [errors, setErrors] = useState<{ [key in keyof ConfirmForgotPasswordState]?: string }>({})
  const [inputConfirmForgotPassword, setInputConfirmForgotPassword] = useState<
    ConfirmForgotPasswordState
  >({
    confirmation_code: '',
    password: '',
    confirmationPassword: '',
  })

  useEffect(() => {
    if (!loginSelector.email) {
      // パスワードリセット画面を経由していない場合はログインページにリダイレクト
      window.location.replace('/login')
    }
  }, [loginSelector.email])

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      setErrors({
        ...errors,
        [key]: '',
      })
      setInputConfirmForgotPassword({
        ...inputConfirmForgotPassword,
        [key]: event.target.value,
      })
    },
    [inputConfirmForgotPassword, errors]
  )

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    try {
      await confirmForgotPasswordSchema.validate(inputConfirmForgotPassword, { abortEarly: false })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      setErrors(getErrorAsObject(err))
      return
    }
    dispatch(
      fetchPostConfirmForgotPassword({
        email: loginSelector.email,
        confirmation_code: inputConfirmForgotPassword.confirmation_code,
        password: inputConfirmForgotPassword.password,
      })
    )
  }

  const handleBack = () => {
    dispatch(push('/forgot_password'))
  }

  return (
    <div data-page="confirm-forgot-password">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="パスワードリセット実行" isCenter={true} />
            <p className={styles.text}>
              パスワードをリセットし、新しいパスワードを設定します。
              <br />
              メールアドレスに送信された検証コードと新しいパスワードを入力してください。
            </p>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit}>
          <div data-section="api-error">
            {loginSelector.error && (
              <ApiError
                error={loginSelector.error.error}
                message={loginSelector.error.message}
                status={loginSelector.error.status}
              />
            )}
          </div>
          {loginSelector.isLoading ? <CircularProgress /> : null}
          <Container className={styles.forms} maxWidth="sm" component={Paper} variant="outlined">
            <TextField
              fullWidth
              label="検証コード"
              margin="normal"
              value={inputConfirmForgotPassword.confirmation_code}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'confirmation_code')
              }}
              error={Boolean(errors.confirmation_code)}
              helperText={errors.confirmation_code}
              data-section="confirmation_code"
            />
            <TextField
              fullWidth
              type="password"
              label="新しいパスワード"
              margin="normal"
              value={inputConfirmForgotPassword.password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'password')
              }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              data-section="password"
            />
            <TextField
              fullWidth
              type="password"
              label="新しいパスワード(確認)"
              margin="normal"
              value={inputConfirmForgotPassword.confirmationPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'confirmationPassword')
              }}
              error={Boolean(errors.confirmationPassword)}
              helperText={errors.confirmationPassword}
              data-section="confirmationPassword"
            />
            <div className={styles.buttonWrap}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={loginSelector.isLoading}
                data-section="backButton"
                onClick={handleBack}
              >
                戻る
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loginSelector.isLoading}
                data-section="submitButton"
              >
                新しいパスワードを設定する
              </Button>
            </div>
          </Container>
        </Box>
      </Container>
    </div>
  )
}

export default ConfirmForgotPassword
