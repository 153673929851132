import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  EnhancedStore,
  Action,
} from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { History } from 'history'
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ThunkAction } from 'redux-thunk'

import InsuranceModules, { InsuranceState } from './insurance'
import InsurancesModules, { InsurancesState } from './insurances'
import PolicyNumbersModules, { PolicyNumbersState } from './policyNumbers'
import PolicyNumberModules, { PolicyNumberState } from './policyNumber'
import EmailTextModules, { EmailTextState } from './emailText'
import EmailTextsModules, { EmailTextsState } from './emailTexts'
import PlatformsModules, { PlatformsState } from './platforms'
import PlatformModules, { PlatformState } from './platform'
import SalesOfficesModules, { SalesOfficesState } from './salesOffices'
import SalesOfficeModules, { SalesOfficeState } from './salesOffice'
import LoginModules, { LoginState } from './login'
import InsuranceAdminChangeHistoriesModules, {
  InsuranceAdminChangeHistoriesState,
} from './insuranceAdminChangeHistories'
import AdminModules, { AdminState } from './admin'
import AgencyModules, { AgencyState } from './agency'
import AgenciesModules, { AgenciesState } from './agencies'
import InsuranceOfficeModules, { InsuranceOfficeState } from './insuranceOffice'
import InsuranceOfficesModules, { InsuranceOfficesState } from './insuranceOffices'
import UserModules, { UserState } from './user'
import QuestionAnswerModules, { QuestionAnswerState } from './questionAnswer'
import QuestionAnswersModules, { QuestionAnswersState } from './questionAnswers'
import csvTemplatesModules, { CsvTemplatesState } from './csvTemplates'

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export interface RootState {
  insurance: InsuranceState
  insurances: InsurancesState
  policyNumber: PolicyNumberState
  policyNumbers: PolicyNumbersState
  emailText: EmailTextState
  emailTexts: EmailTextsState
  platform: PlatformState
  platforms: PlatformsState
  salesOffices: SalesOfficesState
  salesOffice: SalesOfficeState
  insuranceAdminChangeHistories: InsuranceAdminChangeHistoriesState
  login: LoginState
  admin: AdminState
  agency: AgencyState
  agencies: AgenciesState
  insuranceOffice: InsuranceOfficeState
  insuranceOffices: InsuranceOfficesState
  user: UserState
  questionAnswer: QuestionAnswerState
  questionAnswers: QuestionAnswersState
  csvTemplates: CsvTemplatesState
  router: RouterState
}

const persistConfig = {
  key: 'root',
  storage,
  // 永続化するstate
  whitelist: ['login'],
}

// setup 関数を用意してエクスポートする。
export const setupStore = (history: History): EnhancedStore<RootState> => {
  const middlewares = [
    ...getDefaultMiddleware({ serializableCheck: false }),
    routerMiddleware(history),
  ]

  if (['local', 'development', 'staging'].indexOf(process.env.REACT_APP_ENV) >= 0) {
    middlewares.push(logger)
  }

  return configureStore({
    reducer: persistReducer(
      persistConfig,
      combineReducers({
        insurance: InsuranceModules.reducer,
        insurances: InsurancesModules.reducer,
        policyNumber: PolicyNumberModules.reducer,
        policyNumbers: PolicyNumbersModules.reducer,
        emailText: EmailTextModules.reducer,
        emailTexts: EmailTextsModules.reducer,
        platform: PlatformModules.reducer,
        platforms: PlatformsModules.reducer,
        salesOffices: SalesOfficesModules.reducer,
        salesOffice: SalesOfficeModules.reducer,
        insuranceAdminChangeHistories: InsuranceAdminChangeHistoriesModules.reducer,
        login: LoginModules.reducer,
        admin: AdminModules.reducer,
        agency: AgencyModules.reducer,
        agencies: AgenciesModules.reducer,
        insuranceOffice: InsuranceOfficeModules.reducer,
        insuranceOffices: InsuranceOfficesModules.reducer,
        user: UserModules.reducer,
        questionAnswer: QuestionAnswerModules.reducer,
        questionAnswers: QuestionAnswersModules.reducer,
        csvTemplates: csvTemplatesModules.reducer,
        router: connectRouter(history),
      })
    ),
    middleware: middlewares,
  })
}
