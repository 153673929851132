import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { ErrorResponse } from 'store/error'
import { base64ToByteArray } from 'utils/utils'
import { PostDownloadCsvRequestParams, callPostInsuranceCsvDownload } from '../utils/apis/download'

export interface DownloadCsvState {
  isLoading: boolean
  isDownloadSuccess: boolean
  error?: ErrorResponse
}

export const DownloadCsvState: DownloadCsvState = {
  isLoading: false,
  isDownloadSuccess: false,
}

const DownloadCsvModules = createSlice({
  name: 'downloadCsv',
  initialState: DownloadCsvState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callPostDownloadSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetIsDownloadSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
})

// CSV情報のダウンロード処理
export const fetchPostCsvDownloadInfo = (
  file_name: string,
  params: PostDownloadCsvRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(DownloadCsvModules.actions.resetError())
    dispatch(DownloadCsvModules.actions.callApiStart())
    const data = await callPostInsuranceCsvDownload(params)
    dispatch(DownloadCsvModules.actions.resetIsDownloadSuccess())
    const { csv } = data
    const byteArray = base64ToByteArray(csv)

    const blob = new Blob([byteArray], { type: 'text/csv' })
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.setAttribute('download', file_name)
    a.click()
    window.URL.revokeObjectURL(url)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPostInsuranceCsvDownloadIndo error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      DownloadCsvModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default DownloadCsvModules
