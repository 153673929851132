import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE, PERMISSION_ERROR_MESSAGE } from '../const'
import { push } from 'connected-react-router'
import {
  callFindEmailText,
  callPutEmailTextInfo,
  callPostEmailTextInfo,
  callCheckEmailTextExist,
} from '../utils/apis/emailText'
import {
  EmailText,
  PutEmailTextRequestParams,
  PostEmailTextRequestParams,
} from '../types/emailText'

export interface EmailTextState {
  isLoading: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  emailTextCount: number
  emailText?: EmailText
  error?: ErrorResponse
}

export const EmailTextState: EmailTextState = {
  isLoading: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
  emailTextCount: 0,
}

const EmailTextModules = createSlice({
  name: 'emailText',
  initialState: EmailTextState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindEmailTextSuccess: (state, action: PayloadAction<EmailText>) => ({
      ...state,
      isLoading: false,
      emailText: action.payload,
    }),
    callCheckEmailTextExistSuccess: (state, action: PayloadAction<number>) => ({
      ...state,
      isLoading: false,
      emailTextCount: action.payload,
    }),
    callPutEmailTextInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callPostEmailTextInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isRegistSuccess: true,
    }),
    callDeleteEmailTextInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isDeleteSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetEmailText: (state) => {
      const newState = { ...state }
      delete newState.emailText
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsRegistSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetEmailTextCount: (state) => ({
      ...state,
      emailTextCount: 0,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return EmailTextState
    },
  },
})

// メール文書の取得処理
export const fetchGetEmailText = (emailTextId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(EmailTextModules.actions.resetError())
    dispatch(EmailTextModules.actions.callApiStart())
    const res = await callFindEmailText(emailTextId)
    dispatch(EmailTextModules.actions.callFindEmailTextSuccess(res))

    dispatch(EmailTextModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    if (message == PERMISSION_ERROR_MESSAGE) {
      dispatch(push('/email_texts'))
      dispatch(EmailTextModules.actions.resetError())
      dispatch(EmailTextModules.actions.resetIsLoading())
    } else {
      dispatch(
        EmailTextModules.actions.callApiError({
          status: status,
          error: error,
          message: message,
        })
      )
    }
  }
}

// メール文書の変更処理
export const fetchPutEmailTextInfo = (
  emailTextId: string,
  params: PutEmailTextRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(EmailTextModules.actions.resetError())
    dispatch(EmailTextModules.actions.callApiStart())
    await callPutEmailTextInfo(emailTextId, params)
    dispatch(EmailTextModules.actions.callPutEmailTextInfoSuccess())

    // 更新後にメール文書をセットする
    const res = await callFindEmailText(emailTextId)
    dispatch(EmailTextModules.actions.callFindEmailTextSuccess(res))
    dispatch(EmailTextModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutEmailText error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      EmailTextModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// メール文書の存在確認処理
export const fetchCheckEmailTextExist = (
  platformId: number,
  destination: number,
  type: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(EmailTextModules.actions.resetEmailTextCount())
    dispatch(EmailTextModules.actions.resetError())
    dispatch(EmailTextModules.actions.callApiStart())
    const res = await callCheckEmailTextExist(platformId, destination, type)
    dispatch(EmailTextModules.actions.callCheckEmailTextExistSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutEmailText error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      EmailTextModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// メール文書の登録処理
export const fetchPostEmailTextInfo = (params: PostEmailTextRequestParams): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(EmailTextModules.actions.resetIsChangeSuccess())
    dispatch(EmailTextModules.actions.resetError())
    dispatch(EmailTextModules.actions.callApiStart())
    await callPostEmailTextInfo(params)
    dispatch(EmailTextModules.actions.callPostEmailTextInfoSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutEmailText error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      EmailTextModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default EmailTextModules
