import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from 'store/error'
import {
  callDeleteCsvTemplate,
  callGetCsvTemplates,
  callPostCsvTemplate,
  callPutCsvTemplate,
  callResetCsvTemplates,
} from 'utils/apis/csvTemplate'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { CsvTemplate, GetContractsResponse } from '../types/csvTemplate'
import { AppThunk } from './index'

export interface CsvTemplatesState {
  isLoading: boolean
  templatesList: CsvTemplate[]
  selectorMap: Record<string, string>
  isResetSuccess: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  isDeleteSuccess: boolean
  error?: ErrorResponse
}

export const csvTemplatesState: CsvTemplatesState = {
  isLoading: false,
  templatesList: [],
  selectorMap: {},
  isResetSuccess: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
  isDeleteSuccess: false,
}

const CsvTemplatesModules = createSlice({
  name: 'csvTemplates',
  initialState: csvTemplatesState,
  reducers: {
    callFindCsvTemplatesStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callResetCsvTemplatesStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callRegistCsvTemplateStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callChangeCsvTemplateStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callDeleteCsvTemplateStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindCsvTemplatesSuccess: (state, action: PayloadAction<GetContractsResponse>) => ({
      ...state,
      isLoading: false,
      templatesList: action.payload.csv_templates,
      selectorMap: action.payload.selector_map,
    }),
    callResetCsvTemplatesSuccess: (state, action: PayloadAction<GetContractsResponse>) => ({
      ...state,
      isLoading: false,
      templatesList: action.payload.csv_templates,
      selectorMap: action.payload.selector_map,
    }),
    callRegistCsvTemplateSuccess: (state, action: PayloadAction<GetContractsResponse>) => ({
      ...state,
      isLoading: false,
      templatesList: action.payload.csv_templates,
      selectorMap: action.payload.selector_map,
    }),
    callChangeCsvTemplateSuccess: (state, action: PayloadAction<GetContractsResponse>) => ({
      ...state,
      isLoading: false,
      templatesList: action.payload.csv_templates,
      selectorMap: action.payload.selector_map,
    }),
    callDeleteCsvTemplateSuccess: (state, action: PayloadAction<GetContractsResponse>) => ({
      ...state,
      isLoading: false,
      templatesList: action.payload.csv_templates,
      selectorMap: action.payload.selector_map,
    }),
    callFindCsvTemplatesError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    callResetCsvTemplatesError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    callRegistCsvTemplateError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    callChangeCsvTemplateError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    callDeleteCsvTemplateError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetCsvTemplatesList: (state) => ({
      ...state,
      templatesList: [],
      selectorMap: {},
    }),
  },
})

// csvテンプレート取得処理
export const fetchFindCsvTemplates = (): AppThunk => async (dispatch) => {
  try {
    dispatch(CsvTemplatesModules.actions.resetError())
    dispatch(CsvTemplatesModules.actions.callFindCsvTemplatesStart())
    const res = await callGetCsvTemplates()
    console.log('callGetCsvTemplate')
    dispatch(CsvTemplatesModules.actions.callFindCsvTemplatesSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindCsvTemplates error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      CsvTemplatesModules.actions.callFindCsvTemplatesError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// csvテンプレート初期化処理
export const fetchResetTemplates = (): AppThunk => async (dispatch) => {
  try {
    dispatch(CsvTemplatesModules.actions.resetError())
    dispatch(CsvTemplatesModules.actions.callResetCsvTemplatesStart())
    const res = await callResetCsvTemplates()
    dispatch(CsvTemplatesModules.actions.callResetCsvTemplatesSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('callResetCsvTemplate error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      CsvTemplatesModules.actions.callResetCsvTemplatesError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// csvテンプレート追加処理
export const fetchRegistTemplate = (params: Omit<CsvTemplate, 'id'>): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(CsvTemplatesModules.actions.resetError())
    dispatch(CsvTemplatesModules.actions.callRegistCsvTemplateStart())
    const res = await callPostCsvTemplate(params)
    dispatch(CsvTemplatesModules.actions.callRegistCsvTemplateSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('callPostCsvTemplate error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      CsvTemplatesModules.actions.callRegistCsvTemplateError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// csvテンプレート変更処理
export const fetchChangeTemplate = (
  code: string,
  params: Omit<CsvTemplate, 'id'>
): AppThunk => async (dispatch) => {
  try {
    dispatch(CsvTemplatesModules.actions.resetError())
    dispatch(CsvTemplatesModules.actions.callChangeCsvTemplateStart())
    const res = await callPutCsvTemplate(code, params)
    dispatch(CsvTemplatesModules.actions.callChangeCsvTemplateSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('callPutCsvTemplate error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      CsvTemplatesModules.actions.callChangeCsvTemplateError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// csvテンプレート削除処理
export const fetchDeleteTemplate = (code: string): AppThunk => async (dispatch) => {
  try {
    dispatch(CsvTemplatesModules.actions.resetError())
    dispatch(CsvTemplatesModules.actions.callDeleteCsvTemplateStart())
    const res = await callDeleteCsvTemplate(code)
    dispatch(CsvTemplatesModules.actions.callDeleteCsvTemplateSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('callDeleteCsvTemplate error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      CsvTemplatesModules.actions.callDeleteCsvTemplateError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default CsvTemplatesModules
