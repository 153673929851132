import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { SalesOffice } from '../types/salesOffice'
import {
  callFindSalesOffice,
  callPutSalesOfficeInfo,
  callPostSalesOfficeInfo,
} from '../utils/apis/salesOffice'
import { PutSalesOfficeRequestParams, PostSalesOfficeRequestParams } from '../types/salesOffice'

export interface SalesOfficeState {
  isLoading: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  salesOffice?: SalesOffice
  error?: ErrorResponse
}

export const SalesOfficeState: SalesOfficeState = {
  isLoading: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
}

const SalesOfficeModules = createSlice({
  name: 'salesOffice',
  initialState: SalesOfficeState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindSalesOfficeSuccess: (state, action: PayloadAction<SalesOffice>) => ({
      ...state,
      isLoading: false,
      salesOffice: action.payload,
    }),
    callPutSalesOfficeInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callPostSalesOfficeInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isRegistSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetSalesOffice: (state) => {
      const newState = { ...state }
      delete newState.salesOffice
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsRegistSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return SalesOfficeState
    },
  },
})

//営業店情報の取得処理
export const fetchGetSalesOffice = (platformId: string, salesOfficeId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(SalesOfficeModules.actions.resetError())
    dispatch(SalesOfficeModules.actions.callApiStart())
    const res = await callFindSalesOffice(platformId, salesOfficeId)
    dispatch(SalesOfficeModules.actions.callFindSalesOfficeSuccess(res))

    dispatch(SalesOfficeModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      SalesOfficeModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 営業店情報の変更処理
export const fetchPutSalesOfficeInfo = (
  platformId: string,
  salesOfficeId: string,
  params: PutSalesOfficeRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(SalesOfficeModules.actions.resetError())
    dispatch(SalesOfficeModules.actions.callApiStart())
    await callPutSalesOfficeInfo(platformId, salesOfficeId, params)
    dispatch(SalesOfficeModules.actions.callPutSalesOfficeInfoSuccess())

    // 更新後に営業店データをセットする
    const res = await callFindSalesOffice(platformId, salesOfficeId)
    dispatch(SalesOfficeModules.actions.callFindSalesOfficeSuccess(res))
    dispatch(SalesOfficeModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutSalesOffice error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      SalesOfficeModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 営業店情報の登録処理
export const fetchPostSalesOfficeInfo = (
  platformId: string,
  params: PostSalesOfficeRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(SalesOfficeModules.actions.resetIsChangeSuccess())
    dispatch(SalesOfficeModules.actions.resetError())
    dispatch(SalesOfficeModules.actions.callApiStart())
    await callPostSalesOfficeInfo(platformId, params)
    dispatch(SalesOfficeModules.actions.callPostSalesOfficeInfoSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutSalesOffice error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      SalesOfficeModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default SalesOfficeModules
