import React, { useEffect, useLayoutEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { fetchGetInsuranceOffices } from 'store/insuranceOffices'
import ApiError from '../../components/ApiError'
import styles from './styles.module.scss'
import { selectInsuranceOffices, selectLogin } from '../../store/selector'
import Title from '../../components/Title'
import { CognitoGroupNameEnum } from '../../types/login'

export interface LocationState {
  platformName: string
}

const insuranceOffices = () => {
  const { platformId } = useParams<{ platformId: string }>()
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const insuranceOfficesSelector = useSelector(selectInsuranceOffices)

  const history = useHistory()
  const location = useLocation()

  useLayoutEffect(() => {
    if (loginSelector.cognitoGroupName !== CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME) {
      dispatch(push({ pathname: '/platforms' }))
    }
  }, [location.pathname])

  const { platformName } = location.state ? (location.state as LocationState) : { platformName: '' }
  const moveNewPage = (platformId: string) => {
    history.push({
      pathname: `/insurance_office/${platformId}/new`,
      state: { platformName: platformName },
    })
  }

  const moveEditPage = (platformId: string, insuranceOfficeId: string) => {
    history.push({
      pathname: `/insurance_office/${platformId}/${insuranceOfficeId}/edit`,
      state: { platformName: platformName },
    })
  }

  const movePlatformsPage = () => {
    dispatch(push('/platforms'))
  }

  useEffect(() => {
    dispatch(fetchGetInsuranceOffices(platformId))
  }, [dispatch])

  return (
    <div data-page="policy-numbers">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="保険サービス課一覧" isCenter={true} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.info}>
          <p data-section="platform_name">
            <span>プラットフォーム：</span>
            <strong>{platformName}</strong>
          </p>
          <Box className={styles.newCreateBtn}>
            <Button onClick={() => movePlatformsPage()} variant="contained" data-section="back">
              プラットフォーム一覧へ
            </Button>
            <Button
              onClick={() => moveNewPage(platformId)}
              color="primary"
              variant="contained"
              data-section="new"
              disabled={
                loginSelector.cognitoGroupName === CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                  ? false
                  : true
              }
            >
              新規作成
            </Button>
          </Box>
        </Grid>
        <Box>
          {insuranceOfficesSelector.error ? (
            <div data-section="get-api-error">
              <ApiError
                error={insuranceOfficesSelector.error.error}
                message={insuranceOfficesSelector.error.message}
                status={insuranceOfficesSelector.error.status}
              />
            </div>
          ) : insuranceOfficesSelector.isLoading ? (
            <CircularProgress />
          ) : (
            <Box mt={3}>
              <Container maxWidth="lg">
                <TableContainer component={Paper} className={styles.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>保険サービス課名</TableCell>
                        <TableCell>保険サービス課名カナ</TableCell>
                        <TableCell>契約用メールアドレス</TableCell>
                        <TableCell>事故連絡用メールアドレス</TableCell>
                        <TableCell>操作</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {insuranceOfficesSelector.insuranceOffices.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={9}>
                            保険サービス課データがありません
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {insuranceOfficesSelector.insuranceOffices.map((data) => {
                            return (
                              <TableRow key={data.id} data-section={`row${data.id}`}>
                                <TableCell data-section="id">{data.id}</TableCell>
                                <TableCell data-section="insurance_office_name">
                                  {data.insurance_office_name}
                                </TableCell>
                                <TableCell data-section="insurance_office_name_kana">
                                  {data.insurance_office_name_kana}
                                </TableCell>
                                <TableCell data-section="contract_email">
                                  {data.contract_email}
                                </TableCell>
                                <TableCell data-section="accident_email">
                                  {data.accident_email}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => moveEditPage(platformId, String(data.id))}
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                    disabled={
                                      loginSelector.cognitoGroupName ===
                                      CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                                        ? false
                                        : true
                                    }
                                  >
                                    編集
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  )
}

export default insuranceOffices
