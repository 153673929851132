import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ApiError from '../../components/ApiError'
import styles from './styles.module.scss'
import AgencyModules, {
  fetchGetAgency,
  fetchPutAgencyInfo,
  fetchPostAgencyInfo,
} from '../../store/agency'
import { fetchGetPlatform } from '../../store/platform'
import Title from '../../components/Title'
import { selectAgency } from '../../store/selector'
import { PutAgencyRequestParams, PostAgencyRequestParams } from '../../types/agency'
import {
  changeAgencyInfoSchema,
  registerAgencyInfoSchema,
  getErrorAsObject,
} from '../../utils/validate'
import { DATETIME_FORMAT } from 'const'
import { format } from 'date-fns'

export interface LocationState {
  platformName: string
}

const Agency = () => {
  const { platformId, agencyId } = useParams<{ platformId: string; agencyId: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { platformName } = location.state ? (location.state as LocationState) : { platformName: '' }
  // 新規作成フラグ
  const newFlag = location.pathname.match('/new') ? 1 : 0

  // 代理店名
  const [agencyName, setAgencyName] = useState('')
  // 代理店名カナ
  const [agencyNameKana, setAgencyNameKana] = useState('')
  // 代理店正式名称
  const [agencyOfficialName, setAgencyOfficialName] = useState('')
  // 契約用メールアドレス
  const [contractEmail, setContractEmail] = useState('')
  // 契約用メールアドレス有効フラグ
  const [isNotificateContract, setIsNotificateContract] = useState(newFlag ? '1' : '')
  // 事故連絡用メールアドレス
  const [accidentEmail, setAccidentEmail] = useState('')
  // 事故連絡用メールアドレス有効フラグ
  const [isNotificateAccident, setIsNotificateAccident] = useState(newFlag ? '1' : '')

  const agencySelector = useSelector(selectAgency)

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PostAgencyRequestParams]?: string
    }
  >({})

  const isNotificateContractDefaultValue = newFlag
    ? '1'
    : String(agencySelector?.agency?.is_notificate_contract)
  const isNotificateAccidentDefaultValue = newFlag
    ? '1'
    : String(agencySelector?.agency?.is_notificate_accident)

  useEffect(() => {
    if (!newFlag && platformId && agencyId) {
      dispatch(fetchGetAgency(platformId, agencyId))
    }
    if (newFlag && platformId) {
      dispatch(fetchGetPlatform(platformId))
    }
  }, [dispatch])

  // 代理店変更成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (agencySelector.isChangeSuccess) {
      setTimeout(() => {
        dispatch(AgencyModules.actions.resetIsChangeSuccess())
      }, 5000)
    }
  }, [agencySelector.isChangeSuccess])

  // 代理店登録成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (agencySelector.isRegistSuccess) {
      setTimeout(() => {
        dispatch(AgencyModules.actions.resetIsRegistSuccess())
      }, 5000)
    }
  }, [agencySelector.isRegistSuccess])

  // 取得した後にパラメータを設定
  useEffect(() => {
    if (!newFlag && agencySelector) {
      setAgencyName(agencySelector?.agency?.agency_name || '')
      setAgencyNameKana(agencySelector?.agency?.agency_name_kana || '')
      setAgencyOfficialName(agencySelector?.agency?.agency_official_name || '')
      setContractEmail(agencySelector?.agency?.contract_email || '')
      setIsNotificateContract(String(agencySelector?.agency?.is_notificate_contract) || '')
      setAccidentEmail(agencySelector?.agency?.accident_email || '')
      setIsNotificateAccident(String(agencySelector?.agency?.is_notificate_accident) || '')
    }
  }, [newFlag, agencySelector.agency])

  const moveListPage = (platformId: string) => {
    // 画面遷移時にはstoreの代理店をリセット
    dispatch(AgencyModules.actions.resetAgency())
    history.push({
      pathname: `/agencies/${platformId}`,
      state: { platformName: platformName },
    })
  }

  const getPostParams = (): PostAgencyRequestParams => {
    return {
      agency_name: agencyName,
      agency_name_kana: agencyNameKana,
      agency_official_name: agencyOfficialName,
      contract_email: contractEmail,
      is_notificate_contract: Number(isNotificateContract),
      accident_email: accidentEmail,
      is_notificate_accident: Number(isNotificateAccident),
    }
  }

  const getPutParams = (): PutAgencyRequestParams | boolean => {
    if (!agencySelector.agency) {
      return false
    }
    return {
      agency_name: agencyName,
      agency_name_kana: agencyNameKana,
      contract_email: contractEmail,
      agency_official_name: agencyOfficialName,
      is_notificate_contract: Number(isNotificateContract),
      accident_email: accidentEmail,
      is_notificate_accident: Number(isNotificateAccident),
    }
  }

  // 登録ボタン
  const handlePostSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPostCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPostParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPostAgencyInfo(platformId, params))
    }
  }

  //更新ボタン
  const handlePutSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPutCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPutParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPutAgencyInfo(platformId, agencyId, params))
    }
  }

  // 登録ボタン実行時のバリデーション
  const validationPostCheck = async (): Promise<boolean> => {
    const params = getPostParams()
    console.log(params)
    try {
      await registerAgencyInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  // 更新ボタン実行時のバリデーション
  const validationPutCheck = async (): Promise<boolean> => {
    const params = getPutParams()
    try {
      await changeAgencyInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  return (
    <div data-page="agency-input">
      <Container className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            {newFlag ? (
              <Title title="代理店登録" isCenter={true} />
            ) : (
              <Title title="代理店編集" isCenter={true} />
            )}
          </Grid>
        </Grid>
        <Box component="form" className={styles.formBox}>
          {agencySelector.error ? (
            <div data-section="api-error">
              <ApiError
                error={agencySelector.error.error}
                message={agencySelector.error.message}
                status={agencySelector.error.status}
              />
            </div>
          ) : (
            <>
              {agencySelector.isLoading && <CircularProgress />}
              {!newFlag && !agencySelector.agency ? (
                <p data-section="no-items">代理店情報が見つかりません</p>
              ) : (
                <Grid container>
                  {agencySelector.isChangeSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>変更が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {agencySelector.isRegistSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>登録が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} className={styles.info}>
                    <p data-section="platform_name">
                      <span>プラットフォーム：</span>
                      <strong>{platformName}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <form>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="代理店名"
                          type="text"
                          data-section="agency_name"
                          variant="outlined"
                          value={agencyName}
                          onChange={(e) => setAgencyName(e.target.value)}
                          error={!!errors.agency_name}
                          helperText={errors.agency_name}
                          fullWidth
                        />
                        {!newFlag && agencyName !== agencySelector?.agency?.agency_name && (
                          <small data-section="prev_agency_name">
                            変更前代理店名：{agencySelector?.agency?.agency_name}
                          </small>
                        )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="代理店名カナ"
                          type="text"
                          data-section="agency_name_kana"
                          variant="outlined"
                          value={agencyNameKana}
                          onChange={(e) => setAgencyNameKana(e.target.value)}
                          error={!!errors.agency_name_kana}
                          helperText={errors.agency_name_kana}
                          fullWidth
                        />
                        {!newFlag && agencyNameKana !== agencySelector?.agency?.agency_name_kana && (
                          <small data-section="prev_agency_name_kana">
                            変更前代理店名カナ：
                            {agencySelector?.agency?.agency_name_kana}
                          </small>
                        )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="代理店正式名称"
                          type="text"
                          data-section="agency_official_name"
                          variant="outlined"
                          value={agencyOfficialName}
                          onChange={(e) => setAgencyOfficialName(e.target.value)}
                          error={!!errors.agency_official_name}
                          helperText={errors.agency_official_name}
                          fullWidth
                        />
                        {!newFlag &&
                          agencyName !== agencySelector?.agency?.agency_official_name && (
                            <small data-section="prev_agency_name">
                              変更前代理店正式名称：{agencySelector?.agency?.agency_official_name}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="契約用メールアドレス"
                          type="text"
                          data-section="contract_email"
                          variant="outlined"
                          value={contractEmail}
                          onChange={(e) => setContractEmail(e.target.value)}
                          error={!!errors.contract_email}
                          helperText={errors.contract_email}
                          fullWidth
                        />
                        {!newFlag && contractEmail !== agencySelector?.agency?.contract_email && (
                          <small data-section="prev_contract_email">
                            変更前契約用メールアドレス：
                            {agencySelector?.agency?.contract_email}
                          </small>
                        )}
                      </Box>
                      <Box className={styles.radioWrap}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">契約用メールアドレス有効フラグ</FormLabel>
                          <RadioGroup
                            row={true}
                            aria-label="gender"
                            name="controlled-radio-buttons-group"
                            defaultValue={isNotificateContractDefaultValue}
                            onChange={(e) => setIsNotificateContract(e.target.value)}
                            style={{ margin: '0 0 -10px 0' }}
                          >
                            <FormControlLabel value="1" control={<Radio />} label="有効" />
                            <FormControlLabel value="0" control={<Radio />} label="無効" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {!newFlag &&
                        isNotificateContract !==
                          String(agencySelector?.agency?.is_notificate_contract) && (
                          <small data-section="prev_contract_email" className={styles.beforChange}>
                            変更前契約用メールアドレス有効フラグ：
                            {agencySelector?.agency?.is_notificate_contract == 1 ? '有効' : '無効'}
                          </small>
                        )}
                      <Box className={styles.inputWrap}>
                        <TextField
                          label="事故連絡用メールアドレス"
                          type="text"
                          data-section="accident_email"
                          variant="outlined"
                          value={accidentEmail}
                          onChange={(e) => setAccidentEmail(e.target.value)}
                          error={!!errors.accident_email}
                          helperText={errors.accident_email}
                          fullWidth
                        />
                        {!newFlag && accidentEmail !== agencySelector?.agency?.accident_email && (
                          <small data-section="prev_accident_email">
                            変更前契約用メールアドレス：
                            {agencySelector?.agency?.accident_email}
                          </small>
                        )}
                      </Box>
                      <Box className={styles.radioWrap}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            事故連絡用メールアドレス有効フラグ
                          </FormLabel>
                          <RadioGroup
                            row={true}
                            aria-label="gender"
                            name="controlled-radio-buttons-group"
                            defaultValue={isNotificateAccidentDefaultValue}
                            onChange={(e) => setIsNotificateAccident(e.target.value)}
                            style={{ margin: '0 0 -10px 0' }}
                          >
                            <FormControlLabel value="1" control={<Radio />} label="有効" />
                            <FormControlLabel value="0" control={<Radio />} label="無効" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {!newFlag &&
                        isNotificateAccident !==
                          String(agencySelector?.agency?.is_notificate_accident) && (
                          <small data-section="prev_contract_email" className={styles.beforChange}>
                            変更前事故連絡用メールアドレス有効フラグ：
                            {agencySelector?.agency?.is_notificate_accident == 1 ? '有効' : '無効'}
                          </small>
                        )}
                      {!newFlag && (
                        <Grid container className={styles.timeInfoContainer}>
                          <Grid item xs={12}>
                            <p data-section="created_at">
                              <span>作成日時：</span>
                              {format(
                                new Date(agencySelector?.agency?.created_at || ''),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p data-section="updated_at">
                              <span>更新日時：</span>
                              {format(
                                new Date(agencySelector?.agency?.updated_at || ''),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </form>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button
                onClick={() => moveListPage(platformId)}
                variant="contained"
                data-section="back"
              >
                戻る
              </Button>
              {!agencySelector.error && (
                <>
                  {newFlag ? (
                    <Button
                      onClick={() => {
                        handlePostSubmit()
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="post"
                      data-section="register"
                    >
                      登録する
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handlePutSubmit()
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="post"
                      data-section="register"
                    >
                      更新する
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Agency
