import { AxiosResponse } from 'axios'

import axios from '../../apis/axios'
import { CognitoGroupNameEnum } from '../../types/login'
import { CognitoUserInterface } from '../../types/admin'

export interface PostAdminCreateUserRequestParams {
  email: string
  phone_number: string
  group_name: CognitoGroupNameEnum
  agencies_id: string
}

// ユーザー作成
export const callPostAdminCreateUser = async (params: PostAdminCreateUserRequestParams) => {
  params.agencies_id = String(params.agencies_id)
  await axios.post(
    '/admin/create_user',
    JSON.stringify({
      params: params,
    })
  )
}

export interface GetAdminGetUsersResponse {
  users: CognitoUserInterface[]
}

// ユーザー一覧取得
export const callGetAdminGetUsers = async () => {
  const { data }: AxiosResponse<GetAdminGetUsersResponse> = await axios.get('/admin/get_users')
  return data
}

export interface PostAdminDeleteUserRequestParams {
  email: string
}

// ユーザー削除
export const callPostAdminDeleteUser = async (params: PostAdminDeleteUserRequestParams) => {
  await axios.post(
    '/admin/delete_user',
    JSON.stringify({
      params: params,
    })
  )
}
