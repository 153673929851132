import { AxiosResponse } from 'axios'

import axios from '../../apis/axios'
import { GetEmailTextsResponse } from 'store/emailTexts'

/*
 * メール文書一覧取得
 */
export const callGetEmailTexts = async () => {
  const { data }: AxiosResponse<GetEmailTextsResponse> = await axios.get('/email_texts')
  return data
}
