import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE, PERMISSION_ERROR_MESSAGE } from '../const'
import { push } from 'connected-react-router'
import { Agency } from '../types/agency'
import { callFindAgency, callPutAgencyInfo, callPostAgencyInfo } from '../utils/apis/agency'
import { PutAgencyRequestParams, PostAgencyRequestParams } from '../types/agency'

export interface AgencyState {
  isLoading: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  agency?: Agency
  error?: ErrorResponse
}

export const AgencyState: AgencyState = {
  isLoading: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
}

const AgencyModules = createSlice({
  name: 'agency',
  initialState: AgencyState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindAgencySuccess: (state, action: PayloadAction<Agency>) => ({
      ...state,
      isLoading: false,
      agency: action.payload,
    }),
    callPutAgencyInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callPostAgencyInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isRegistSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetAgency: (state) => {
      const newState = { ...state }
      delete newState.agency
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsRegistSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return AgencyState
    },
  },
})

//営業店情報の取得処理
export const fetchGetAgency = (platformId: string, agencyId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(AgencyModules.actions.resetError())
    dispatch(AgencyModules.actions.callApiStart())
    const res = await callFindAgency(platformId, agencyId)
    dispatch(AgencyModules.actions.callFindAgencySuccess(res))

    dispatch(AgencyModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    if (message == PERMISSION_ERROR_MESSAGE) {
      dispatch(push('/platforms'))
      dispatch(AgencyModules.actions.resetError())
      dispatch(AgencyModules.actions.resetIsLoading())
    } else {
      dispatch(
        AgencyModules.actions.callApiError({
          status: status,
          error: error,
          message: message,
        })
      )
    }
  }
}

// 営業店情報の変更処理
export const fetchPutAgencyInfo = (
  platformId: string,
  agencyId: string,
  params: PutAgencyRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(AgencyModules.actions.resetError())
    dispatch(AgencyModules.actions.callApiStart())
    await callPutAgencyInfo(platformId, agencyId, params)
    dispatch(AgencyModules.actions.callPutAgencyInfoSuccess())

    // 更新後に営業店データをセットする
    const res = await callFindAgency(platformId, agencyId)
    dispatch(AgencyModules.actions.callFindAgencySuccess(res))
    dispatch(AgencyModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutAgency error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      AgencyModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 営業店情報の登録処理
export const fetchPostAgencyInfo = (
  platformId: string,
  params: PostAgencyRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(AgencyModules.actions.resetIsChangeSuccess())
    dispatch(AgencyModules.actions.resetError())
    dispatch(AgencyModules.actions.callApiStart())
    await callPostAgencyInfo(platformId, params)
    dispatch(AgencyModules.actions.callPostAgencyInfoSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutAgency error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      AgencyModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default AgencyModules
