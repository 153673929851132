import React, { useState, useEffect, ChangeEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { push } from 'connected-react-router'

import InsuranceModules, {
  fetchFindInsurance,
  fetchPutInsuranceInfo,
} from '../../../store/insurance'

import { fetchGetPolicyNumbers } from 'store/policyNumbers'
import { selectInsurance, selectPolicyNumbers } from '../../../store/selector'

import styles from './styles.module.scss'
import Title from '../../../components/Title'
import ApiError from '../../../components/ApiError'
import { changeInsuranceInfoSchema, getErrorAsObject } from '../../../utils/validate'
import { PutInsuranceRequestParams } from '../../../utils/apis/insurance'
import { INSURANCE_IS_SALES_NAME } from '../../../const'
import { checkExpirationTime, checkOnTheDay } from '../../../utils/utils'
import { InsuranceIsSalesEnum } from '../../../types/insurance'

const Change = () => {
  const dispatch = useDispatch()
  const insuranceSelector = useSelector(selectInsurance)
  const PolicyNumbersSelector = useSelector(selectPolicyNumbers)
  const { insuranceId } = useParams<{ insuranceId: string }>()

  // 売上高
  const [salesAmount, setSalesAmount] = useState(0)
  // 業種
  const [industry, setIndustry] = useState('')
  // 法人名/屋号(漢字)
  const [companyName, setcompanyName] = useState('')
  // 氏名(漢字)
  const [contractorName, setContractorName] = useState('')
  // 郵便番号
  const [zip, setZip] = useState('')
  // 住所
  const [address, setAddress] = useState('')
  // ご担当者名
  const [managerName, setManagerName] = useState('')
  // 電話番号
  const [phone, setPhone] = useState('')
  // メールアドレス
  const [email, setEmail] = useState('')
  // 他社保険会社名
  const [otherInsuranceCompany, setOtherInsuranceCompany] = useState('')
  // 他社保険種類
  const [otherInsuranceType, setOtherInsuranceType] = useState('')
  // 他社満期日
  const [otherInsurancePeriod, setOtherInsurancePeriod] = useState('')
  // 証券番号ID
  const [policyNumberId, setPolicyNumberId] = useState(0)
  // 決済状況
  const [isSalesValue, setIsSalesValue] = useState<InsuranceIsSalesEnum>(
    InsuranceIsSalesEnum.TEMPORARY_SALES
  )

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PutInsuranceRequestParams]?: string
    }
  >({})

  const moveBackPage = () => {
    dispatch(push(`/insurance/${insuranceId}`))
  }

  const getParams = (): PutInsuranceRequestParams | boolean => {
    if (!insuranceSelector.insurance) {
      return false
    }
    return {
      sales_amount: salesAmount,
      industry: industry,
      company_name: companyName,
      contractor_name: contractorName,
      zip: zip,
      address: address,
      manager_name: managerName,
      phone: phone,
      email: email,
      other_insurance_company: otherInsuranceCompany,
      other_insurance_type: otherInsuranceType,
      other_insurance_period: otherInsurancePeriod,
      policy_number_id: policyNumberId,
      is_sales: isSalesValue,
    }
  }

  const validationCheck = async (): Promise<boolean> => {
    const params = getParams()
    try {
      await changeInsuranceInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  const handleSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPutInsuranceInfo(insuranceId, params))
    }
  }

  useEffect(() => {
    dispatch(fetchFindInsurance(insuranceId))
    dispatch(fetchGetPolicyNumbers())
  }, [dispatch])

  // 保険情報変更成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (insuranceSelector.isChangeSuccess) {
      setTimeout(() => {
        dispatch(InsuranceModules.actions.resetIsChangeSuccess())
      }, 5000)
    }
  }, [insuranceSelector.isChangeSuccess])

  // 契約者情報が取得できたらinputの初期値に設定
  useEffect(() => {
    if (insuranceSelector.insurance) {
      setSalesAmount(insuranceSelector.insurance.sales_amount || 0)
      setIndustry(insuranceSelector.insurance.industry || '')
      setContractorName(insuranceSelector.insurance.contractor_name || '')
      setcompanyName(insuranceSelector.insurance.company_name || '')
      setZip(insuranceSelector.insurance.zip || '')
      setAddress(insuranceSelector.insurance.address || '')
      setManagerName(insuranceSelector.insurance.manager_name || '')
      setEmail(insuranceSelector.insurance.email || '')
      setPhone(insuranceSelector.insurance.phone || '')
      setOtherInsuranceCompany(insuranceSelector.insurance.other_insurance_company || '')
      setOtherInsuranceType(insuranceSelector.insurance.other_insurance_type || '')
      setOtherInsurancePeriod(insuranceSelector.insurance.other_insurance_period || '')
      setPolicyNumberId(insuranceSelector.insurance.policy_number.id || 1)
      setIsSalesValue(insuranceSelector.insurance.is_sales)
    }
  }, [insuranceSelector.insurance])

  return (
    <div data-page="change">
      <Container maxWidth="md" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="保険契約情報変更" />
          </Grid>
        </Grid>
        {insuranceSelector.error ? (
          <div data-section="api-error">
            <ApiError
              error={insuranceSelector.error.error}
              message={insuranceSelector.error.message}
              status={insuranceSelector.error.status}
            />
          </div>
        ) : insuranceSelector.isLoading ? (
          <CircularProgress />
        ) : !insuranceSelector.insurance ? (
          <p data-section="no-items">保険商品情報が見つかりません。</p>
        ) : (
          <Grid container>
            {insuranceSelector.isChangeSuccess && (
              <Grid container>
                <Grid item xs={12} className={styles.success}>
                  <p data-section="complete">
                    <CheckCircleIcon />
                    <span>変更が完了しました。</span>
                  </p>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={styles.info}>
              <p data-section="insurance-status">
                <strong>状態：</strong>
                {insuranceSelector.insurance.deleted_at
                  ? '削除されました'
                  : (insuranceSelector.insurance.cancelled_at &&
                      checkExpirationTime(insuranceSelector.insurance.cancelled_at)) ||
                    (insuranceSelector.insurance.cancelled_at &&
                      checkOnTheDay(
                        insuranceSelector.insurance.application_date,
                        insuranceSelector.insurance.cancelled_at
                      ))
                  ? '解約されました'
                  : checkExpirationTime(insuranceSelector.insurance.contract_period_end)
                  ? '契約満了'
                  : insuranceSelector.insurance.cancelled_at
                  ? '解約申込がされています'
                  : '契約中'}
              </p>
              <p data-section="insurance-member-id">
                <strong>会員番号：</strong>
                {insuranceSelector.insurance.member_id}
              </p>
              <p data-section="insurance-contractor_id">
                <strong>加入者番号:</strong>
                {insuranceSelector.insurance.contractor_id}
              </p>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      salesAmount !== insuranceSelector.insurance.sales_amount
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="売上高(百万円)"
                    value={salesAmount}
                    onChange={(e) => {
                      if (e.target.value.match(/[^\d]/g)) {
                        setSalesAmount(0)
                      } else if (e.target.value == '') {
                        setSalesAmount(0)
                      } else {
                        const value = e.target.value.replace(/[^\d]/g, '0')
                        setSalesAmount(parseInt(value))
                      }
                    }}
                    error={!!errors.sales_amount}
                    helperText={errors.sales_amount}
                    data-section="sales-amount"
                  />
                  {salesAmount !== insuranceSelector.insurance.sales_amount && (
                    <small data-section="prev-sales-amount">
                      変更前売上高(百万円)：{insuranceSelector.insurance.sales_amount}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      industry !== insuranceSelector.insurance.industry ? styles.isChange : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="業種"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    error={!!errors.industry}
                    helperText={errors.industry}
                    data-section="industry"
                  />
                  {industry !== insuranceSelector.insurance.industry && (
                    <small data-section="prev-industry">
                      変更前業種：{insuranceSelector.insurance.industry}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      contractorName !== insuranceSelector.insurance.contractor_name
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="氏名(漢字)"
                    value={contractorName}
                    onChange={(e) => setContractorName(e.target.value)}
                    error={!!errors.contractor_name}
                    helperText={errors.contractor_name}
                    data-section="contractor-name"
                  />
                  {contractorName !== insuranceSelector.insurance.contractor_name && (
                    <small data-section="prev-contractor-name">
                      変更前氏名(漢字)：{insuranceSelector.insurance.contractor_name}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      companyName !== insuranceSelector.insurance.company_name
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="法人名/屋号"
                    placeholder=""
                    value={companyName}
                    onChange={(e) => setcompanyName(e.target.value)}
                    error={!!errors.company_name}
                    helperText={errors.company_name}
                    data-section="company-name"
                  />
                  {companyName !== insuranceSelector.insurance.company_name && (
                    <small data-section="prev-company-name">
                      変更前法人名/屋号(漢字)：{insuranceSelector.insurance.company_name}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={zip !== insuranceSelector.insurance.zip ? styles.isChange : ''}
                    fullWidth
                    variant="outlined"
                    label="郵便番号"
                    value={zip}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d]/g, '').slice(0, 7)
                      setZip(value)
                    }}
                    error={!!errors.zip}
                    helperText={errors.zip}
                    data-section="zip"
                  />
                  {zip !== insuranceSelector.insurance.zip && (
                    <small data-section="prev-zip">
                      変更前郵便番号：{insuranceSelector.insurance.zip}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      address !== insuranceSelector.insurance.address ? styles.isChange : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="住所"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={!!errors.address}
                    helperText={errors.address}
                    data-section="address"
                  />
                  {address !== insuranceSelector.insurance.address && (
                    <small data-section="prev-address">
                      変更前住所：{insuranceSelector.insurance.address}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      managerName !== insuranceSelector.insurance.manager_name
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="ご担当者名"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                    error={!!errors.manager_name}
                    helperText={errors.manager_name}
                    data-section="manager-name"
                  />
                  {managerName !== insuranceSelector.insurance.manager_name && (
                    <small data-section="prev-manager-name">
                      変更前ご担当者名：{insuranceSelector.insurance.manager_name}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={email !== insuranceSelector.insurance.email ? styles.isChange : ''}
                    fullWidth
                    variant="outlined"
                    label="メールアドレス"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    data-section="email"
                  />
                  {email !== insuranceSelector.insurance.email && (
                    <small data-section="prev-email">
                      変更前メールアドレス：{insuranceSelector.insurance.email}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={phone !== insuranceSelector.insurance.phone ? styles.isChange : ''}
                    fullWidth
                    variant="outlined"
                    label="電話番号"
                    value={phone}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d]/g, '').slice(0, 11)
                      setPhone(value)
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    data-section="phone"
                  />
                  {phone !== insuranceSelector.insurance.phone && (
                    <small data-section="prev-phone">
                      変更前電話番号：{insuranceSelector.insurance.phone}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      otherInsuranceCompany !== '' &&
                      otherInsuranceCompany !== insuranceSelector.insurance.other_insurance_company
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="他社保険会社名"
                    value={otherInsuranceCompany}
                    onChange={(e) => setOtherInsuranceCompany(e.target.value)}
                    error={!!errors.other_insurance_company}
                    helperText={errors.other_insurance_company}
                    data-section="other-insurance-company"
                  />
                  {otherInsuranceCompany !==
                    insuranceSelector.insurance.other_insurance_company && (
                    <small data-section="prev-other-insurance-company">
                      変更前他社保険会社名：{insuranceSelector.insurance.other_insurance_company}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      otherInsuranceType !== '' &&
                      otherInsuranceType !== insuranceSelector.insurance.other_insurance_type
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="他社保険種類"
                    value={otherInsuranceType}
                    onChange={(e) => setOtherInsuranceType(e.target.value)}
                    error={!!errors.other_insurance_type}
                    helperText={errors.other_insurance_type}
                    data-section="other-insurance-type"
                  />
                  {otherInsuranceType !== insuranceSelector.insurance.other_insurance_type && (
                    <small data-section="prev-other-insurance-type">
                      変更前他社保険種類：{insuranceSelector.insurance.other_insurance_type}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <TextField
                    className={
                      otherInsuranceType !== '' &&
                      otherInsurancePeriod !== insuranceSelector.insurance.other_insurance_period
                        ? styles.isChange
                        : ''
                    }
                    fullWidth
                    variant="outlined"
                    label="他社満期日"
                    value={otherInsurancePeriod}
                    onChange={(e) => setOtherInsurancePeriod(e.target.value)}
                    error={!!errors.other_insurance_period}
                    helperText={errors.other_insurance_period}
                    data-section="other-insurance-period"
                  />
                  {otherInsurancePeriod !== insuranceSelector.insurance.other_insurance_period && (
                    <small data-section="prev-other-insurance-period">
                      変更前他社満期日：{insuranceSelector.insurance.other_insurance_period}
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <InputLabel id="select-label" className={styles.label}>
                    証券番号
                  </InputLabel>
                  <Select
                    id="select-label"
                    className={
                      policyNumberId !== insuranceSelector.insurance.policy_number.id
                        ? styles.isChange
                        : ''
                    }
                    style={{ width: '100%' }}
                    label="証券番号"
                    value={policyNumberId}
                    onChange={(e) => {
                      setPolicyNumberId(Number(e.target.value) as 1 | 0)
                    }}
                    error={Boolean(errors.policy_number_id)}
                  >
                    {PolicyNumbersSelector.policyNumbers.map((data, i) => {
                      return (
                        <MenuItem key={i} value={data.id}>
                          {data.policy_number}({data.year}年{data.month}月)
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {policyNumberId !== insuranceSelector.insurance.policy_number.id && (
                    <small data-section="prev-other-insurance-period">
                      変更前証券番号：
                      {insuranceSelector.insurance.policy_number.policy_number}(
                      {insuranceSelector.insurance.policy_number.year}年
                      {insuranceSelector.insurance.policy_number.month}月)
                    </small>
                  )}
                </Box>
                <Box className={styles.inputWrap}>
                  <InputLabel id="select-label" className={styles.label}>
                    決済状況
                  </InputLabel>
                  <Select
                    id="select-label"
                    className={
                      isSalesValue !== insuranceSelector.insurance.is_sales ? styles.isChange : ''
                    }
                    style={{ width: '100%' }}
                    label="決済状況"
                    value={isSalesValue}
                    onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                      setIsSalesValue(e.target.value as InsuranceIsSalesEnum)
                    }}
                    error={Boolean(errors.is_sales)}
                  >
                    <MenuItem value={InsuranceIsSalesEnum.TEMPORARY_SALES} data-section="savings">
                      {INSURANCE_IS_SALES_NAME[InsuranceIsSalesEnum.TEMPORARY_SALES]}
                    </MenuItem>
                    <MenuItem value={InsuranceIsSalesEnum.ACTUAL_SALES} data-section="current">
                      {INSURANCE_IS_SALES_NAME[InsuranceIsSalesEnum.ACTUAL_SALES]}
                    </MenuItem>
                    <MenuItem value={InsuranceIsSalesEnum.CANCEL} data-section="current">
                      {INSURANCE_IS_SALES_NAME[InsuranceIsSalesEnum.CANCEL]}
                    </MenuItem>
                  </Select>
                  {isSalesValue !== insuranceSelector.insurance.is_sales && (
                    <small data-section="prev-other-insurance-period">
                      変更前決済状況：
                      {INSURANCE_IS_SALES_NAME[insuranceSelector.insurance.is_sales]}
                    </small>
                  )}
                </Box>
              </form>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button onClick={moveBackPage} variant="contained" data-section="back">
                戻る
              </Button>
              {/* 契約情報が見つからなかった場合は非表示 */}
              {insuranceSelector.insurance && (
                <>
                  {insuranceSelector.insurance.deleted_at ? (
                    <Button color="primary" variant="contained" data-section="save" disabled={true}>
                      削除済みのため変更不可
                    </Button>
                  ) : (insuranceSelector.insurance.cancelled_at &&
                      checkExpirationTime(insuranceSelector.insurance.cancelled_at)) ||
                    (insuranceSelector.insurance.cancelled_at &&
                      checkOnTheDay(
                        insuranceSelector.insurance.application_date,
                        insuranceSelector.insurance.cancelled_at
                      )) ? (
                    <Button color="primary" variant="contained" data-section="save" disabled={true}>
                      解約済みのため変更不可
                    </Button>
                  ) : checkExpirationTime(insuranceSelector.insurance.contract_period_end) ? (
                    <Button color="primary" variant="contained" data-section="save" disabled={true}>
                      満期のため変更不可
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                      data-section="save"
                    >
                      変更を保存する
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Change
