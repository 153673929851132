import { AxiosError } from 'axios'
import { parse } from 'date-fns'
import { INTERNAL_SERVER_ERROR_MESSAGE } from 'const'

import axios from '../apis/axios'
import { ISO_YYYYMMDD } from '../const'

// HelthCheck用
export const callHelthCheck = async (param: string) => {
  await axios.get(`/helth_check?param=${param}`)
}

// 開発用
export const sleep = (milli: number, isReject?: boolean) => {
  return new Promise((resolve, reject) => {
    setTimeout(isReject ? reject : resolve, milli)
  })
}

export function base64ToByteArray(b64: string) {
  const byteCharacters = atob(b64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return byteArray
}

// 契約満了・解約日の判定
export function checkExpirationTime(dateStr: string): boolean {
  return parse(dateStr, ISO_YYYYMMDD, new Date()) <= new Date()
}

export function checkOnTheDay(application_date: string, cancelled_at: string): boolean {
  return application_date === cancelled_at
}

export function extractAxiosErrorObject(e: AxiosError) {
  const response = e.response
  if (response) {
    return {
      status: response.status || 500,
      error: response.data?.error || '',
      message: response.data?.message || INTERNAL_SERVER_ERROR_MESSAGE,
    }
  }
  return {
    status: 0,
    error: e.name,
    message: e.message,
  }
}

// 半角→全角(英数字)
export function replaceAlphanumericHalfToFull(str: string) {
  return str.replace(/[!-~]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
  })
}

// 半角カタカナ→全角カタカナ
export function replaceKanaHalfToFull(str: string) {
  const kanaMap: { [key: string]: string } = {
    ｶﾞ: 'ガ',
    ｷﾞ: 'ギ',
    ｸﾞ: 'グ',
    ｹﾞ: 'ゲ',
    ｺﾞ: 'ゴ',
    ｻﾞ: 'ザ',
    ｼﾞ: 'ジ',
    ｽﾞ: 'ズ',
    ｾﾞ: 'ゼ',
    ｿﾞ: 'ゾ',
    ﾀﾞ: 'ダ',
    ﾁﾞ: 'ヂ',
    ﾂﾞ: 'ヅ',
    ﾃﾞ: 'デ',
    ﾄﾞ: 'ド',
    ﾊﾞ: 'バ',
    ﾋﾞ: 'ビ',
    ﾌﾞ: 'ブ',
    ﾍﾞ: 'ベ',
    ﾎﾞ: 'ボ',
    ﾊﾟ: 'パ',
    ﾋﾟ: 'ピ',
    ﾌﾟ: 'プ',
    ﾍﾟ: 'ペ',
    ﾎﾟ: 'ポ',
    ｳﾞ: 'ヴ',
    ﾜﾞ: 'ヷ',
    ｦﾞ: 'ヺ',
    ｱ: 'ア',
    ｲ: 'イ',
    ｳ: 'ウ',
    ｴ: 'エ',
    ｵ: 'オ',
    ｶ: 'カ',
    ｷ: 'キ',
    ｸ: 'ク',
    ｹ: 'ケ',
    ｺ: 'コ',
    ｻ: 'サ',
    ｼ: 'シ',
    ｽ: 'ス',
    ｾ: 'セ',
    ｿ: 'ソ',
    ﾀ: 'タ',
    ﾁ: 'チ',
    ﾂ: 'ツ',
    ﾃ: 'テ',
    ﾄ: 'ト',
    ﾅ: 'ナ',
    ﾆ: 'ニ',
    ﾇ: 'ヌ',
    ﾈ: 'ネ',
    ﾉ: 'ノ',
    ﾊ: 'ハ',
    ﾋ: 'ヒ',
    ﾌ: 'フ',
    ﾍ: 'ヘ',
    ﾎ: 'ホ',
    ﾏ: 'マ',
    ﾐ: 'ミ',
    ﾑ: 'ム',
    ﾒ: 'メ',
    ﾓ: 'モ',
    ﾔ: 'ヤ',
    ﾕ: 'ユ',
    ﾖ: 'ヨ',
    ﾗ: 'ラ',
    ﾘ: 'リ',
    ﾙ: 'ル',
    ﾚ: 'レ',
    ﾛ: 'ロ',
    ﾜ: 'ワ',
    ｦ: 'ヲ',
    ﾝ: 'ン',
    ｧ: 'ァ',
    ｨ: 'ィ',
    ｩ: 'ゥ',
    ｪ: 'ェ',
    ｫ: 'ォ',
    ｯ: 'ッ',
    ｬ: 'ャ',
    ｭ: 'ュ',
    ｮ: 'ョ',
    '｡': '。',
    '､': '、',
    '｢': '「',
    '｣': '」',
    '･': '・',
    ' ': '　',
  }

  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g')
  return str
    .replace(reg, function (match) {
      return kanaMap[match]
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
    .replace(/[\u002d\u2010\u2011\u2013-\u2015\u2212\uff0d\uff70]/g, 'ー')
}

// 氏名（カナ）の半角スペース・半角ハイフン→全角スペース・全角ハイフン
export function replaceNameKanaHalfToFull(str: string) {
  return str
    .replace(/ /g, '　')
    .replace(/[\u002d\u2010\u2011\u2013-\u2015\u2212\uff0d\uff70]/g, 'ー')
}

// 半角→全角
export function replaceHalfToFull(str: string) {
  return replaceKanaHalfToFull(replaceAlphanumericHalfToFull(str))
}

export function decodeTokenPayload(token: string) {
  return JSON.parse(atob(token.split('.')[1]))
}

// idTokenの有効時間判定
export function needsRefresh(token: string): boolean {
  const payload = decodeTokenPayload(token)
  const exp = new Date(Number(payload.exp) * 1000)
  const now = new Date()

  // 有効期限切れまで5分以下ならリフレッシュ必要と判定
  const after5Minutes = new Date(now.getTime() + 5 * 60 * 1000)
  return after5Minutes >= exp
}

export function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
