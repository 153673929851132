import { TestContext, StringSchema, ValidationError } from 'yup'
import * as yup from 'yup'
const { setLocale, object, string, date, number } = yup
import _set from 'lodash/set'
import { CognitoGroupNameEnum } from '../types/login'
import { InsuranceIsSalesEnum } from '../types/insurance'

// eslint-disable-next-line no-irregular-whitespace
const KANA_REGEX = /^[ァ-ヶｦ-ﾟ]([ァ-ヶｦ-ﾟー\-‐‑–—―−ｰ－\s])*$/g
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9!-*,-/:-<>-@[-`{-~]{8,16}$/g
// 数字のみ
const PHONE_NUMBER_REGEX = /^0[0-9]{9,10}$/g

// eslint-disable-next-line @typescript-eslint/no-unused-vars
yup.addMethod<StringSchema>(string, 'kanji', function (formats, parseStrict) {
  return this.test('kanji', '${char}は入力できません。', function (value) {
    const { path, createError } = this
    const r = new RegExp(
      '[∪∩∠⊥≡≒√∵∫Σ①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳㍉㌔㌢㍍㌘㌧㌃㌶㍑㍗㌍㌦㌣㌫㍊㌻㎜㎝㎞㎎㎏㏄㎡㍻〝〟№㏍℡㊤㊥㊦㊧㊨㈲㈹㍾㍽㍼≒≡∫∮∑√⊥∠∟⊿∵∩∪悵煕凜№℡∵]',
      'gi'
    )
    if (value) {
      const match = value.match(r)
      if (match) {
        const [char] = match
        return createError({
          path,
          params: {
            char,
          },
        })
      }
    }
    return true
  })
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
yup.addMethod<StringSchema>(string, 'kana', function (formats, parseStrict) {
  return this.test('kana', 'カタカナで入力してください。', function (value) {
    const { path, createError } = this
    const r = new RegExp(
      '^[' +
        '、。，．・：；？！゛゜´｀¨＾￣＿ヽヾゝゞ〃仝々〆〇ー―‐／＼～∥｜…‥‘’“”（）〔〕［］｛｝〈〉《》「」『』【】＋－±×÷＝≠＜＞≦≧∞∴♂♀°′″℃￥＄￠￡％＃＆＊＠§☆★○●◎◇◆□■△▲▽▼※〒→←↑↓〓' +
        '∈∋⊆⊇⊂⊃∧∨￢⇒⇔∀∃⌒∂∇≪≫∽∝∬Å‰♯♭♪†‡¶◯' +
        '０１２３４５６７８９' +
        'ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶ' +
        'ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψω' +
        'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя─│┌┐┘└├┬┤┴┼━┃┏┓┛┗┣┳┫┻╋┠┯┨┷┿┝┰┥┸╂' +
        'ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ㈱' +
        'ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ￢|＇＂㈱丨' +
        ']*$',
      'gi'
    )
    if (value) {
      const match = value.match(r)
      if (!match) {
        return createError({
          path,
        })
      }
    }
    return true
  })
})

setLocale({
  mixed: {
    required: '入力してください',
  },
  string: {
    min: '${min}文字以上入力してください',
    max: '${max}文字以内で入力してください',
    email: 'メールアドレスを正しく入力してください',
  },
})

export const getErrorAsObject = <R extends Record<string, unknown>>(err: ValidationError): R => {
  const errObj: { [key in string]: Record<string, unknown> | string } = {}
  if (err.path) {
    return _set(errObj, err.path, err.message) as R
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return err.inner.reduce((acc: any, cur: ValidationError) => {
    return {
      ...acc,
      ...getErrorAsObject(cur),
    }
  }, errObj) as R
}

export const policyNumberSchema = object().shape({
  policyNumber: string()
    .trim()
    .required()
    .test({
      name: 'valid-policy-numbers',
      message: '10桁の半角数字を改行で区切って入力してください',
      test: function (this: TestContext) {
        const numbers = this.parent.policyNumber.split('\n')
        return numbers.every((number: string) => /^[0-9]{10}$/.test(number))
      },
    })
    .test({
      name: 'valid-policy-numbers',
      message: '一度に登録する証券番号は650件以下にしてください',
      test: function (this: TestContext) {
        const numbers = this.parent.policyNumber.split('\n')
        return numbers.length <= 650
      },
    }),
})

export const questionAnswerSchema = object().shape({
  question: string().required().max(1024),
  answer: string().required().max(1024),
})

export const insuranceSchema = object().shape({
  insuranceProduct: string().required(),
  applicationDate: date().required().typeError('正しく入力してください'),
  contractPeriodStart: date().required().typeError('正しく入力してください'),
  contractPeriodEnd: date().required().typeError('正しく入力してください'),
})

export const cancelInsuranceSchema = object().shape({
  cancelled_apply_date: date().required().typeError('正しく入力してください'),
})

export const loginSchema = object().shape({
  email: string().trim().required().email(),
  password: string()
    .required()
    .matches(PASSWORD_REGEX, '半角英数字で小文字と数字を含めた8〜16文字で入力してください。'),
})

export const authChallengeSmsSchema = object().shape({
  confirmation_code: string().required(),
})

export const authChallengeSchema = object().shape({
  password: string()
    .required()
    .matches(PASSWORD_REGEX, '半角英数字で小文字と数字を含めた8〜16文字で入力してください。'),
  // 確認パスワード
  // https://www.fixes.pub/program/466441.html
  confirmationPassword: string()
    .required()
    .when('password', (password, schema) => {
      return schema.test({
        test: (confirmationPassword: string) => password === confirmationPassword,
        message: 'パスワードが一致しません',
      })
    }),
})

export const forgotPasswordSchema = object().shape({
  email: string().trim().required().email(),
})

export const confirmForgotPasswordSchema = object().shape({
  confirmation_code: string().required(),
  password: string()
    .required()
    .matches(PASSWORD_REGEX, '半角英数字で小文字と数字を含めた8〜16文字で入力してください。'),
  // 確認パスワード
  // https://www.fixes.pub/program/466441.html
  confirmationPassword: string()
    .required()
    .when('password', (password, schema) => {
      return schema.test({
        test: (confirmationPassword: string) => password === confirmationPassword,
        message: 'パスワードが一致しません',
      })
    }),
})

export const downloadInsurancesSchema = object().shape({
  from_date: date().required().typeError('正しく入力してください'),
  to_date: date().required().typeError('正しく入力してください'),
  approve_number: string()
    .trim()
    .nullable()
    .matches(/^(\w{0,9})?$/g, '半角英数字(9文字以下)で入力してください。'),
  agency_code: string()
    .trim()
    .nullable()
    .matches(/^(\w{0,5})?$/g, '半角英数字(5文字以下)で入力してください。'),
  agency_subcode: string()
    .trim()
    .nullable()
    .matches(/^(\w{0,3})?$/g, '半角英数字(3文字以下)で入力してください。'),
  department_store_charge: string()
    .trim()
    .nullable()
    .matches(/^(\w{0,4})?$/g, '半角英数字(4文字以下)で入力してください。'),
})

export const downloadSettlementsSchema = object().shape({
  from_date: date().required().typeError('正しく入力してください'),
  to_date: date().required().typeError('正しく入力してください'),
})

export const adminCreateUserSchema = object().shape({
  email: string().trim().required().email(),
  phone_number: string()
    .required()
    .matches(PHONE_NUMBER_REGEX, 'ハイフンなしの数字のみで入力してください'),
  // selectなので不要かと思いますが念の為
  group_name: string()
    .required()
    .test({
      name: 'valid-group-name',
      message: '選択肢から選んでください',
      test: function (this: TestContext) {
        const regexp = new RegExp(
          `^(${CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME}|${CognitoGroupNameEnum.COGNITO_AGENCY_GROUP_NAME})$`,
          'g'
        )
        return regexp.test(this.parent.group_name)
      },
    }),
  agencies_id: string()
    .trim()
    .when('group_name', (group_name, schema) => {
      return schema.test({
        // 代理店権限が選択されている場合は代理店情報を必須に
        test: (agencies_id: string) => {
          if (group_name === CognitoGroupNameEnum.COGNITO_AGENCY_GROUP_NAME) {
            return !!agencies_id
          }
          return true
        },
        message: '代理店権限の場合は代理店を選択してください',
      })
    }),
})

export const changeInsuranceInfoSchema = object().shape({
  sales_amount: number().required(),
  industry: string()
    .required()
    .max(100, '100文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  company_name: string()
    .required()
    .max(100, '100文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  contractor_name: string()
    .required()
    .max(100, '100文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  zip: string()
    .trim()
    .required()
    .max(8)
    .matches(/^[0-9]{7}$/g, '郵便番号を正しく入力してください'),
  address: string()
    .trim()
    .required()
    .max(100, '100文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  manager_name: string().trim().required().max(100, '100文字以内で入力して下さい'),
  phone: string()
    .trim()
    .required()
    .matches(/^(^0[0-9]{9,10}$)$/g, '電話番号を正しく入力してください')
    .max(11),
  email: string().trim().required().email(),
  other_insurance_company: string().trim().max(100, '100文字以内で入力して下さい'),
  other_insurance_type: string().trim().max(100, '100文字以内で入力して下さい'),
  other_insurance_period: string().trim().max(100, '100文字以内で入力して下さい'),
  // selectなので不要かと思いますが念の為
  policy_number_id: number().typeError('正しく入力してください').required(),
  // selectなので不要かと思いますが念の為
  is_sales: number()
    .typeError('正しく入力してください')
    .required()
    .test({
      name: 'valid-is-sales',
      message: '選択肢から選んでください',
      test: function (this: TestContext) {
        const regexp = new RegExp(
          `^(${InsuranceIsSalesEnum.TEMPORARY_SALES}|${InsuranceIsSalesEnum.ACTUAL_SALES}|${InsuranceIsSalesEnum.CANCEL})$`,
          'g'
        )
        return regexp.test(String(this.parent.is_sales))
      },
    }),
})

export const changePolicyNumberInfoSchema = object().shape({
  policy_number: string()
    .trim()
    .required()
    .matches(/^[0-9]{13}$/g, '13桁の証券番号を入力してください'),
  year: string()
    .trim()
    .required()
    .matches(/^[0-9]{4}$/g, '登録年を正しく入力してください'),
  month: string()
    .trim()
    .required()
    .matches(/^(0[1-9]|1[0-2])$/g, '登録月を正しく入力してください'),
})

export const registerPolicyNumberInfoSchema = object().shape({
  policy_number: string()
    .trim()
    .required()
    .matches(/^[0-9]{13}$/g, '13桁の証券番号を入力してください'),
  year: string()
    .trim()
    .required()
    .matches(/^[0-9]{4}$/g, '登録年を正しく入力してください'),
  month: string()
    .trim()
    .required()
    .matches(/^(0[1-9]|1[0-2])$/g, '登録月を正しく入力してください'),
  platform_id: number().required(),
})

export const changePlatformInfoSchema = object().shape({
  platform_name: string().trim().required().max(50, '50文字以内で入力して下さい'),
  identifier: string()
    .trim()
    .required()
    .matches(/^[A-Z]{2}$/g, '2文字以内の大文字英字で入力してください'),
})

export const registerPlatformInfoSchema = object().shape({
  platform_name: string().trim().required().max(50, '50文字以内で入力して下さい'),
  identifier: string()
    .trim()
    .required()
    .matches(/^[A-Z]{2}$/g, '2文字以内の大文字英字で入力してください'),
})

export const registerSalesOfficeInfoSchema = object().shape({
  sales_office_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  sales_office_name_kana: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    .matches(KANA_REGEX, 'カタカナで入力してください。'),
  contract_email: string().trim().required().email(),
  accident_email: string().trim().required().email(),
})

export const changeSalesOfficeInfoSchema = object().shape({
  sales_office_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  sales_office_name_kana: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    .matches(KANA_REGEX, 'カタカナで入力してください。'),
  contract_email: string().trim().required().email(),
  accident_email: string().trim().required().email(),
})

export const registerAgencyInfoSchema = object().shape({
  agency_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  agency_name_kana: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    .matches(KANA_REGEX, 'カタカナで入力してください。'),
  agency_official_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  contract_email: string().trim().required().email(),
  accident_email: string().trim().required().email(),
})

export const changeAgencyInfoSchema = object().shape({
  agency_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  agency_name_kana: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    .matches(KANA_REGEX, 'カタカナで入力してください。'),
  agency_official_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  contract_email: string().trim().required().email(),
  accident_email: string().trim().required().email(),
})

export const registerInsuranceOfficeInfoSchema = object().shape({
  insurance_office_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  insurance_office_name_kana: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    .matches(KANA_REGEX, 'カタカナで入力してください。'),
  contract_email: string().trim().required().email(),
  accident_email: string().trim().required().email(),
})

export const changeInsuranceOfficeInfoSchema = object().shape({
  insurance_office_name: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .kanji(),
  insurance_office_name_kana: string()
    .trim()
    .required()
    .max(50, '50文字以内で入力して下さい')
    .matches(KANA_REGEX, 'カタカナで入力してください。'),
  contract_email: string().trim().required().email(),
  accident_email: string().trim().required().email(),
})

export const downloadCsvInfoSchema = object().shape({
  from_date: date().required().typeError('正しく入力してください'),
  to_date: date().required().typeError('正しく入力してください'),
})

export const changeEmailTextSchema = object().shape({
  title: string().trim().required().max(100, '100文字以内で入力して下さい'),
  body: string().trim().required().max(4000, '4000文字以内で入力して下さい'),
})

export const registerEmailTextSchema = object().shape({
  platform_id: string()
    .required()
    .test({
      name: 'valid-platform-id',
      message: '選択してください',
      test: function (this: TestContext) {
        return this.parent.platform_id != '0'
      },
    }),
  destination: string()
    .required()
    .test({
      name: 'valid-destination',
      message: '選択してください',
      test: function (this: TestContext) {
        return this.parent.destination != '0'
      },
    }),
  type: string()
    .required()
    .test({
      name: 'valid-type',
      message: '選択してください',
      test: function (this: TestContext) {
        return this.parent.type != '0'
      },
    }),
  title: string().trim().required().max(100, '100文字以内で入力して下さい'),
  body: string().trim().required().max(4000, '4000文字以内で入力して下さい'),
})
