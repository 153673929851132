import { AxiosResponse } from 'axios'
import axios from '../../apis/axios'
import { PolicyNumber } from '../../types/policyNumber'

/*
 * 契約情報取得
 */

export interface FindPolicyNunberResponse {
  policy_number: PolicyNumber
}

export const callFindPolicyNumber = async (policyNumberId: string) => {
  const { data }: AxiosResponse<FindPolicyNunberResponse> = await axios.get(
    `/policy_number/${policyNumberId}`
  )
  return data.policy_number
}

/*
 * 証券番号情報登録
 */
export interface PostPolicyNumberRequestParams {
  policy_number: string
  platform_id: number
  year: string
  month: string
}

export const callPostPolicyNumberInfo = async (params: PostPolicyNumberRequestParams) => {
  await axios.post('/policy_number', {
    params: params,
  })
}

/*
 * 証券番号情報更新
 */
export interface PutPolicyNumberRequestParams {
  policy_number: string
  year: string
  month: string
  platform_id: number
}

export const callPutPolicyNumberInfo = async (
  insuranceId: string,
  params: PutPolicyNumberRequestParams
) => {
  await axios.put(`/policy_number/${insuranceId}`, {
    params: params,
  })
}
