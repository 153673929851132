import React from 'react'
import styles from './styles.module.scss'

interface Props {
  title: string
  isCenter?: boolean
}

const Title = ({ title, isCenter = false }: Props) => {
  return <div className={`${styles.title} ${isCenter ? styles.isCenter : ''}`}>{title}</div>
}

export default Title
