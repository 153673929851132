import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { callGetInsuranceOfficesByPlatformId } from '../utils/apis/insuranceOffice'
import { InsuranceOffice } from '../types/insuranceOffice'

export interface InsuranceOfficesState {
  isLoading: boolean
  insuranceOffices: InsuranceOffice[]
  error?: ErrorResponse
}

export const InsuranceOfficesState: InsuranceOfficesState = {
  isLoading: false,
  insuranceOffices: [],
}

export interface PutSalesOfficeRequestParams {
  salesOfficeId: string
}

const InsuranceOfficesModules = createSlice({
  name: 'insuranceOffices',
  initialState: InsuranceOfficesState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetInsuranceOfficesSuccess: (state, action: PayloadAction<InsuranceOffice[]>) => ({
      ...state,
      isLoading: false,
      insuranceOffices: action.payload,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
  },
})

export const fetchGetInsuranceOffices = (platformId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceOfficesModules.actions.resetError())
    dispatch(InsuranceOfficesModules.actions.callApiStart())
    const res = await callGetInsuranceOfficesByPlatformId(platformId)
    dispatch(InsuranceOfficesModules.actions.callGetInsuranceOfficesSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetinsuranceOffices error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      InsuranceOfficesModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default InsuranceOfficesModules
