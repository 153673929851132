import { AxiosResponse } from 'axios'

import axios from '../../apis/axios'
import {
  FindEmailTextResponse,
  CheckEmailTextExistResponse,
  PostEmailTextRequestParams,
  PutEmailTextRequestParams,
} from '../../types/emailText'

/*
 * メール文書取得
 */
export const callFindEmailText = async (emailTextId: string) => {
  const { data }: AxiosResponse<FindEmailTextResponse> = await axios.get(
    `/email_text/${emailTextId}`
  )
  return data.email_text
}

/*
 * メール文書の存在確認
 */
export const callCheckEmailTextExist = async (
  platformId: number,
  destination: number,
  type: number
) => {
  const { data }: AxiosResponse<CheckEmailTextExistResponse> = await axios.get(
    `/email_text/${platformId}/${destination}/${type}`
  )
  return data.email_text_count
}

/*
 * メール文書登録
 */
export const callPostEmailTextInfo = async (params: PostEmailTextRequestParams) => {
  await axios.post('/email_text', {
    params: params,
  })
}

/*
 * メール文書更新
 */
export const callPutEmailTextInfo = async (
  emailTextId: string,
  params: PutEmailTextRequestParams
) => {
  await axios.put(`/email_text/${emailTextId}`, {
    params: params,
  })
}
