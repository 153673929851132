import React, {
  ChangeEvent,
  useCallback,
  useState,
  useEffect,
  SyntheticEvent,
  useLayoutEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Paper,
  Container,
  Box,
  TextField,
  Button,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { getErrorAsObject, adminCreateUserSchema } from 'utils/validate'
import ApiError from 'components/ApiError'
import styles from './styles.module.scss'
import Title from 'components/Title'
import { selectAdmin, selectAgencies, selectLogin } from 'store/selector'
import { PostAdminCreateUserRequestParams } from '../../../utils/apis/admin'
import { CognitoGroupNameEnum } from '../../../types/login'
import { fetchGetAgencies } from '../../../store/agencies'
import AdminModules, { fetchPostAdminCreateUser } from '../../../store/admin'

const initialInputState = {
  email: '',
  phone_number: '',
  group_name: CognitoGroupNameEnum.COGNITO_AGENCY_GROUP_NAME,
  agencies_id: '',
}

const AdminCreateUser = () => {
  const dispatch = useDispatch()
  const adminSelector = useSelector(selectAdmin)
  const agenciesSelector = useSelector(selectAgencies)
  const loginSelector = useSelector(selectLogin)
  const history = useHistory()

  useLayoutEffect(() => {
    if (loginSelector.cognitoGroupName !== CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME) {
      moveErrorPage('権限がありません')
    }
  }, [location.pathname])

  const moveErrorPage = (errorMessage: string) => {
    history.push({
      pathname: '/error',
      state: { errorMessage: errorMessage },
    })
  }

  const [errors, setErrors] = useState<
    { [key in keyof PostAdminCreateUserRequestParams]?: string }
  >({})
  const [inputAdminCreateUser, setInputAdminCreateUser] = useState<
    PostAdminCreateUserRequestParams
  >(initialInputState)

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      setErrors({
        ...errors,
        [key]: '',
      })
      setInputAdminCreateUser({
        ...inputAdminCreateUser,
        [key]: event.target.value,
      })
    },
    [inputAdminCreateUser, errors]
  )

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    try {
      await adminCreateUserSchema.validate(inputAdminCreateUser, { abortEarly: false })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      setErrors(getErrorAsObject(err))
      return
    }
    dispatch(fetchPostAdminCreateUser(inputAdminCreateUser))
  }

  useEffect(() => {
    // 代理店情報取得
    dispatch(fetchGetAgencies())
  }, [])

  // ユーザー作成成功時のリセット操作
  useEffect(() => {
    if (adminSelector.isCreateUserSuccess) {
      // input（state）のリセット
      setInputAdminCreateUser(initialInputState)

      setTimeout(() => {
        dispatch(AdminModules.actions.resetIsCreateUserSuccess())
      }, 5000)
    }
  }, [adminSelector.isCreateUserSuccess])

  return (
    <div data-page="admin-create-user">
      {adminSelector.isCreateUserSuccess && (
        <div>
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success">ユーザー作成に成功しました。</Alert>
          </Snackbar>
        </div>
      )}
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="管理者用ユーザー作成" isCenter={true} />
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit}>
          <div data-section="api-error">
            {adminSelector.error ? (
              <ApiError
                error={adminSelector.error.error}
                message={adminSelector.error.message}
                status={adminSelector.error.status}
              />
            ) : agenciesSelector.error ? (
              <ApiError
                error={agenciesSelector.error.error}
                message={agenciesSelector.error.message}
                status={agenciesSelector.error.status}
              />
            ) : null}
          </div>
          {adminSelector.isLoading || agenciesSelector.isLoading ? <CircularProgress /> : null}
          <Container className={styles.forms} maxWidth="sm" component={Paper} variant="outlined">
            <TextField
              fullWidth
              label="メールアドレス"
              margin="normal"
              value={inputAdminCreateUser.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'email')
              }}
              error={Boolean(errors.email)}
              helperText={errors.email}
              data-section="email"
            />
            <TextField
              fullWidth
              label="電話番号"
              margin="normal"
              value={inputAdminCreateUser.phone_number}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'phone_number')
              }}
              error={Boolean(errors.phone_number)}
              helperText={errors.phone_number}
              data-section="phone_number"
            />
            <FormControl error={Boolean(errors.group_name)}>
              <InputLabel id="select-group-name-label">権限</InputLabel>
              <Select
                labelId="select-group-name-label"
                label="権限"
                value={inputAdminCreateUser.group_name}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => {
                  handleTextFieldChange(e, 'group_name')
                }}
                data-section="group_name"
              >
                <MenuItem value={CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME}>
                  管理者権限
                </MenuItem>
                <MenuItem value={CognitoGroupNameEnum.COGNITO_AGENCY_GROUP_NAME}>
                  営業店/代理店/保サ権限（自店舗限定）
                </MenuItem>
              </Select>
              {Boolean(errors.group_name) && <FormHelperText>{errors.group_name}</FormHelperText>}
            </FormControl>
            <FormControl error={Boolean(errors.agencies_id)}>
              <InputLabel id="select-agencies-id-label">代理店（代理店権限の場合のみ）</InputLabel>
              <Select
                labelId="select-agencies-id-label"
                label="代理店情報"
                value={inputAdminCreateUser.agencies_id}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => {
                  handleTextFieldChange(e, 'agencies_id')
                }}
                data-section="agencies_id"
                disabled={
                  inputAdminCreateUser.group_name === CognitoGroupNameEnum.COGNITO_AGENCY_GROUP_NAME
                    ? false
                    : true
                }
              >
                <MenuItem value="">なし</MenuItem>
                {agenciesSelector.agencies.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.id.toString()}>
                      {data.agency_name}
                    </MenuItem>
                  )
                })}
              </Select>
              {Boolean(errors.agencies_id) && <FormHelperText>{errors.agencies_id}</FormHelperText>}
            </FormControl>
            <div className={styles.buttonWrap}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={adminSelector.isLoading}
                data-section="submitButton"
              >
                ユーザーを作成する
              </Button>
            </div>
          </Container>
        </Box>
      </Container>
    </div>
  )
}

export default AdminCreateUser
