import { AxiosResponse } from 'axios'

import axios from '../../apis/axios'

export interface PostLoginResponse {
  challenge_name: string
  session: string
  user_id: string
}

export interface PostLoginRequestParams {
  email: string
  password: string
}

export const callPostLogin = async (params: PostLoginRequestParams) => {
  const { data }: AxiosResponse<PostLoginResponse> = await axios.post('/auth/login', {
    params: params,
  })
  return data
}

export interface PostAuthChallengeSmsRequestParams {
  session: string
  user_id: string
  confirmation_code: string
}

export interface PostAuthChallengeSmsResponse {
  id_token: string
  refresh_token: string
  agencies_id: string
  platform_id: number
}

// SMS認証
export const callPostAuthChallengeSms = async (params: PostAuthChallengeSmsRequestParams) => {
  const { data }: AxiosResponse<PostAuthChallengeSmsResponse> = await axios.post(
    '/auth/auth_challenge_sms',
    {
      params: params,
    }
  )
  return data
}

export interface PostAuthChallengeRequestParams {
  session: string
  user_id: string
  password: string
}

export interface PostAuthChallengeResponse {
  challenge_name: string
  session: string
}

// パスワード再設定
export const callPostAuthChallenge = async (params: PostAuthChallengeRequestParams) => {
  const { data }: AxiosResponse<PostAuthChallengeResponse> = await axios.post(
    '/auth/auth_challenge',
    {
      params: params,
    }
  )
  return data
}

export interface PostForgotPasswordRequestParams {
  email: string
}

// パスワードリセット（忘れた人用）
export const callPostForgotPassword = async (params: PostForgotPasswordRequestParams) => {
  await axios.post('/auth/forgot_password', {
    params: params,
  })
}

export interface PostConfirmForgotPasswordRequestParams {
  email: string
  password: string
  confirmation_code: string
}

// パスワードリセット（忘れた人用）
export const callPostConfirmForgotPassword = async (
  params: PostConfirmForgotPasswordRequestParams
) => {
  await axios.post('/auth/confirm_forgot_password', {
    params: params,
  })
}
