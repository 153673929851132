import { RootState } from './index'
import { InsuranceState } from './insurance'
import { InsurancesState } from './insurances'
import { LoginState } from './login'
import { InsuranceAdminChangeHistoriesState } from './insuranceAdminChangeHistories'
import { PolicyNumberState } from './policyNumber'
import { PolicyNumbersState } from './policyNumbers'
import { EmailTextState } from './emailText'
import { EmailTextsState } from './emailTexts'
import { PlatformState } from './platform'
import { PlatformsState } from './platforms'
import { SalesOfficeState } from './salesOffice'
import { SalesOfficesState } from './salesOffices'
import { AdminState } from './admin'
import { AgencyState } from './agency'
import { AgenciesState } from './agencies'
import { InsuranceOfficeState } from './insuranceOffice'
import { InsuranceOfficesState } from './insuranceOffices'
import { UserState } from './user'
import { QuestionAnswerState } from './questionAnswer'
import { QuestionAnswersState } from './questionAnswers'
import { CsvTemplatesState } from './csvTemplates'

export const selectInsurance = (store: RootState): InsuranceState => store.insurance
export const selectInsurances = (store: RootState): InsurancesState => store.insurances
export const selectInsuranceAdminChangeHistories = (
  store: RootState
): InsuranceAdminChangeHistoriesState => store.insuranceAdminChangeHistories
export const selectLogin = (store: RootState): LoginState => store.login
export const selectPolicyNumber = (store: RootState): PolicyNumberState => store.policyNumber
export const selectPolicyNumbers = (store: RootState): PolicyNumbersState => store.policyNumbers
export const selectEmailText = (store: RootState): EmailTextState => store.emailText
export const selectEmailTexts = (store: RootState): EmailTextsState => store.emailTexts
export const selectPlatform = (store: RootState): PlatformState => store.platform
export const selectPlatforms = (store: RootState): PlatformsState => store.platforms
export const selectSalesOffice = (store: RootState): SalesOfficeState => store.salesOffice
export const selectSalesOffices = (store: RootState): SalesOfficesState => store.salesOffices
export const selectAgency = (store: RootState): AgencyState => store.agency
export const selectAgencies = (store: RootState): AgenciesState => store.agencies
export const selectInsuranceOffice = (store: RootState): InsuranceOfficeState =>
  store.insuranceOffice
export const selectInsuranceOffices = (store: RootState): InsuranceOfficesState =>
  store.insuranceOffices
export const selectAdmin = (store: RootState): AdminState => store.admin
//export const selectAgencies = (store: RootState): AgenciesState => store.agencies TODO: 変更予定(佐藤)
export const selectUser = (store: RootState): UserState => store.user
export const selectQuestionAnswer = (store: RootState): QuestionAnswerState => store.questionAnswer
export const selectQuestionAnswers = (store: RootState): QuestionAnswersState =>
  store.questionAnswers
export const selectCsvTemplates = (store: RootState): CsvTemplatesState => store.csvTemplates
