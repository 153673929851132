import { AxiosResponse } from 'axios'
import axios from '../../apis/axios'
import { Agency, AgencysResponce } from '../../types/agency'

export interface GetAgenciesResponse {
  agencies: Agency[]
}

// 代理店一覧取得
export const callGetAgencies = async () => {
  const { data }: AxiosResponse<GetAgenciesResponse> = await axios.get('/agencies')
  return data.agencies
}

// 代理店一覧取得
export const callGetAgenciesByPlatformId = async (platformId: string) => {
  const { data }: AxiosResponse<AgencysResponce> = await axios.get(`/agencies/${platformId}`)
  return data.agencies
}
