import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Alert from '@material-ui/lab/Alert'
import Pagination from '@material-ui/lab/Pagination'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { push } from 'connected-react-router'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlatformModules, { fetchGetPlatforms } from 'store/platforms'
import PolicyNumbersModules, { fetchGetPolicyNumbers } from 'store/policyNumbers'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import { INSURANCE_PRODUCT_NAME } from '../../const'
import InsurancesModules, {
  fetchFindInsurances,
  InsuranceSearchParams,
  SortOrderParams,
} from '../../store/insurances'
import {
  selectInsurance,
  selectInsurances,
  selectLogin,
  selectPlatforms,
  selectPolicyNumbers,
} from '../../store/selector'
import { InsuranceProductEnum } from '../../types/insurance'
import { CognitoGroupNameEnum } from '../../types/login'
import IsSalesModal from '../IsSalesModal/index'
import styles from './styles.module.scss'

const Insurances = () => {
  const dispatch = useDispatch()
  const insurancesSelector = useSelector(selectInsurances)
  const insuranceSelector = useSelector(selectInsurance)
  const PolicyNumbersSelector = useSelector(selectPolicyNumbers)
  const PlatformsSelector = useSelector(selectPlatforms)
  const [modalOpen, setModalOpen] = useState(false)
  const [changeButtonDisable, setChangeButtonDisable] = useState(false)
  // 選択した契約情報
  const [checkedInsurances, setCheckedInsurances] = useState([] as number[])
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false)
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false)
  const [nPage, setNPage] = useState(0)

  const handleCloseErrorSnackBar = () => {
    setErrorSnackBarOpen(false)
  }

  const handleCloseSuccessSnackBar = () => {
    setSuccessSnackBarOpen(false)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleOnCheck = useCallback(
    (insurance_id: number) => {
      if (checkedInsurances.includes(insurance_id)) {
        // すでに含まれていたら削除する
        setCheckedInsurances(checkedInsurances.filter((item) => item !== insurance_id))
      } else {
        // 含まれていなければ新しく追加する
        setCheckedInsurances([...checkedInsurances, insurance_id])
      }
    },
    [checkedInsurances]
  )

  const loginSelector = useSelector(selectLogin)
  useEffect(() => {
    dispatch(fetchGetPolicyNumbers())
    dispatch(fetchGetPlatforms())
  }, [dispatch])

  let loginUserPlatformId = 0
  useLayoutEffect(() => {
    // ログインユーザーのプラットフォームIDを検索条件にセット admin権限は全選択可能
    if (loginSelector.cognitoGroupName !== CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME) {
      loginUserPlatformId = loginSelector.platform_id
      searchParams.platformId = loginUserPlatformId
    }
    //handleSearch()
  }, [dispatch])

  const [searchParams, setSearchParams] = useState<InsuranceSearchParams>({
    companyName: '',
    contractPeriodStartFrom: null,
    contractPeriodStartTo: null,
    contractPeriodEndFrom: null,
    contractPeriodEndTo: null,
    policyNumberId: 0,
    // 管理者権限の場合、loginUserPlatformIdは''
    platformId: loginUserPlatformId,
    contractorId: '',
    insuranceProduct: 0,
    pageNumber: 1,
    perPage: 100,
    sortOrderParams: {
      memberId: undefined,
      contractorId: undefined,
      policyNumber: undefined,
      platformName: undefined,
      insuranceProduct: undefined,
      applicationDate: undefined,
      contractPeriodStart: undefined,
      contractPeriodEnd: undefined,
      startFrom: undefined,
      startTo: undefined,
      cancelledAt: undefined,
      companyName: undefined,
      contractState: undefined,
      paymentState: undefined,
    },
  })

  const handleChangeCompanyName = useCallback(
    (e) => setSearchParams({ ...searchParams, companyName: e.target.value }),
    [searchParams]
  )

  const handleChangeContractPeriodStartFrom = useCallback(
    (date: MaterialUiPickersDate) => {
      setSearchParams({ ...searchParams, contractPeriodStartFrom: date })
    },
    [searchParams]
  )

  const handleChangeContractPeriodStartTo = useCallback(
    (date: MaterialUiPickersDate) => {
      setSearchParams({ ...searchParams, contractPeriodStartTo: date })
    },
    [searchParams]
  )

  const handleChangeContractPeriodEndFrom = useCallback(
    (date: MaterialUiPickersDate) => {
      setSearchParams({ ...searchParams, contractPeriodEndFrom: date })
    },
    [searchParams]
  )

  const handleChangeContractPeriodEndTo = useCallback(
    (date: MaterialUiPickersDate) => {
      setSearchParams({ ...searchParams, contractPeriodEndTo: date })
    },
    [searchParams]
  )

  const handleChangePolicyNumberId = useCallback(
    (e) => setSearchParams({ ...searchParams, policyNumberId: e.target.value }),
    [searchParams]
  )

  const handleChangePlatformId = useCallback(
    (e) => setSearchParams({ ...searchParams, platformId: e.target.value }),
    [searchParams]
  )

  const handleChangeContractorId = useCallback(
    (e) => setSearchParams({ ...searchParams, contractorId: e.target.value }),
    [searchParams]
  )

  const handleChangeInsuranceProduct = useCallback(
    (e) => setSearchParams({ ...searchParams, insuranceProduct: e.target.value }),
    [searchParams]
  )

  // const handleChangePageNumber = useCallback(
  //   (e, v) => {
  //     console.log(v)
  //     setSearchParams({ ...searchParams, pageNumber: v })
  //     handleSearch()
  //   },
  //   [searchParams]
  // )
  const handleChangePageNumber = (e: React.ChangeEvent<unknown>, v: number) => {
    console.log(v)
    console.log(insurancesSelector.nObject / searchParams.perPage)
    setSearchParams({ ...searchParams, pageNumber: v })
  }

  // TODO:契約期間変更作成時に修正
  // const handleChangeContractPeriodStart = (id: string, date: string) => {
  //   dispatch(InsurancesModules.actions.updateInsuranceContractStart({ id, date }))
  // }

  // const handleChangeContractPeriodEnd = (id: string, date: string) => {
  //   dispatch(InsurancesModules.actions.updateInsuranceContractEnd({ id, date }))
  // }

  const handleSearch = (pageNumber?: number) => {
    if (pageNumber) {
      if (searchParams.pageNumber === pageNumber) handleSearch()
      else setSearchParams({ ...searchParams, pageNumber: pageNumber })
    } else dispatch(fetchFindInsurances(searchParams))
  }

  const moveDetailPage = (insuranceId: string) => () => {
    dispatch(push(`/insurance/${insuranceId}`))
  }

  const changeOrder = (key: string, value?: string) =>
    setSearchParams({
      ...searchParams,
      sortOrderParams: {
        [key]: !value ? 'desc' : value === 'asc' ? 'desc' : 'asc',
      },
    })

  const setAllow = (paramName: keyof SortOrderParams) => {
    if (searchParams.sortOrderParams[paramName] === 'asc')
      return (
        <div>
          <ArrowDropUp fontSize="small" />
        </div>
      )
    else if (searchParams.sortOrderParams[paramName] === 'desc')
      return (
        <div>
          <ArrowDropDown fontSize="small" />
        </div>
      )
    else return
  }

  const checkedWithPagination = (insuranceId: number): boolean => {
    const insuranceIdWithPagination =
      ((insuranceId - 1) % searchParams.perPage) +
      1 +
      searchParams.perPage * (searchParams.pageNumber - 1)
    return checkedInsurances.includes(insuranceIdWithPagination)
  }

  // const handleUpdateInsurancePeriod = (
  //   insuranceId: string,
  //   contractPeriodStart: string,
  //   contractPeriodEnd: string
  // ) => () => {
  //   dispatch(
  //     fetchUpdateInsurancePeriod(insuranceId, {
  //       contractPeriodStart,
  //       contractPeriodEnd,
  //     })
  //   )
  // }

  useLayoutEffect(() => {
    dispatch(InsurancesModules.actions.resetError())
    dispatch(InsurancesModules.actions.resetInsurancesList())
    dispatch(PolicyNumbersModules.actions.resetError())
    dispatch(PlatformModules.actions.resetError())
  }, [dispatch])

  useEffect(() => {
    insuranceSelector.isChangeSuccess && setSuccessSnackBarOpen(true)
  }, [insuranceSelector.isChangeSuccess])

  useEffect(() => {
    insuranceSelector.error && insuranceSelector.error.message && setErrorSnackBarOpen(true)
  }, [insuranceSelector.error])

  // チェックされるたびに一括変更ボタンの確認を行う
  useEffect(() => {
    if (checkedInsurances.length !== 0) {
      setChangeButtonDisable(false)
    } else {
      setChangeButtonDisable(true)
    }
  }, [checkedInsurances])

  useLayoutEffect(() => {
    handleSearch()
  }, [searchParams.pageNumber, searchParams.sortOrderParams])

  useEffect(() => {
    setNPage(
      Math.floor(insurancesSelector.nObject / searchParams.perPage) +
        (insurancesSelector.nObject % searchParams.perPage ? 1 : 0)
    )
  })

  return (
    <div data-page="insurances">
      <Container maxWidth="lg" className={styles.root} fixed>
        <Grid container>
          <Grid item xs={12}>
            <Title title="加入者照会" isCenter={true} />
          </Grid>
        </Grid>
        <Grid container className={styles.search}>
          <Grid item xs={12}>
            <Card variant="outlined" className={styles.contents}>
              <CardContent className={styles.column}>
                <TextField
                  label="法人名"
                  value={searchParams.companyName}
                  onChange={handleChangeCompanyName}
                  data-section="contractor-name"
                />
                <FormControl className={styles.formControl}>
                  <InputLabel>証券番号</InputLabel>
                  <Select
                    label="証券番号"
                    value={searchParams.policyNumberId ? searchParams.policyNumberId : ''}
                    onChange={handleChangePolicyNumberId}
                    data-section="policy-number-id"
                  >
                    <MenuItem value="">
                      <em>選択してください</em>
                    </MenuItem>
                    {PolicyNumbersSelector.policyNumbers.map((data, i) => {
                      return (
                        <MenuItem key={i} value={data.id}>
                          {data.policy_number}({data.year}年{data.month}月)
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <InputLabel>プラットフォーム</InputLabel>
                  <Select
                    label="プラットフォーム"
                    value={
                      loginSelector.platform_id
                        ? loginSelector.platform_id
                        : searchParams.platformId
                        ? searchParams.platformId
                        : ''
                    }
                    onChange={handleChangePlatformId}
                    data-section="platform-id"
                    disabled={
                      loginSelector.cognitoGroupName !==
                      CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                    }
                  >
                    <MenuItem key={0} value={0}>
                      選択してください
                    </MenuItem>
                    {PlatformsSelector.platforms.map((data) => {
                      return (
                        <MenuItem key={data.id} value={data.id}>
                          {data.platform_name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <TextField
                  label="加入者番号"
                  value={searchParams.contractorId}
                  onChange={handleChangeContractorId}
                  data-section="contractor-id"
                />
                <FormControl className={styles.formControl}>
                  <InputLabel>保険商品</InputLabel>
                  <Select
                    label="保険商品"
                    value={searchParams.insuranceProduct ? searchParams.insuranceProduct : ''}
                    onChange={handleChangeInsuranceProduct}
                    data-section="insurance-product"
                  >
                    <MenuItem value="">
                      <em>選択してください</em>
                    </MenuItem>
                    <MenuItem value={InsuranceProductEnum.LITE}>
                      {INSURANCE_PRODUCT_NAME[InsuranceProductEnum.LITE]}
                    </MenuItem>
                    <MenuItem value={InsuranceProductEnum.STANDARD}>
                      {INSURANCE_PRODUCT_NAME[InsuranceProductEnum.STANDARD]}
                    </MenuItem>
                    <MenuItem value={InsuranceProductEnum.PREMIUM}>
                      {INSURANCE_PRODUCT_NAME[InsuranceProductEnum.PREMIUM]}
                    </MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
              <CardContent className={styles.column}>
                <Typography className={styles.label}>契約期間開始</Typography>
                <KeyboardDatePicker
                  variant="inline"
                  format="yyyy-MM-dd"
                  label="契約期間開始FROM"
                  value={searchParams.contractPeriodStartFrom}
                  onChange={handleChangeContractPeriodStartFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                  autoOk
                  data-section="contract-period-start-from"
                />
                <KeyboardDatePicker
                  variant="inline"
                  format="yyyy-MM-dd"
                  label="契約期間開始TO"
                  value={searchParams.contractPeriodStartTo}
                  onChange={handleChangeContractPeriodStartTo}
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                  autoOk
                  data-section="contract-period-start-to"
                />
              </CardContent>
              <CardContent className={styles.column}>
                <Typography className={styles.label}>契約期間終了</Typography>
                <KeyboardDatePicker
                  variant="inline"
                  format="yyyy-MM-dd"
                  label="契約期間終了FROM"
                  value={searchParams.contractPeriodEndFrom}
                  onChange={handleChangeContractPeriodEndFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                  autoOk
                  data-section="contract-period-end-from"
                />
                <KeyboardDatePicker
                  variant="inline"
                  format="yyyy-MM-dd"
                  label="契約期間終了TO"
                  value={searchParams.contractPeriodEndTo}
                  onChange={handleChangeContractPeriodEndTo}
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                  autoOk
                  data-section="contract-period-end-to"
                />
              </CardContent>
              <CardContent className={styles.control}>
                <Button
                  className={styles.changeButton}
                  onClick={handleOpenModal}
                  color="secondary"
                  variant="contained"
                  data-section="change-button"
                  disabled={changeButtonDisable}
                >
                  一括変更
                </Button>
                <Button
                  className={styles.searchButton}
                  onClick={() => handleSearch(1)}
                  color="primary"
                  variant="contained"
                  data-section="search-button"
                >
                  検索
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {insurancesSelector.error ? (
          <div data-section="api-error">
            <ApiError
              error={insurancesSelector.error?.error}
              message={insurancesSelector.error?.message}
              status={insurancesSelector.error?.status}
            />
          </div>
        ) : insurancesSelector.isLoading ? (
          <Grid container>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.tableTitle}>一括変更</TableCell>
                      <TableCell
                        className={styles.tableTitle}
                        onClick={() =>
                          changeOrder('memberId', searchParams.sortOrderParams.memberId)
                        }
                      >
                        会員番号
                        {setAllow('memberId')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('contractorId', searchParams.sortOrderParams.contractorId)
                        }
                        className={styles.tableTitle}
                      >
                        加入者番号
                        {setAllow('contractorId')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('policyNumber', searchParams.sortOrderParams.policyNumber)
                        }
                        className={styles.tableTitle}
                      >
                        証券番号
                        {setAllow('policyNumber')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('platformName', searchParams.sortOrderParams.platformName)
                        }
                        className={styles.tableTitle}
                      >
                        プラットフォーム
                        {setAllow('platformName')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder(
                            'insuranceProduct',
                            searchParams.sortOrderParams.insuranceProduct
                          )
                        }
                        className={styles.tableTitle}
                      >
                        保険商品
                        {setAllow('insuranceProduct')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder(
                            'applicationDate',
                            searchParams.sortOrderParams.applicationDate
                          )
                        }
                        className={styles.tableTitle}
                      >
                        申込日
                        {setAllow('applicationDate')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder(
                            'contractPeriodStart',
                            searchParams.sortOrderParams.contractPeriodStart
                          )
                        }
                        className={styles.tableTitle}
                      >
                        契約期間開始
                        {setAllow('contractPeriodStart')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder(
                            'contractPeriodEnd',
                            searchParams.sortOrderParams.contractPeriodEnd
                          )
                        }
                        className={styles.tableTitle}
                      >
                        契約期間終了
                        {setAllow('contractPeriodEnd')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('cancelledAt', searchParams.sortOrderParams.cancelledAt)
                        }
                        className={styles.tableTitle}
                      >
                        解約日
                        {setAllow('cancelledAt')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('companyName', searchParams.sortOrderParams.companyName)
                        }
                        className={styles.tableTitle}
                      >
                        法人名
                        {setAllow('companyName')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('contractState', searchParams.sortOrderParams.contractState)
                        }
                        className={styles.tableTitle}
                      >
                        契約状態
                        {setAllow('contractState')}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeOrder('paymentState', searchParams.sortOrderParams.paymentState)
                        }
                        className={styles.tableTitle}
                      >
                        決済状態
                        {setAllow('paymentState')}
                      </TableCell>
                      <TableCell>操作</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {insurancesSelector.insurancesList.length === 0 ? (
                      <TableRow>
                        <TableCell align="center" colSpan={7} data-section="noItems">
                          契約商品がありません
                        </TableCell>
                      </TableRow>
                    ) : (
                      <React.Fragment>
                        {insurancesSelector.insurancesList.map((data, i) => {
                          return (
                            <TableRow key={i} data-section={`row${i}`}>
                              <TableCell data-section="check_change">
                                <Checkbox
                                  onChange={() => handleOnCheck(parseInt(data.id))}
                                  checked={checkedWithPagination(parseInt(data.id))}
                                />
                              </TableCell>
                              <TableCell data-section="member_id">{data.member_id}</TableCell>
                              <TableCell data-section="contractor_id">
                                {data.contractor_id}
                              </TableCell>
                              {data.policy_number ? (
                                <TableCell data-section="policy_number">
                                  {data.policy_number.policy_number}
                                </TableCell>
                              ) : (
                                <TableCell data-section="policy_number">
                                  証券番号が未割り当てです
                                </TableCell>
                              )}
                              <TableCell data-section="platform">{data.platform_name}</TableCell>
                              <TableCell data-section="insurance-products">
                                {data.insurance_product === InsuranceProductEnum.LITE
                                  ? INSURANCE_PRODUCT_NAME[InsuranceProductEnum.LITE]
                                  : data.insurance_product === InsuranceProductEnum.STANDARD
                                  ? INSURANCE_PRODUCT_NAME[InsuranceProductEnum.STANDARD]
                                  : INSURANCE_PRODUCT_NAME[InsuranceProductEnum.PREMIUM]}
                              </TableCell>
                              <TableCell data-section="application_date">
                                {data.application_date}
                              </TableCell>
                              <TableCell data-section="contract_period_start">
                                {data.contract_period_start}
                              </TableCell>
                              <TableCell data-section="contract_period_end">
                                {data.contract_period_end}
                              </TableCell>
                              <TableCell data-section="cancelled_at">{data.cancelled_at}</TableCell>
                              <TableCell data-section="company_name">{data.company_name}</TableCell>
                              <TableCell data-section="contractState">
                                {data.contract_state}
                              </TableCell>
                              <TableCell data-section="payment_state">
                                {data.payment_state}
                              </TableCell>
                              <TableCell data-section="operation">
                                <Box className={styles.operationCell}>
                                  <Button
                                    onClick={moveDetailPage(data.id)}
                                    color="primary"
                                    variant="contained"
                                  >
                                    契約詳細
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        <IsSalesModal
          open={modalOpen}
          onClose={handleCloseModal}
          disableButton={setChangeButtonDisable}
          insurance_ids={checkedInsurances}
          setInsurances={setCheckedInsurances}
        />
        <Pagination
          count={nPage}
          color="primary"
          onChange={handleChangePageNumber}
          page={searchParams.pageNumber}
        />
        <Snackbar
          open={errorSnackBarOpen}
          autoHideDuration={6000}
          onClose={handleCloseErrorSnackBar}
        >
          <Alert severity="error" onClose={handleCloseErrorSnackBar}>
            {insuranceSelector.error
              ? insuranceSelector.error?.message
              : 'サーバーでエラーが起きました。'}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successSnackBarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackBar}
        >
          <Alert severity="success" onClose={handleCloseSuccessSnackBar}>
            変更に成功しました。
          </Alert>
        </Snackbar>
      </Container>
    </div>
  )
}

export default Insurances
