import { AxiosResponse } from 'axios'

import axios from '../../apis/axios'
import {
  FindPlatformResponse,
  PostPlatformRequestParams,
  PutPlatformRequestParams,
} from '../../types/platform'

/*
 * 契約情報取得
 */

export const callFindPlatform = async (platformId: string) => {
  const { data }: AxiosResponse<FindPlatformResponse> = await axios.get(`/platform/${platformId}`)
  return data.platform
}

/*
 * プラットフォーム情報登録
 */

export const callPostPlatformInfo = async (params: PostPlatformRequestParams) => {
  await axios.post('/platform', {
    params: params,
  })
}

/*
 * プラットフォーム更新
 */

export const callPutPlatformInfo = async (
  insuranceId: string,
  params: PutPlatformRequestParams
) => {
  await axios.put(`/platform/${insuranceId}`, {
    params: params,
  })
}
