export enum ChallengeNameEnum {
  // パスワード再設定
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  // SMS認証
  SMS_MFA = 'SMS_MFA',
}

// Cognitoのグループ名
export enum CognitoGroupNameEnum {
  // 管理者権限
  COGNITO_ADMIN_GROUP_NAME = 'admin',
  //  営業店/代理店/保サ権限（自店舗限定）
  COGNITO_AGENCY_GROUP_NAME = 'agency',
}
