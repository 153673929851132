import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { callGetPlatforms } from 'utils/apis/platforms'
import { callTruncateTable } from 'utils/apis/truncateTable'

export interface Platform {
  id: number
  platform_name: string
}

export interface PlatformsState {
  isLoading: boolean
  platforms: Platform[]
  error?: ErrorResponse
}

export const platformsState: PlatformsState = {
  isLoading: false,
  platforms: [],
}

export interface GetPlatformsResponse {
  platforms: Platform[]
}
export interface PutPlatformRequestParams {
  platformId: string
}

const PlatformsModules = createSlice({
  name: 'platforms',
  initialState: platformsState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetPlatformsSuccess: (state, action: PayloadAction<GetPlatformsResponse>) => ({
      ...state,
      isLoading: false,
      platforms: action.payload.platforms,
    }),
    callTruncatePlatformSuccess: (state) => ({
      ...state,
      isLoading: false,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
  },
})

export const fetchGetPlatforms = (): AppThunk => async (dispatch) => {
  try {
    dispatch(PlatformsModules.actions.resetError())
    dispatch(PlatformsModules.actions.callApiStart())
    const res = await callGetPlatforms()
    dispatch(PlatformsModules.actions.callGetPlatformsSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetPlatforms error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      PlatformsModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchTruncatePlatform = (): AppThunk => async (dispatch) => {
  try {
    dispatch(PlatformsModules.actions.resetError())
    dispatch(PlatformsModules.actions.callApiStart())
    await callTruncateTable('platforms')
    dispatch(PlatformsModules.actions.callTruncatePlatformSuccess())

    dispatch(fetchGetPlatforms())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetEmailTexts error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      PlatformsModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default PlatformsModules
