import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import { fetchGetPlatforms } from '../../store/platforms'
import PolicyNumberModules, {
  fetchGetPolicyNumber,
  fetchPostPolicyNumberInfo,
  fetchPutPolicyNumberInfo,
} from '../../store/policyNumber'
import { selectLogin, selectPlatforms, selectPolicyNumber } from '../../store/selector'
import { CognitoGroupNameEnum } from '../../types/login'
import {
  PostPolicyNumberRequestParams,
  PutPolicyNumberRequestParams,
} from '../../utils/apis/policyNumber'
import {
  changePolicyNumberInfoSchema,
  getErrorAsObject,
  registerPolicyNumberInfoSchema,
} from '../../utils/validate'
import styles from './styles.module.scss'

const PolicyNumbersInput = () => {
  const loginSelector = useSelector(selectLogin)
  const dispatch = useDispatch()
  const { policyNumberId } = useParams<{ policyNumberId: string }>()
  // 証券番号
  const [policyNumber, setPolicyNumber] = useState('')
  // プラットフォームID
  const [platformId, setPlatformId] = useState(1)
  // 登録(年)
  const [year, setYear] = useState('')
  // 登録(月)
  const [month, setMonth] = useState('')

  const policyNumberSelector = useSelector(selectPolicyNumber)
  const platformsSelector = useSelector(selectPlatforms)

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PutPolicyNumberRequestParams]?: string
    }
  >({})

  // 新規作成フラグ
  const location = useLocation().pathname
  const newFlag = location.match('/new') ? 1 : 0

  useEffect(() => {
    if (policyNumberId) {
      dispatch(fetchGetPolicyNumber(policyNumberId))
      setPlatformId(Number(policyNumberSelector.policyNumber?.platform_id))
    }
    dispatch(fetchGetPlatforms())
  }, [dispatch])

  // 証券番号変更成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (policyNumberSelector.isChangeSuccess) {
      setTimeout(() => {
        dispatch(PolicyNumberModules.actions.resetIsChangeSuccess())
      }, 5000)
    }
  }, [policyNumberSelector.isChangeSuccess])

  // 証券番号登録成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (policyNumberSelector.isRegistSuccess) {
      setTimeout(() => {
        dispatch(PolicyNumberModules.actions.resetIsRegistSuccess())
      }, 5000)
    }
  }, [policyNumberSelector.isRegistSuccess])

  // 取得した後にパラメータを設定
  useEffect(() => {
    if (!newFlag && policyNumberSelector) {
      // 証券番号
      setPolicyNumber(policyNumberSelector?.policyNumber?.policy_number || '')
      setPlatformId(policyNumberSelector?.policyNumber?.platform_id || 1)
      setYear(policyNumberSelector?.policyNumber?.year || '')
      setMonth(policyNumberSelector?.policyNumber?.month || '')
    }
  }, [newFlag, policyNumberSelector.policyNumber])

  const moveListPage = () => {
    // 画面遷移時にはstoreの証券番号をリセット
    dispatch(PolicyNumberModules.actions.resetPolicyNumber())
    dispatch(push({ pathname: '/policy_numbers' }))
  }

  const getPostParams = (): PostPolicyNumberRequestParams => {
    return {
      policy_number: policyNumber,
      year: year,
      month: ('00' + month).slice(-2),
      platform_id: platformId,
    }
  }

  const getPutParams = (): PutPolicyNumberRequestParams | boolean => {
    if (!policyNumberSelector.policyNumber) {
      return false
    }
    return {
      policy_number: policyNumber,
      year: year,
      month: ('00' + month).slice(-2),
      platform_id: platformId,
    }
  }

  // 登録ボタン
  const handlePostSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPostCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPostParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPostPolicyNumberInfo(params))
    }
  }

  // 更新ボタン
  const handlePutSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPutCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPutParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPutPolicyNumberInfo(policyNumberId, params))
    }
  }

  // 登録ボタン実行時のバリデーション
  const validationPostCheck = async (): Promise<boolean> => {
    const params = getPostParams()
    console.log(params)
    try {
      await registerPolicyNumberInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  // 更新ボタン実行時のバリデーション
  const validationPutCheck = async (): Promise<boolean> => {
    const params = getPutParams()
    try {
      await changePolicyNumberInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  return (
    <div data-page="policy-number-input">
      <Container className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            {newFlag ? <Title title="証券番号登録" /> : <Title title="証券番号編集" />}
          </Grid>
        </Grid>
        <Box>
          {policyNumberSelector.error && (
            <div data-section="api-error">
              <ApiError
                error={policyNumberSelector.error.error}
                message={policyNumberSelector.error.message}
                status={policyNumberSelector.error.status}
              />
            </div>
          )}
          {policyNumberSelector.isLoading && <CircularProgress />}
          {!newFlag && !policyNumberSelector.policyNumber ? (
            <p data-section="no-items">証券番号情報が見つかりません</p>
          ) : (
            <Grid container>
              {policyNumberSelector.isChangeSuccess && (
                <Grid container>
                  <Grid item xs={12} className={styles.success}>
                    <p data-section="complete">
                      <CheckCircleIcon />
                      <span>変更が完了しました。</span>
                    </p>
                  </Grid>
                </Grid>
              )}
              {policyNumberSelector.isRegistSuccess && (
                <Grid container>
                  <Grid item xs={12} className={styles.success}>
                    <p data-section="complete">
                      <CheckCircleIcon />
                      <span>登録が完了しました。</span>
                    </p>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <form>
                  <Box className={styles.inputWrap}>
                    <TextField
                      label="証券番号"
                      type="text"
                      data-section="policy_number"
                      variant="outlined"
                      inputProps={{ maxLength: 13 }}
                      value={policyNumber}
                      onChange={(e) => setPolicyNumber(e.target.value)}
                      error={!!errors.policy_number}
                      helperText={errors.policy_number}
                    />
                  </Box>
                  <Box className={styles.inputWrap}>
                    <TextField
                      label="登録(年)"
                      type="text"
                      data-section="year"
                      variant="outlined"
                      inputProps={{ maxLength: 4 }}
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      error={!!errors.year}
                      helperText={errors.year}
                    />
                  </Box>
                  <Box className={styles.inputWrap}>
                    <TextField
                      label="登録(月)"
                      type="text"
                      data-section="month"
                      variant="outlined"
                      inputProps={{ maxLength: 2 }}
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      error={!!errors.month}
                      helperText={errors.month}
                    />
                  </Box>
                  <Box className={styles.inputWrap}>
                    <InputLabel>プラットフォーム</InputLabel>
                    <Select
                      label="プラットフォーム"
                      labelId="platform-id-label"
                      id="platform-id-select"
                      value={platformId}
                      disabled={
                        newFlag &&
                        loginSelector.cognitoGroupName ===
                          CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME
                          ? false
                          : false
                      }
                      onChange={(e) => setPlatformId(Number(e.target.value))}
                    >
                      {platformsSelector.platforms.map((data, i) => {
                        return (
                          <MenuItem key={i} value={data.id}>
                            {data.platform_name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </Box>
                </form>
              </Grid>
            </Grid>
          )}
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button onClick={() => moveListPage()} variant="contained" data-section="back">
                戻る
              </Button>
              {/* 契約情報が見つからなかった場合は非表示 */}
              {newFlag ? (
                <Button
                  onClick={() => {
                    handlePostSubmit()
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  value="post"
                  data-section="register"
                >
                  登録する
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    handlePutSubmit()
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  value="post"
                  data-section="register"
                >
                  更新する
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default PolicyNumbersInput
