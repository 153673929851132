import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Title from '../../components/Title'
import { useLocation } from 'react-router-dom'

export interface LocationState {
  errorMessage: string
}

const Error = () => {
  const location = useLocation()
  const { errorMessage } = location.state
    ? (location.state as LocationState)
    : { errorMessage: 'エラーが発生しました' }

  return (
    <div data-page="error">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <Title title={errorMessage} isCenter={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Error
