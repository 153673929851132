import React, { ChangeEvent, SyntheticEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, Container, Box, TextField, Button, CircularProgress, Grid } from '@material-ui/core'

import { fetchPostAuthChallengeSms } from 'store/login'
import { getErrorAsObject, authChallengeSmsSchema } from 'utils/validate'
import ApiError from 'components/ApiError'
import styles from './styles.module.scss'
import Title from '../../components/Title'
import { selectLogin } from '../../store/selector'

export interface AuthChallengeSmsState {
  confirmation_code: string
}

const AuthChallengeSms = () => {
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const [errors, setErrors] = useState<{ [key in keyof AuthChallengeSmsState]?: string }>({})
  const [inputAuthChallengeSms, setInputAuthChallengeSms] = useState<AuthChallengeSmsState>({
    confirmation_code: '',
  })

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      setErrors({
        ...errors,
        [key]: '',
      })
      setInputAuthChallengeSms({
        ...inputAuthChallengeSms,
        [key]: event.target.value,
      })
    },
    [inputAuthChallengeSms, errors]
  )

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    try {
      await authChallengeSmsSchema.validate(inputAuthChallengeSms, { abortEarly: false })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      setErrors(getErrorAsObject(err))
      return
    }
    dispatch(fetchPostAuthChallengeSms(inputAuthChallengeSms.confirmation_code))
  }

  return (
    <div data-page="auth_challenge_sms">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="SMS認証" isCenter={true} />
            <p className={styles.text}>SMSに送信された検証コードを入力してください。</p>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSubmit}>
          <div data-section="api-error">
            {loginSelector.error && (
              <ApiError
                error={loginSelector.error.error}
                message={loginSelector.error.message}
                status={loginSelector.error.status}
              />
            )}
          </div>
          {loginSelector.isLoading ? <CircularProgress /> : null}
          <Container className={styles.forms} maxWidth="sm" component={Paper} variant="outlined">
            <TextField
              fullWidth
              label="検証コード"
              margin="normal"
              value={inputAuthChallengeSms.confirmation_code}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleTextFieldChange(e, 'confirmation_code')
              }}
              error={Boolean(errors.confirmation_code)}
              helperText={errors.confirmation_code}
              data-section="confirmation_code"
            />
            <div className={styles.buttonWrap}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loginSelector.isLoading}
                data-section="loginButton"
              >
                認証を実行する
              </Button>
            </div>
          </Container>
        </Box>
      </Container>
    </div>
  )
}

export default AuthChallengeSms
