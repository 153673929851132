import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ErrorResponse } from './error'
import { QuestionAnswer } from '../types/questionAnswer'
import { callGetQuestionAnswers, GetQuestionAnswersResponse } from '../utils/apis/questionAnswers'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'

export interface QuestionAnswersState {
  isLoading: boolean
  question_answers: QuestionAnswer[]
  error?: ErrorResponse
}

const initialState: QuestionAnswersState = {
  isLoading: false,
  question_answers: [],
}

const QuestionAnswersModules = createSlice({
  name: 'questionAnswers',
  initialState: initialState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetQuestionAnswersSuccess: (state, action: PayloadAction<GetQuestionAnswersResponse>) => ({
      ...state,
      isLoading: false,
      ...action.payload,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
  },
})

export const fetchGetQuestionAnswers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(QuestionAnswersModules.actions.resetError())
    dispatch(QuestionAnswersModules.actions.callApiStart())
    const res = await callGetQuestionAnswers()
    dispatch(QuestionAnswersModules.actions.callGetQuestionAnswersSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetQuestionAnswers error')
    console.log(e)
    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE

    dispatch(
      QuestionAnswersModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default QuestionAnswersModules
