import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Snackbar,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Alert } from '@material-ui/lab'
import { format, parse } from 'date-fns'
import { push } from 'connected-react-router'
import moment from 'moment'

import styles from './styles.module.scss'
import {
  DATE_FORMAT,
  INSURANCE_IS_SALES_NAME,
  INSURANCE_PRODUCT_NAME,
  ISO_YYYYMMDD,
} from '../../../const'
import { selectInsurance } from '../../../store/selector'
import InsuranceModules, {
  fetchFindInsurance,
  fetchPutCancelInsurance,
  fetchPutCancelResetInsurance,
} from '../../../store/insurance'
import { checkExpirationTime, checkOnTheDay } from '../../../utils/utils'
import { cancelInsuranceSchema, getErrorAsObject } from '../../../utils/validate'
import ApiError from '../../../components/ApiError'
import Title from '../../../components/Title'

export interface InsuranceCancelState {
  cancelled_apply_date: Date | null
}

const InsuranceCancel = () => {
  const dispatch = useDispatch()
  const insuranceSelector = useSelector(selectInsurance)
  const { insuranceId } = useParams<{ insuranceId: string }>()

  const [errors, setErrors] = useState<{ [key in keyof InsuranceCancelState]?: string }>({})
  const [inputInsurance, setInputInsurance] = useState<Partial<InsuranceCancelState>>({
    cancelled_apply_date: null,
  })

  const handleChangeDate = useCallback(
    (date: MaterialUiPickersDate, key: string) => {
      setErrors({
        ...errors,
        [key]: '',
      })
      setInputInsurance({ ...inputInsurance, [key]: date })
    },
    [inputInsurance]
  )

  const moveDetailPage = () => {
    dispatch(push(`/insurance/${insuranceId}`))
  }

  // 解約日の変更が行われているか確認
  const checkIsDiff = (): boolean => {
    // すでに解約済み
    if (insuranceSelector.insurance?.cancelled_at) {
      // 解約日inputが空
      if (!inputInsurance.cancelled_apply_date) {
        return true
      }
      // 解約日inputとDBの解約日が異なる
      if (
        format(inputInsurance.cancelled_apply_date, ISO_YYYYMMDD) !==
        insuranceSelector.insurance.cancelled_at
      ) {
        return true
      }
    } else {
      // 解約日inputに入力がある
      if (inputInsurance.cancelled_apply_date) {
        return true
      }
    }
    return false
  }

  // 解約日のバリデーション
  const validateCancelledApplyDate = (): boolean => {
    if (insuranceSelector.insurance && inputInsurance.cancelled_apply_date) {
      // 解約日が日付の形式になっていない
      if (!moment(inputInsurance.cancelled_apply_date).isValid()) {
        setErrors({
          ...errors,
          cancelled_apply_date: '解約日を正しく入力してください。',
        })
        return false
      }

      const cancelledApplyDate = inputInsurance.cancelled_apply_date.valueOf()
      const applicationDate = parse(
        insuranceSelector.insurance.application_date,
        ISO_YYYYMMDD,
        new Date()
      ).valueOf()
      const periodDate = parse(
        insuranceSelector.insurance.contract_period_end,
        ISO_YYYYMMDD,
        new Date()
      ).valueOf()

      // 解約日が申込日よりも前になっている
      if (cancelledApplyDate < applicationDate) {
        setErrors({
          ...errors,
          cancelled_apply_date: '解約日は申込日よりも前の日付に設定することはできません。',
        })
        return false
      }

      // 解約日が契約終了日よりも後になっている
      if (cancelledApplyDate > periodDate) {
        setErrors({
          ...errors,
          cancelled_apply_date: '解約日は契約終了日よりも後の日付に設定することはできません。',
        })
        return false
      }
    }
    setErrors({})
    return true
  }

  // 解約情報の編集
  const handleEdit = async () => {
    try {
      // 不要な履歴を残さないため項目に変更がない場合はreturn
      if (!checkIsDiff()) {
        window.alert('変更された項目はありません')
        return
      }

      // 解約日のバリデーション
      if (!validateCancelledApplyDate()) {
        return
      }

      await cancelInsuranceSchema.validate(inputInsurance, { abortEarly: false })
      dispatch(
        fetchPutCancelInsurance(insuranceId, {
          cancelled_apply_date: format(inputInsurance.cancelled_apply_date as Date, ISO_YYYYMMDD),
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      setErrors(getErrorAsObject(err))
    }
  }

  // 解約の取り消し
  const handleReset = () => {
    // 解約日のバリデーションエラーが残るのでリセット
    setErrors({})

    dispatch(fetchPutCancelResetInsurance(insuranceId))
  }

  useLayoutEffect(() => {
    dispatch(InsuranceModules.actions.resetAll())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchFindInsurance(insuranceId))
  }, [dispatch])

  useEffect(() => {
    if (insuranceSelector.insurance) {
      setInputInsurance({
        cancelled_apply_date: insuranceSelector.insurance.cancelled_at
          ? parse(insuranceSelector.insurance.cancelled_at, ISO_YYYYMMDD, new Date())
          : null,
      })
    }
  }, [insuranceSelector.insurance])

  // 解約成功フラグのリセット
  useEffect(() => {
    if (insuranceSelector.isCancelSuccess) {
      setTimeout(() => {
        dispatch(InsuranceModules.actions.resetIsCancelSuccess())
      }, 5000)
    }
  }, [insuranceSelector.isCancelSuccess])

  // 解約取り消し成功フラグのリセット
  useEffect(() => {
    if (insuranceSelector.isCancelResetSuccess) {
      setTimeout(() => {
        dispatch(InsuranceModules.actions.resetIsCancelResetSuccess())
      }, 5000)
    }
  }, [insuranceSelector.isCancelResetSuccess])

  return (
    <div data-page="cancel">
      {(insuranceSelector.isCancelSuccess || insuranceSelector.isCancelResetSuccess) && (
        <div>
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success">
              {insuranceSelector.isCancelSuccess
                ? '保険契約の解約に成功しました'
                : '保険契約の解約取り消しに成功しました'}
            </Alert>
          </Snackbar>
        </div>
      )}
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="保険商品解約処理画面" />
          </Grid>
        </Grid>
        {insuranceSelector.error ? (
          <div data-section="api-error">
            <ApiError
              error={insuranceSelector.error.error}
              message={insuranceSelector.error.message}
              status={insuranceSelector.error.status}
            />
          </div>
        ) : insuranceSelector.isLoading ? (
          <CircularProgress />
        ) : !insuranceSelector.insurance ? (
          <p data-section="no-items">保険商品情報が見つかりません。</p>
        ) : (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <Card variant="outlined" className={styles.insuranceInfo}>
                  <CardContent>
                    <Typography variant="subtitle2" data-section="state">
                      契約状態:{' '}
                      {insuranceSelector.insurance.deleted_at
                        ? '削除済'
                        : (insuranceSelector.insurance.cancelled_at &&
                            checkExpirationTime(insuranceSelector.insurance.cancelled_at)) ||
                          (insuranceSelector.insurance.cancelled_at &&
                            checkOnTheDay(
                              insuranceSelector.insurance.application_date,
                              insuranceSelector.insurance.cancelled_at
                            ))
                        ? '解約済'
                        : checkExpirationTime(insuranceSelector.insurance.contract_period_end)
                        ? '契約満了'
                        : insuranceSelector.insurance.cancelled_at
                        ? '解約申込中'
                        : '契約中'}
                    </Typography>
                    <Typography variant="subtitle2" data-section="contractor-id">
                      加入者番号: {insuranceSelector.insurance.contractor_id}
                    </Typography>
                    <Typography variant="subtitle2" data-section="contractor-name">
                      氏名(漢字): {insuranceSelector.insurance.contractor_name}
                    </Typography>
                    <Typography variant="subtitle2" data-section="shop-origin">
                      決済状態:{INSURANCE_IS_SALES_NAME[insuranceSelector.insurance.is_sales]}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2" data-section="policy-number">
                      証券番号: {insuranceSelector.insurance.policy_number.policy_number}
                    </Typography>
                    <Typography variant="subtitle2" data-section="insurance-product">
                      保険商品名:
                      {INSURANCE_PRODUCT_NAME[insuranceSelector.insurance.insurance_product]}
                    </Typography>
                    <Typography variant="subtitle2" data-section="contract-period-start">
                      契約期間開始:{' '}
                      {format(
                        new Date(insuranceSelector.insurance.contract_period_start),
                        DATE_FORMAT
                      )}
                    </Typography>
                    <Typography variant="subtitle2" data-section="contract-period-end">
                      契約期間終了:{' '}
                      {format(
                        new Date(insuranceSelector.insurance.contract_period_end),
                        DATE_FORMAT
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={styles.forms}>
                <Typography variant="h6">解約情報の編集</Typography>
                <KeyboardDatePicker
                  variant="inline"
                  format="yyyy-MM-dd"
                  minDate={parse(
                    insuranceSelector.insurance.application_date,
                    ISO_YYYYMMDD,
                    new Date()
                  )}
                  maxDate={parse(
                    insuranceSelector.insurance.contract_period_end,
                    ISO_YYYYMMDD,
                    new Date()
                  )}
                  label="解約日"
                  value={inputInsurance.cancelled_apply_date}
                  onChange={(date: MaterialUiPickersDate) => {
                    // 入力フォームに手入力すると日付の入力が終わるまで date = "Invalid Date" となっている
                    if (moment(date).isValid()) {
                      handleChangeDate(date, 'cancelled_apply_date')
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value && !moment(e.target.value).isValid()) {
                      setErrors({
                        ...errors,
                        cancelled_apply_date: '解約日を正しく入力してください。',
                      })
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'cancel date',
                  }}
                  autoOk
                  error={Boolean(errors.cancelled_apply_date)}
                  helperText={errors.cancelled_apply_date}
                  // 満期、削除済みの場合操作をできないように
                  disabled={
                    !!insuranceSelector.insurance.deleted_at ||
                    checkExpirationTime(insuranceSelector.insurance.contract_period_end)
                  }
                  data-section="cancelled-apply-date"
                />
                {inputInsurance.cancelled_apply_date &&
                  format(inputInsurance.cancelled_apply_date as Date, ISO_YYYYMMDD) !==
                    insuranceSelector.insurance.cancelled_at && (
                    <small data-section="prev-cancelled-at">
                      変更前解約日：{insuranceSelector.insurance.cancelled_at}
                    </small>
                  )}

                {insuranceSelector.insurance.deleted_at ? (
                  <p>削除された保険商品のため操作はできません。</p>
                ) : checkExpirationTime(insuranceSelector.insurance.contract_period_end) ? (
                  <p>契約期間が満了している保険商品のため操作はできません。</p>
                ) : (
                  <Box className={styles.button}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}
                      data-section="update-cancellation"
                    >
                      解約情報の更新
                    </Button>
                    {insuranceSelector.insurance.cancelled_at && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReset}
                        data-section="cancel-cancellation"
                      >
                        解約の取り消し
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button
                onClick={moveDetailPage}
                color="primary"
                variant="contained"
                data-section="back"
              >
                保険商品詳細へ戻る
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default InsuranceCancel
