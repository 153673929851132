import { AxiosResponse } from 'axios'

import { InsuranceInterface, InsuranceIsSalesEnum } from '../../types/insurance'
import axios from '../../apis/axios'

/*
 * 契約情報取得
 */

export interface FindInsuranceResponse {
  insurance: InsuranceInterface
}

export const callFindInsurance = async (insuranceId: string) => {
  const { data }: AxiosResponse<FindInsuranceResponse> = await axios.get(
    `/insurance/${insuranceId}`
  )
  return data.insurance
}

/*
 * 契約情報更新
 */
export interface PutInsuranceRequestParams {
  sales_amount: number
  industry: string
  contractor_name: string
  company_name: string
  zip: string
  address: string
  manager_name: string
  phone: string
  email: string
  other_insurance_company: string
  other_insurance_type: string
  other_insurance_period: string
  policy_number_id: number
  is_sales: InsuranceIsSalesEnum
}

// 保険情報の変更
export const callPutInsuranceInfo = async (
  insuranceId: string,
  params: PutInsuranceRequestParams
) => {
  await axios.put(`/insurance/${insuranceId}`, {
    params: params,
  })
}

/*
 * 契約の解約・解約取り消し
 */
export interface InsuranceCancelRequestParams {
  cancelled_apply_date: string
}

// 解約
export const callPutCancelInsurance = async (
  insuranceId: string,
  params: InsuranceCancelRequestParams
) => {
  await axios.put(`/insurance/${insuranceId}/cancel`, {
    params: params,
  })
}

// 解約の取り消し
export const callPutCancelResetInsurance = async (insuranceId: string) => {
  await axios.put(`/insurance/${insuranceId}/cancel_reset`)
}

/*
 * 契約の削除・削除取り消し
 */

// 削除
export const callDeleteInsurance = async (insuranceId: string) => {
  await axios.delete(`/insurance/${insuranceId}`)
}

// 削除取り消し
export const callPutDeleteResetInsurance = async (insuranceId: string) => {
  await axios.put(`/insurance/${insuranceId}/undo_deletion`)
}

// TODO: 以下、必要になったら有効化・修正
// // 証券番号割り当て
// export const callPutInsurancePolicyNumber = async (insuranceId: string, policyNumberId: number) => {
//   await axios.put(`/insurance/${insuranceId}/policy_number`, {
//     params: { policy_number_id: policyNumberId },
//   })
// }
//
// export interface UpdateInsurancePeriodRequestParams {
//   contractPeriodStart: string
//   contractPeriodEnd: string
// }
//
// export const callUpdateInsurancePeriod = async (
//   id: string,
//   params: UpdateInsurancePeriodRequestParams
// ) => {
//   await axios.put(`/insurance/${id}/period`, {
//     params: params,
//   })
// }
