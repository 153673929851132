import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INTERNAL_SERVER_ERROR_MESSAGE, PERMISSION_ERROR_MESSAGE } from '../const'
import { push } from 'connected-react-router'
import { Agency } from '../types/agency'
import { callGetAgencies, callGetAgenciesByPlatformId } from '../utils/apis/agencies'
import { ErrorResponse } from './error'
import { AppThunk } from './index'

export interface AgenciesState {
  isLoading: boolean
  agencies: Agency[]
  error?: ErrorResponse
}

export const agenciesState: AgenciesState = {
  isLoading: false,
  agencies: [],
}

export interface PutSalesOfficeRequestParams {
  salesOfficeId: string
}

const AgenciesModules = createSlice({
  name: 'agencies',
  initialState: agenciesState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callGetAgenciesSuccess: (state, action: PayloadAction<Agency[]>) => ({
      ...state,
      isLoading: false,
      agencies: action.payload,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
})

export const fetchGetAgencies = (): AppThunk => async (dispatch) => {
  try {
    dispatch(AgenciesModules.actions.resetError())
    dispatch(AgenciesModules.actions.callApiStart())
    const res = await callGetAgencies()
    dispatch(AgenciesModules.actions.callGetAgenciesSuccess(res))
    dispatch(AgenciesModules.actions.resetIsLoading())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetAgencies error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      AgenciesModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export const fetchGetAgenciesByPlatformId = (platformId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(AgenciesModules.actions.resetError())
    dispatch(AgenciesModules.actions.callApiStart())
    const res = await callGetAgenciesByPlatformId(platformId)
    dispatch(AgenciesModules.actions.callGetAgenciesSuccess(res))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchGetAgencies error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    if (message == PERMISSION_ERROR_MESSAGE) {
      dispatch(push('/platforms'))
      dispatch(AgenciesModules.actions.resetError())
      dispatch(AgenciesModules.actions.resetIsLoading())
    } else {
      dispatch(
        AgenciesModules.actions.callApiError({
          status: status,
          error: error,
          message: message,
        })
      )
    }
  }
}

export default AgenciesModules
