import React, { useLayoutEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import { selectLogin, selectUser } from 'store/selector'
import { needsRefresh } from '../../utils/utils'
import { fetchPostUserRefreshToken } from '../../store/user'

interface AuthProps {
  children: React.ReactNode
}

const Auth = (props: AuthProps) => {
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const userSelector = useSelector(selectUser)

  // トークンの有効期限を確認して期限切れまたは期限切れが近かったら更新を行う
  useLayoutEffect(() => {
    // ローカル環境ではリフレッシュ行わない
    if (process.env.REACT_APP_ENV === 'local') {
      console.log('env local, refresh token disabled')
      return
    }
    // Cognito無効化の場合はリフレッシュ行わない
    if (process.env.REACT_APP_COGNITO_DISABLE_FLG === '1') {
      console.log('COGNITO_DISABLE_FLG=1, refresh token disabled')
      return
    }
    if (loginSelector.id_token && needsRefresh(loginSelector.id_token)) {
      dispatch(fetchPostUserRefreshToken())
    }
  }, [props, dispatch, loginSelector.id_token])

  return loginSelector.id_token || process.env.REACT_APP_COGNITO_DISABLE_FLG === '1' ? (
    <>{userSelector.isLoading ? <CircularProgress /> : props.children}</>
  ) : (
    <Redirect to={'/login'} />
  )
}

export default Auth
