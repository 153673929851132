import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import {
  AppBar,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { selectLogin } from 'store/selector'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import styles from './styles.module.scss'
import { ErrorResponse } from 'store/error'
import ApiError from '../../components/ApiError'
import { CognitoGroupNameEnum } from '../../types/login'

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '1rem',
    position: 'relative',
  },
  drawer: {
    flexShrink: 0,
    width: 600,
  },
  collapse: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar + 1,
  },
  elistItem: {
    marginLeft: '10%',
  },
}))

const Header = () => {
  const dispatch = useDispatch()
  const loginSelector = useSelector(selectLogin)
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [loading] = React.useState(false)
  const [error] = React.useState<ErrorResponse>()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const movePage = (path: string) => () => {
    if (open) {
      setOpen(!open)
    }
    if (mobileOpen) {
      setMobileOpen(!mobileOpen)
    }
    dispatch(push(path))
  }

  const moveList = [
    ['加入者照会', '/insurances'],
    ['証券番号一覧', '/policy_numbers'],
    ['プラットフォーム一覧', '/platforms'],
    ['CSVダウンロード', '/csv_download'],
    ['メール文書一覧', '/email_texts'],
    // appの方ではfaqをベタ書きにしている。機能復活する可能性もあるのでコメントアウト
    // ['質問回答一覧', '/question_answers'],
  ]

  // ハンバーガーボタン押下時のメニュー
  const drawer = (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6">メニュー</Typography>
        </Toolbar>
      </AppBar>
      <List>
        <ListItem className={styles.listItem} key={0}>
          <Button color="inherit" onClick={handleDrawerToggle}>
            <CloseIcon />
            閉じる
          </Button>
        </ListItem>
        {/* 管理者権限のみ表示 */}
        {loginSelector.cognitoGroupName === CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME && (
          <>
            <ListItem button onClick={movePage('/admin/create_user')}>
              <ListItemText primary="管理者用ユーザー作成" />
            </ListItem>
            <ListItem button onClick={movePage('/admin/users')}>
              <ListItemText primary="管理画面ユーザー一覧" />
            </ListItem>
          </>
        )}
        {moveList.map((list) => (
          <div key={list[0]}>
            <ListItem button onClick={movePage(list[1])}>
              <ListItemText primary={list[0]} />
            </ListItem>
          </div>
        ))}
      </List>
    </>
  )

  return (
    <>
      <AppBar position="relative" className={(classes.appBar, styles.root)}>
        <Toolbar variant="dense" className={styles.toolbar}>
          <div className={styles.hamburger}>
            <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </div>
          <Typography variant="h6">Esendアプリ管理</Typography>
          {/* メニューバー */}
        </Toolbar>
        {error ? (
          <ApiError error={error.error} message={error.message} status={error.status} />
        ) : loading ? (
          <CircularProgress />
        ) : null}
        <div className={styles.loginSelector}>
          {/* ログイン中に保持していた設定をリセットしたいので通常のリンクを使用して移動 */}
          <Link className={styles.link} href="/login" color="inherit">
            <img src="/logout.png" alt="" />
            <p>{loginSelector.id_token ? 'logout' : 'login'}</p>
          </Link>
        </div>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={classes.drawer}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default Header
