import axios from '../../apis/axios'

/*
 * 項目一覧CSVダウンロード情報取得
 */
export interface PostDownloadCsvRequestParams {
  from_date: Date
  to_date: Date
}

export const callPostInsuranceCsvDownload = async (params: PostDownloadCsvRequestParams) => {
  const { data } = await axios.post('/insurances/download', {
    responseType: 'arraybuffer',
    ...params,
  })
  return data
}
