import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE, PERMISSION_ERROR_MESSAGE } from '../const'
import { push } from 'connected-react-router'
import { InsuranceInterface } from '../types/insurance'
import {
  callDeleteInsurance,
  callFindInsurance,
  callPutCancelInsurance,
  callPutCancelResetInsurance,
  callPutDeleteResetInsurance,
  callPutInsuranceInfo,
  InsuranceCancelRequestParams,
  PutInsuranceRequestParams,
} from '../utils/apis/insurance'

export interface InsuranceState {
  isLoading: boolean
  isChangeSuccess: boolean
  isCancelSuccess: boolean
  isCancelResetSuccess: boolean
  isDeleteSuccess: boolean
  isDeleteResetSuccess: boolean
  insurance?: InsuranceInterface
  error?: ErrorResponse
}

export const insuranceState: InsuranceState = {
  isLoading: false,
  isChangeSuccess: false,
  isCancelSuccess: false,
  isCancelResetSuccess: false,
  isDeleteSuccess: false,
  isDeleteResetSuccess: false,
}

const InsuranceModules = createSlice({
  name: 'insurance',
  initialState: insuranceState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindInsuranceSuccess: (state, action: PayloadAction<InsuranceInterface>) => ({
      ...state,
      isLoading: false,
      insurance: action.payload,
    }),
    callPutCancelInsuranceSuccess: (state) => ({
      ...state,
      isLoading: false,
      isCancelSuccess: true,
    }),
    callPutCancelResetInsuranceSuccess: (state) => ({
      ...state,
      isLoading: false,
      isCancelResetSuccess: true,
    }),
    callPutInsuranceInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callDeleteInsuranceSuccess: (state) => ({
      ...state,
      isLoading: false,
      isDeleteSuccess: true,
    }),
    callPutDeleteResetInsuranceSuccess: (state) => ({
      ...state,
      isLoading: false,
      isDeleteResetSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetInsurance: (state) => {
      const newState = { ...state }
      delete newState.insurance
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsCancelSuccess: (state) => ({
      ...state,
      isCancelSuccess: false,
    }),
    resetIsCancelResetSuccess: (state) => ({
      ...state,
      isCancelResetSuccess: false,
    }),
    resetIsDeleteSuccess: (state) => ({
      ...state,
      isDeleteSuccess: false,
    }),
    resetIsDeleteResetSuccess: (state) => ({
      ...state,
      isDeleteResetSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return insuranceState
    },
  },
})

export const fetchFindInsurance = (insuranceId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceModules.actions.resetError())
    dispatch(InsuranceModules.actions.callApiStart())
    const res = await callFindInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callFindInsuranceSuccess(res))
    dispatch(InsuranceModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    if (message == PERMISSION_ERROR_MESSAGE) {
      dispatch(push('/insurances'))
      dispatch(InsuranceModules.actions.resetError())
      dispatch(InsuranceModules.actions.resetIsLoading())
    } else {
      dispatch(
        InsuranceModules.actions.callApiError({
          status: status,
          error: error,
          message: message,
        })
      )
    }
  }
}

// 解約
export const fetchPutCancelInsurance = (
  insuranceId: string,
  params: InsuranceCancelRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceModules.actions.resetError())
    dispatch(InsuranceModules.actions.callApiStart())
    await callPutCancelInsurance(insuranceId, params)
    dispatch(InsuranceModules.actions.callPutCancelInsuranceSuccess())

    // 更新後のデータをセット
    dispatch(InsuranceModules.actions.callApiStart())
    const res = await callFindInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callFindInsuranceSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutCancelInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 解約の取り消し
export const fetchPutCancelResetInsurance = (insuranceId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceModules.actions.resetError())
    dispatch(InsuranceModules.actions.callApiStart())
    await callPutCancelResetInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callPutCancelResetInsuranceSuccess())

    // 更新後のデータをセット
    dispatch(InsuranceModules.actions.callApiStart())
    const res = await callFindInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callFindInsuranceSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutCancelResetInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 保険情報の変更処理
export const fetchPutInsuranceInfo = (
  insuranceId: string,
  params: PutInsuranceRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceModules.actions.resetIsChangeSuccess())
    dispatch(InsuranceModules.actions.resetError())
    dispatch(InsuranceModules.actions.callApiStart())
    await callPutInsuranceInfo(insuranceId, params)
    dispatch(InsuranceModules.actions.callPutInsuranceInfoSuccess())
    // 更新後のデータをセット
    dispatch(InsuranceModules.actions.callApiStart())
    const res = await callFindInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callFindInsuranceSuccess(res))

    // 履歴取得 実装するかわからないため一旦コメントアウト
    // dispatch(InsuranceModules.actions.callApiStart())
    // const insuranceAdminChangeHistoriesResponse = await callGetInsuranceAdminChangeHistories(
    //   insuranceId
    // )
    // dispatch(
    //   InsuranceAdminChangeHistoriesModules.actions.setInsuranceAdminChangeHistories(
    //     insuranceAdminChangeHistoriesResponse
    //   )
    // )
    dispatch(InsuranceModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutInsuranceInfo error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 削除
export const fetchDeleteInsurance = (insuranceId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceModules.actions.resetError())
    dispatch(InsuranceModules.actions.callApiStart())
    await callDeleteInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callDeleteInsuranceSuccess())

    // 更新後のデータをセット
    dispatch(InsuranceModules.actions.callApiStart())
    const res = await callFindInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callFindInsuranceSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchDeleteInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 削除の取り消し
export const fetchPutDeleteResetInsurance = (insuranceId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceModules.actions.resetError())
    dispatch(InsuranceModules.actions.callApiStart())
    await callPutDeleteResetInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callPutDeleteResetInsuranceSuccess())

    // 更新後のデータをセット
    dispatch(InsuranceModules.actions.callApiStart())
    const res = await callFindInsurance(insuranceId)
    dispatch(InsuranceModules.actions.callFindInsuranceSuccess(res))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutDeleteResetInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// TODO: 以下、必要になったら有効化・修正
// export const fetchUpdateInsurancePeriod = (
//   id: string,
//   params: UpdateInsurancePeriodRequestParams
// ): AppThunk => async (dispatch) => {
//   try {
//     dispatch(InsuranceModules.actions.resetIsChangeSuccess())
//     dispatch(InsuranceModules.actions.callApiStart())
//     dispatch(InsuranceModules.actions.resetError())
//     await callUpdateInsurancePeriod(id, params)
//     dispatch(InsuranceModules.actions.resetIsLoading())
//     dispatch(InsuranceModules.actions.callPutInsuranceInfoSuccess())
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     console.log('fetchUpdateInsurancePeriod error')
//     console.log(e)
//
//     const status = e.response && e.response.status ? e.response.status : 500
//     const error =
//       e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
//     const message =
//       e.response && e.response.data && e.response.data.message
//         ? e.response.data.message
//         : INTERNAL_SERVER_ERROR_MESSAGE
//     dispatch(
//       InsuranceModules.actions.callApiError({
//         status: status,
//         error: error,
//         message: message,
//       })
//     )
//   }
// }
//
// // 証券番号割り当て
// export const fetchPutInsurancePolicyNumber = (
//   insuranceId: string,
//   policyNumberId: number
// ): AppThunk => async (dispatch) => {
//   try {
//     dispatch(InsuranceModules.actions.resetIsChangeSuccess())
//     dispatch(InsuranceModules.actions.resetError())
//     dispatch(InsuranceModules.actions.callApiStart())
//     await callPutInsurancePolicyNumber(insuranceId, policyNumberId)
//     dispatch(InsuranceModules.actions.resetIsLoading())
//
//     // 更新後のデータをセット
//     dispatch(
//       fetchFindInsurances({
//         contractorName: '',
//         contractPeriodStartFrom: null,
//         contractPeriodStartTo: null,
//         contractPeriodEndFrom: null,
//         contractPeriodEndTo: null,
//         policyNumber: '',
//         contractorId: '',
//         insuranceProduct: '',
//       })
//     )
//     dispatch(fetchGetPolicyNumbers())
//     dispatch(InsuranceModules.actions.callPutInsuranceInfoSuccess())
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     console.log('fetchPutInsurancePolicyNumber error')
//     console.log(e)
//
//     const status = e.response && e.response.status ? e.response.status : 500
//     const error =
//       e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
//     const message =
//       e.response && e.response.data && e.response.data.message
//         ? e.response.data.message
//         : INTERNAL_SERVER_ERROR_MESSAGE
//     dispatch(
//       InsuranceModules.actions.callApiError({
//         status: status,
//         error: error,
//         message: message,
//       })
//     )
//   }
// }

export default InsuranceModules
