export interface EmailText {
  id: number
  platform_id: number
  title: string
  body: string
  destination: number
  type: number
  created_at: string
  updated_at: string
  // 削除日時
  deleted_at: string | null
}

/*
 * メール文書取得
 */
export interface FindEmailTextResponse {
  email_text: EmailText
}

/*
 * メール文書存在確認
 */
export interface CheckEmailTextExistResponse {
  email_text_count: number
}

/*
 * メール文書登録
 */
export interface PostEmailTextRequestParams {
  platform_id: number
  title: string
  body: string
  destination: number
  type: number
}

/*
 * メール文書更新
 */
export interface PutEmailTextRequestParams {
  title: string
  body: string
}

// メール送信先
export enum EmailTextsDestinationEnum {
  // お客様
  CUSTOMER = 1,
  // 営業店、代理店、保険サービス課など
  SALES_OFFICES_ETC = 2,
}

// メールタイプ 1:申込 2:事故連絡 3:契約変更 4:解約 5:お問い合わせ
export enum EmailTextsTypeEnum {
  APPLICATION = 1,
  ACCIDENT_REPORT = 2,
  CHANGE = 3,
  CANCEL = 4,
  INQUIRY = 5,
}
