import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import ApiError from '../../components/ApiError'
import styles from './styles.module.scss'
import { selectEmailTexts, selectPlatforms, selectLogin } from '../../store/selector'
import { fetchGetEmailTexts, fetchTruncateEmailText } from 'store/emailTexts'
import { fetchGetPlatforms } from 'store/platforms'
import Title from '../../components/Title'
import { EMAI_TEXT_DESTINATION, EMAI_TEXT_TYPE } from '../../const'
import { CognitoGroupNameEnum } from '../../types/login'

const EmailTexts = () => {
  const dispatch = useDispatch()
  const EmailTextsSelector = useSelector(selectEmailTexts)
  const PlatformsSelector = useSelector(selectPlatforms)
  const loginSelector = useSelector(selectLogin)

  // const moveNewPage = () => {
  //   dispatch(push('/email_text/new'))
  // }

  const moveEditPage = (id: number) => {
    dispatch(push(`/email_text/${id}/edit`))
  }

  useEffect(() => {
    dispatch(fetchGetEmailTexts())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchGetPlatforms())
  }, [dispatch])

  // 全削除ボタン
  const handleTruncateSubmit = async () => {
    dispatch(fetchTruncateEmailText())
    setTruncateModalOpen(false)
  }

  const [truncateModalOpen, setTruncateModalOpen] = useState(false)

  const handleCloseTruncateModal = () => {
    setTruncateModalOpen(false)
  }

  const handleOpenTruncateModal = () => {
    setTruncateModalOpen(true)
  }

  return (
    <div data-page="EmailTexts">
      <Container maxWidth="lg" className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            <Title title="メール文書一覧" isCenter={true} />
          </Grid>
        </Grid>
        <Box className={styles.newCreateBtn}>
          {loginSelector.cognitoGroupName === CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME &&
            ['local', 'development', 'staging'].includes(process.env.REACT_APP_ENV) && (
              <Button
                onClick={() => {
                  handleOpenTruncateModal()
                }}
                color="secondary"
                variant="contained"
                data-section="new"
              >
                全削除
              </Button>
            )}
          <Dialog
            open={truncateModalOpen}
            onClose={handleCloseTruncateModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'メール文書全削除'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                メール文書を全削除します。 よろしいでしょうか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTruncateModal} variant="contained" color="secondary">
                閉じる
              </Button>
              <Button onClick={handleTruncateSubmit} variant="contained" color="primary">
                全削除
              </Button>
            </DialogActions>
          </Dialog>
          {/* 削除機能を廃止したため、登録機能も不要になった */}
          {/* <Button onClick={moveNewPage} color="primary" variant="contained" data-section="new">
            新規作成
          </Button> */}
        </Box>
        <Box>
          {EmailTextsSelector.error ? (
            <div data-section="get-api-error">
              <ApiError
                error={EmailTextsSelector.error.error}
                message={EmailTextsSelector.error.message}
                status={EmailTextsSelector.error.status}
              />
            </div>
          ) : EmailTextsSelector.isLoading ? (
            <CircularProgress />
          ) : (
            <Box mt={3}>
              <Container maxWidth="lg">
                <TableContainer component={Paper} className={styles.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>プラットフォーム名</TableCell>
                        <TableCell>送信先</TableCell>
                        <TableCell>タイプ</TableCell>
                        <TableCell>操作</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!EmailTextsSelector.emailTexts?.length ? (
                        <TableRow>
                          <TableCell align="center" colSpan={9}>
                            メール文書データがありません
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {EmailTextsSelector.emailTexts.map((data) => {
                            return (
                              <TableRow key={data.id} data-section={`row${data.id}`}>
                                <TableCell data-section="id">{data.id}</TableCell>
                                {PlatformsSelector.platforms.map((platformsData) => {
                                  return (
                                    platformsData.id == data.platform_id && (
                                      <TableCell data-section="platform-name">
                                        {platformsData.platform_name}
                                      </TableCell>
                                    )
                                  )
                                })}
                                <TableCell data-section="destination">
                                  {EMAI_TEXT_DESTINATION[data.destination]}
                                </TableCell>
                                <TableCell data-section="type">
                                  {EMAI_TEXT_TYPE[data.type]}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => moveEditPage(data.id)}
                                    color="primary"
                                    variant="contained"
                                    data-section="edit"
                                  >
                                    編集
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  )
}

export default EmailTexts
