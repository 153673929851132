import { Box, Button, CircularProgress, Container, Grid, TextField } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { push } from 'connected-react-router'
import { DATETIME_FORMAT } from 'const'
import { format } from 'date-fns'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import PlatformModules, {
  fetchGetPlatform,
  fetchPostPlatformInfo,
  fetchPutPlatformInfo,
} from '../../store/platform'
import { selectPlatform, selectLogin } from '../../store/selector'
import { PostPlatformRequestParams, PutPlatformRequestParams } from '../../types/platform'
import { CognitoGroupNameEnum } from '../../types/login'
import {
  changePlatformInfoSchema,
  getErrorAsObject,
  registerPlatformInfoSchema,
} from '../../utils/validate'
import styles from './styles.module.scss'

const PlatformsInput = () => {
  const dispatch = useDispatch()
  const { platformId } = useParams<{ platformId: string }>()
  // プラットフォーム名
  const [platform_name, setPlatformName] = useState('')
  // 加入者番号識別子
  const [identifier, setIdentifier] = useState('')

  const platformSelector = useSelector(selectPlatform)
  const loginSelector = useSelector(selectLogin)

  // バリデーションエラー用
  const [errors, setErrors] = useState<
    {
      [key in keyof PutPlatformRequestParams]?: string
    }
  >({})

  // 新規作成フラグ
  const location = useLocation()
  const newFlag = location.pathname.match('/new') ? 1 : 0

  useEffect(() => {
    if (platformId) {
      dispatch(fetchGetPlatform(platformId))
    }
  }, [dispatch])

  // プラットフォーム変更成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (platformSelector.isChangeSuccess) {
      setTimeout(() => {
        dispatch(PlatformModules.actions.resetIsChangeSuccess())
      }, 5000)
    }
  }, [platformSelector.isChangeSuccess])

  // プラットフォーム登録成功時の成功メッセージのリセット処理
  useEffect(() => {
    if (platformSelector.isRegistSuccess) {
      setTimeout(() => {
        dispatch(PlatformModules.actions.resetIsRegistSuccess())
      }, 5000)
    }
  }, [platformSelector.isRegistSuccess])

  useLayoutEffect(() => {
    if (loginSelector.cognitoGroupName !== CognitoGroupNameEnum.COGNITO_ADMIN_GROUP_NAME) {
      dispatch(push({ pathname: '/platforms' }))
    }
    dispatch(PlatformModules.actions.resetPlatform())
  }, [location.pathname])

  // 取得した後にパラメータを設定
  useEffect(() => {
    if (platformSelector) {
      // プラットフォーム
      setPlatformName(platformSelector?.platform?.platform_name || '')
      setIdentifier(platformSelector?.platform?.identifier || '')
    }
  }, [platformSelector.platform])

  const moveListPage = () => {
    // 画面遷移時にはstoreのプラットフォーム名をリセット
    dispatch(push({ pathname: '/platforms' }))
  }

  const getPostParams = (): PostPlatformRequestParams => {
    return {
      platform_name: platform_name,
      identifier: identifier,
    }
  }

  const getPutParams = (): PutPlatformRequestParams | boolean => {
    if (!platformSelector.platform) {
      return false
    }
    return {
      platform_name: platform_name,
      identifier: identifier,
    }
  }

  // 登録ボタン
  const handlePostSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPostCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPostParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPostPlatformInfo(params))
    }
  }

  // 更新ボタン
  const handlePutSubmit = async () => {
    // 送信前にもう一度バリデーションチェックを行う
    const isValid = await validationPutCheck()
    if (!isValid) {
      console.log('validation error')
      return
    }
    const params = getPutParams()
    if (typeof params !== 'boolean') {
      dispatch(fetchPutPlatformInfo(platformId, params))
    }
  }

  // 登録ボタン実行時のバリデーション
  const validationPostCheck = async (): Promise<boolean> => {
    const params = getPostParams()
    console.log(params)
    try {
      await registerPlatformInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  // 更新ボタン実行時のバリデーション
  const validationPutCheck = async (): Promise<boolean> => {
    const params = getPutParams()
    try {
      await changePlatformInfoSchema.validate(params, { abortEarly: false })
      // バリデーションエラーをリセット
      setErrors({})
      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // バリデーションエラー
      setErrors(getErrorAsObject(e))
      return false
    }
  }

  return (
    <div data-page="platform-input">
      <Container className={styles.root}>
        <Grid container>
          <Grid item xs={12}>
            {newFlag ? (
              <Title title="プラットフォーム登録" isCenter={true} />
            ) : (
              <Title title="プラットフォーム編集" isCenter={true} />
            )}
          </Grid>
        </Grid>
        <Box component="form" className={styles.formBox}>
          {platformSelector.error ? (
            <div data-section="api-error">
              <ApiError
                error={platformSelector.error.error}
                message={platformSelector.error.message}
                status={platformSelector.error.status}
              />
            </div>
          ) : (
            <>
              {platformSelector.isLoading && <CircularProgress />}
              {!newFlag && !platformSelector.platform ? (
                <p data-section="no-items">プラットフォーム情報が見つかりません</p>
              ) : (
                <Grid container>
                  {platformSelector.isChangeSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>変更が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {platformSelector.isRegistSuccess && (
                    <Grid container>
                      <Grid item xs={12} className={styles.success}>
                        <p data-section="complete">
                          <CheckCircleIcon />
                          <span>登録が完了しました。</span>
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <form>
                      <Box className={styles.inputWrap}>
                        <TextField
                          className={
                            platform_name !== platformSelector.platform?.platform_name
                              ? styles.isChange
                              : ''
                          }
                          label="プラットフォーム名"
                          type="text"
                          data-section="platform"
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}
                          value={platform_name}
                          onChange={(e) => setPlatformName(e.target.value)}
                          error={!!errors.platform_name}
                          helperText={errors.platform_name}
                        />
                        {platformSelector.platform?.platform_name &&
                          platform_name !== platformSelector.platform?.platform_name && (
                            <small data-section="prev-platform-name">
                              <br />
                              変更前プラットフォーム名：{platformSelector.platform?.platform_name}
                            </small>
                          )}
                      </Box>
                      <Box className={styles.inputWrap}>
                        <TextField
                          className={
                            identifier !== platformSelector.platform?.identifier
                              ? styles.isChange
                              : ''
                          }
                          label="加入者番号識別子"
                          type="text"
                          data-section="identifier"
                          variant="outlined"
                          inputProps={{ maxLength: 2 }}
                          value={identifier}
                          onChange={(e) => setIdentifier(e.target.value)}
                          error={!!errors.identifier}
                          helperText={errors.identifier}
                        />
                        {platformSelector.platform?.identifier &&
                          identifier !== platformSelector.platform?.identifier && (
                            <small data-section="prev-identifier">
                              <br />
                              変更前加入者番号識別子：{platformSelector.platform?.identifier}
                            </small>
                          )}
                      </Box>
                      {!newFlag && (
                        <Grid container className={styles.timeInfoContainer}>
                          <Grid item xs={12}>
                            <p data-section="created_at">
                              <span>作成日時：</span>
                              {format(
                                new Date(platformSelector.platform?.created_at || ''),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p data-section="updated_at">
                              <span>更新日時：</span>
                              {format(
                                new Date(platformSelector.platform?.updated_at || ''),
                                DATETIME_FORMAT
                              )}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </form>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button onClick={() => moveListPage()} variant="contained" data-section="back">
                戻る
              </Button>
              {!platformSelector.error && (
                <>
                  {newFlag ? (
                    <Button
                      onClick={() => {
                        handlePostSubmit()
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="post"
                      data-section="register"
                    >
                      登録する
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handlePutSubmit()
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="post"
                      data-section="register"
                    >
                      更新する
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default PlatformsInput
