import { Box, Button, CircularProgress, Container, Grid, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DialogTitle from '@material-ui/core/DialogTitle'
import { push } from 'connected-react-router'
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  INSURANCE_ESIRED_START_DATE,
  INSURANCE_IS_SALES_NAME,
  INSURANCE_PRODUCT_NAME,
} from 'const'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ApiError from '../../components/ApiError'
import Title from '../../components/Title'
import InsuranceModules, {
  fetchDeleteInsurance,
  fetchFindInsurance,
  fetchPutDeleteResetInsurance,
} from '../../store/insurance'
import { selectInsurance } from '../../store/selector'
import { checkExpirationTime, checkOnTheDay } from '../../utils/utils'
import styles from './styles.module.scss'

const Insurance = () => {
  const dispatch = useDispatch()
  const insuranceSelector = useSelector(selectInsurance)
  const { insuranceId } = useParams<{ insuranceId: string }>()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const moveBackPage = () => {
    dispatch(push('/insurances'))
  }

  const moveInsuranceEditPage = () => {
    dispatch(push(`/insurance/${insuranceId}/edit`))
  }

  const moveInsuranceCancelPage = () => {
    dispatch(push(`/insurance/${insuranceId}/cancel`))
  }

  // 削除（+取り消し）確認ダイアログを表示
  const handleDeleteDialogOpen = () => {
    setIsDeleteDialogOpen(true)
  }

  // 削除（+取り消し）確認ダイアログを非表示
  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false)
  }

  // 契約削除実行
  const handleDelete = () => {
    // ダイアログを非表示
    setIsDeleteDialogOpen(false)
    // 削除実行
    dispatch(fetchDeleteInsurance(insuranceId))
  }

  // 契約削除取り消し実行
  const handleDeleteReset = () => {
    // ダイアログを非表示
    setIsDeleteDialogOpen(false)
    // 削除取り消し実行
    dispatch(fetchPutDeleteResetInsurance(insuranceId))
  }

  // 削除成功ダイアログの非表示
  const handleDeleteSuccessDialogClose = () => {
    // 削除成功フラグをリセット
    dispatch(InsuranceModules.actions.resetIsDeleteSuccess())
  }

  // 削除取り消し成功ダイアログの非表示
  const handleDeleteResetSuccessDialogClose = () => {
    // 削除取り消し成功フラグをリセット
    dispatch(InsuranceModules.actions.resetIsDeleteResetSuccess())
  }

  // TODO: 必要になったら有効化・修正
  // const handleChangeContractPeriodStart = (date: string) => {
  //   insurance && setInsurance({ ...insurance, contract_period_start: date })
  // }
  //
  // const handleChangeContractPeriodEnd = (date: string) => {
  //   insurance && setInsurance({ ...insurance, contract_period_end: date })
  // }
  //
  // const handleUpdateInsurancePeriod = () => () => {
  //   insurance &&
  //     dispatch(
  //       fetchUpdateInsurancePeriod(insurance.id, {
  //         contractPeriodStart: insurance.contract_period_start,
  //         contractPeriodEnd: insurance.contract_period_end,
  //       })
  //     )
  // }

  useEffect(() => {
    dispatch(fetchFindInsurance(insuranceId))
  }, [dispatch])

  // APIエラーが発生したらページ上部にスクロールし、エラー確認できるようにする
  useEffect(() => {
    if (insuranceSelector.error) {
      window.scrollTo(0, 0)
    }
  }, [insuranceSelector.error])

  return (
    <div data-page="insurance">
      <Container className={styles.root} fixed>
        <Grid container>
          <Grid item xs={12}>
            <Title title="保険商品詳細" />
          </Grid>
        </Grid>
        {insuranceSelector.error && (
          <div data-section="api-error">
            <ApiError
              error={insuranceSelector.error.error}
              message={insuranceSelector.error.message}
              status={insuranceSelector.error.status}
            />
          </div>
        )}
        {insuranceSelector.isLoading && <CircularProgress />}
        {!insuranceSelector.insurance ? (
          <p data-section="no-items">保険商品情報が見つかりません。</p>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} className={styles.forms}>
                <h3>保険契約情報</h3>
                <TextField
                  label="ID"
                  type="text"
                  value={insuranceSelector.insurance.id}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="insurance-id"
                />
                <TextField
                  label="会員番号"
                  type="text"
                  value={insuranceSelector.insurance.member_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="member-id"
                />
                <TextField
                  label="加入者番号"
                  type="text"
                  value={insuranceSelector.insurance.contractor_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="contractor-id"
                />
                <TextField
                  label="代理店コード"
                  type="text"
                  value={insuranceSelector.insurance.agency_id}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="agency-id"
                />
                <TextField
                  label="証券番号"
                  type="text"
                  value={insuranceSelector.insurance.policy_number?.policy_number || '未割り当て'}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="policy-number"
                />
                <TextField
                  label="業種"
                  type="text"
                  value={insuranceSelector.insurance.industry}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="industry"
                />
                <TextField
                  label="売上高(百万円)"
                  type="text"
                  value={insuranceSelector.insurance.sales_amount}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="sales-amount"
                />
                <TextField
                  label="氏名(漢字)"
                  type="text"
                  value={insuranceSelector.insurance.contractor_name}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="contractor-name"
                />
                <TextField
                  label="法人名"
                  type="text"
                  value={insuranceSelector.insurance.company_name}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="company-name"
                />
                <TextField
                  label="郵便番号"
                  type="text"
                  value={insuranceSelector.insurance.zip}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="zip"
                />
                <TextField
                  label="住所"
                  type="text"
                  value={insuranceSelector.insurance.address}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="address"
                />
                <TextField
                  label="ご担当者名"
                  type="text"
                  value={insuranceSelector.insurance.manager_name}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="manager-name"
                />
                <TextField
                  label="電話番号"
                  type="text"
                  value={insuranceSelector.insurance.phone}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="phone"
                />
                <TextField
                  label="メールアドレス"
                  type="text"
                  value={insuranceSelector.insurance.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="email"
                />
                <TextField
                  label="登録日時"
                  type="text"
                  value={format(new Date(insuranceSelector.insurance.created_at), DATETIME_FORMAT)}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="contractor-created-at"
                />
                <TextField
                  label="更新日時"
                  type="text"
                  value={format(new Date(insuranceSelector.insurance.updated_at), DATETIME_FORMAT)}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="contractor-updated-at"
                />
                <TextField
                  label={insuranceSelector.insurance.deleted_at ? '削除日時' : ''}
                  placeholder={insuranceSelector.insurance.deleted_at ? '' : '削除日時'}
                  type="text"
                  value={
                    insuranceSelector.insurance.deleted_at
                      ? format(new Date(insuranceSelector.insurance.deleted_at), DATETIME_FORMAT)
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="contractor-deleted-at"
                />
                <Box className={styles.button}>
                  <Button
                    onClick={moveInsuranceEditPage}
                    color="primary"
                    variant="contained"
                    data-section="cancel"
                  >
                    契約情報変更
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} className={styles.forms}>
                <h3>
                  保険商品情報
                  {insuranceSelector.insurance.deleted_at ? (
                    <span className={styles.status} data-section="status">
                      削除されました
                    </span>
                  ) : (insuranceSelector.insurance.cancelled_at &&
                      checkExpirationTime(insuranceSelector.insurance.cancelled_at)) ||
                    (insuranceSelector.insurance.cancelled_at &&
                      checkOnTheDay(
                        insuranceSelector.insurance.application_date,
                        insuranceSelector.insurance.cancelled_at
                      )) ? (
                    <span className={styles.status} data-section="status">
                      解約されました
                    </span>
                  ) : insuranceSelector.insurance.contract_period_end &&
                    checkExpirationTime(insuranceSelector.insurance.contract_period_end) ? (
                    <span className={styles.status} data-section="status">
                      契約満了
                    </span>
                  ) : insuranceSelector.insurance.cancelled_at ? (
                    <span className={styles.status} data-section="status">
                      解約申込がされています
                    </span>
                  ) : (
                    <span className={styles.status} data-section="status">
                      契約中
                    </span>
                  )}
                </h3>
                {/* TODO: 証券番号の取得 */}
                <TextField
                  label="証券番号"
                  type="text"
                  value={insuranceSelector.insurance?.policy_number.policy_number || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="policy-number"
                />
                <TextField
                  label="保険商品名"
                  type="text"
                  value={INSURANCE_PRODUCT_NAME[insuranceSelector.insurance.insurance_product]}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="insurance-product"
                />
                <TextField
                  label="保険料 (円)"
                  type="text"
                  value={insuranceSelector.insurance.fee_amount}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="fee-amount"
                />
                <TextField
                  label="申込日"
                  type="text"
                  value={format(
                    new Date(insuranceSelector.insurance.application_date),
                    DATE_FORMAT
                  )}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="application-date"
                />
                <TextField
                  label="保険始期"
                  type="text"
                  value={
                    INSURANCE_ESIRED_START_DATE[
                      insuranceSelector.insurance.esired_insurance_start_date
                    ]
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="esired-insurance-start-date"
                />
                {/* prodでテストしているため機能を使えるようにした */}
                {/* <KeyboardDatePicker
                  label="契約期間開始"
                  className={styles['date-form']}
                  variant="inline"
                  format="yyyy年MM月dd日"
                  value={insuranceSelector.insurance.contract_period_start}
                  onChange={(date: MaterialUiPickersDate) =>
                    handleChangeContractPeriodStart(date ? format(date, 'yyyy-MM-dd') : '')
                  }
                  autoOk
                />
                <KeyboardDatePicker
                  label="契約期間終了"
                  className={styles['date-form']}
                  variant="inline"
                  format="yyyy年MM月dd日"
                  value={insuranceSelector.insurance.contract_period_end}
                  onChange={(date: MaterialUiPickersDate) =>
                    handleChangeContractPeriodEnd(date ? format(date, 'yyyy-MM-dd') : '')
                  }
                  autoOk
                /> */}
                <TextField
                  label="契約期間開始"
                  className={styles['date-form']}
                  type="text"
                  value={format(
                    new Date(insuranceSelector.insurance.contract_period_start),
                    DATE_FORMAT
                  )}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="契約期間終了"
                  className={styles['date-form']}
                  type="text"
                  value={format(
                    new Date(insuranceSelector.insurance.contract_period_end),
                    DATE_FORMAT
                  )}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label={insuranceSelector.insurance.cancelled_at ? '解約日' : ''}
                  placeholder={insuranceSelector.insurance.cancelled_at ? '' : '解約日'}
                  type="text"
                  value={
                    insuranceSelector.insurance.cancelled_at
                      ? format(new Date(insuranceSelector.insurance.cancelled_at), DATE_FORMAT)
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="cancelled-apply-date"
                />
                <TextField
                  label="登録日時"
                  type="text"
                  value={format(new Date(insuranceSelector.insurance.created_at), DATETIME_FORMAT)}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="insurance-created-at"
                />
                <TextField
                  label="更新日時"
                  type="text"
                  value={format(new Date(insuranceSelector.insurance.updated_at), DATETIME_FORMAT)}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="insurance-updated-at"
                />
                <TextField
                  label={insuranceSelector.insurance.deleted_at ? '削除日時' : ''}
                  placeholder={insuranceSelector.insurance.deleted_at ? '' : '削除日時'}
                  type="text"
                  value={
                    insuranceSelector.insurance.deleted_at
                      ? format(new Date(insuranceSelector.insurance.deleted_at), DATETIME_FORMAT)
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="insurance-deleted-at"
                />
                <TextField
                  label="決済状態"
                  type="text"
                  value={INSURANCE_IS_SALES_NAME[insuranceSelector.insurance.is_sales]}
                  InputProps={{
                    readOnly: true,
                  }}
                  data-section="insurance-sales-status"
                />
                <Box className={styles.button}>
                  {!insuranceSelector.insurance.deleted_at && (
                    <Button
                      onClick={moveInsuranceCancelPage}
                      color="primary"
                      variant="contained"
                      data-section="cancel"
                    >
                      解約
                    </Button>
                  )}
                  <Button
                    onClick={handleDeleteDialogOpen}
                    color="primary"
                    variant="contained"
                    data-section="cancel"
                  >
                    {insuranceSelector.insurance.deleted_at ? '削除取り消し' : '削除'}
                  </Button>
                  {/* prodでテストしているため機能を使えるようにした */}
                  {/* <Button
                    onClick={handleUpdateInsurancePeriod()}
                    color="secondary"
                    variant="contained"
                    data-section="update"
                  >
                    契約期間変更(テスト用)
                  </Button> */}
                  {/*{process.env.REACT_APP_ENV !== 'production' && (*/}
                  {/*  <Button*/}
                  {/*    onClick={handleUpdateInsurancePeriod()}*/}
                  {/*    color="secondary"*/}
                  {/*    variant="contained"*/}
                  {/*    data-section="update"*/}
                  {/*  >*/}
                  {/*    契約期間変更(テスト用)*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                </Box>
              </Grid>
            </Grid>

            {/* 削除確認ダイアログ */}
            <Dialog
              open={isDeleteDialogOpen}
              onClose={handleDeleteDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {insuranceSelector.insurance.deleted_at ? '契約の削除取り消し' : '契約の削除'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {insuranceSelector.insurance.deleted_at ? '契約の削除取り消し' : '契約の削除'}
                  を実行します。
                  <br />
                  よろしいでしょうか？
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteDialogClose} variant="contained" color="secondary">
                  閉じる
                </Button>
                {insuranceSelector.insurance.deleted_at ? (
                  <Button onClick={handleDeleteReset} variant="contained" color="primary">
                    削除取り消し実行
                  </Button>
                ) : (
                  <Button onClick={handleDelete} variant="contained" color="primary">
                    削除実行
                  </Button>
                )}
              </DialogActions>
            </Dialog>

            {/* 削除完了ダイアログ */}
            <Dialog
              open={insuranceSelector.isDeleteSuccess}
              onClose={handleDeleteSuccessDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">契約削除完了</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  削除が成功しました。
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDeleteSuccessDialogClose}
                  variant="contained"
                  color="secondary"
                >
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
            {/* 削除取り消し完了ダイアログ */}
            <Dialog
              open={insuranceSelector.isDeleteResetSuccess}
              onClose={handleDeleteResetSuccessDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">契約削除取り消し完了</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  削除の取り消しが成功しました。
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDeleteResetSuccessDialogClose}
                  variant="contained"
                  color="secondary"
                >
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Box className={styles.buttonWrap}>
              <Button
                onClick={moveBackPage}
                color="primary"
                variant="contained"
                data-section="back"
              >
                保険商品一覧へ戻る
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Insurance
