import React from 'react'

// routing
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// store
import { setupStore } from 'store'
import { Provider, useSelector } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

// MaterialUI
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import jaLocale from 'date-fns/locale/ja'
import format from 'date-fns/format'

import './App.scss'
import axios from 'apis/axios'
import Login from './pages/Login'
import Auth from 'components/Auth'
import Header from 'components/Header'
import Insurances from './pages/Insurances'
import Insurance from './pages/Insurance'
import Change from './pages/Insurance/Change'
import PolicyNumbers from './pages/PolicyNumbers'
import PolicyNumbersInput from 'pages/PolicyNumbersInput'
import EmailTexts from './pages/EmailTexts'
import EmailText from './pages/EmailText'
import Platforms from './pages/Platforms'
import PlatformsInput from './pages/PlatformsInput'
import SalesOffices from './pages/SalesOffices'
import SalesOffice from './pages/SalesOffice'
import Agency from './pages/Agency'
import Agencies from './pages/Agencies'
import InsuranceOffice from './pages/InsuranceOffice'
import InsuranceOffices from './pages/InsuranceOffices'
import CsvDownload from 'pages/CsvDownload'
import CsvTemplates from 'pages/CsvTemplates'
import AuthChallengeSms from './pages/AuthChallengeSms'
import AuthChallenge from './pages/AuthChallenge'
import { selectLogin } from './store/selector'
import AdminCreateUser from './pages/Admin/CreateUser'
import ForgotPassword from './pages/ForgotPassword'
import ConfirmForgotPassword from './pages/ConfirmForgotPassword'
import AdminUsers from './pages/Admin/Users'
import InsuranceCancel from './pages/Insurance/Cancel'
import Error from './pages/Error'

const history = createBrowserHistory()

// store設定
const store = setupStore(history)
const pstore = persistStore(store)

// マテリアルUIの初期設定
const theme = createTheme({
  typography: {
    // buttonが大文字になるのを防ぐ
    button: {
      textTransform: 'none',
    },
  },
})

// DatePickerの日本語化
class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日', { locale: this.locale })
  }
}

// ログイン後のaxiosに対してheaderにAuthorizationを設定する
const InitApiAuth = () => {
  const loginSelector = useSelector(selectLogin)

  // intercept all requests on this axios instance
  axios.interceptors.request.use(function (config) {
    config.headers['Authorization'] = loginSelector.id_token
    return config
  })

  return null
}

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <PersistGate loading={<p>loading...</p>} persistor={pstore}>
            <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header />
                <Switch>
                  <Route exact path="/login" component={Login} />
                  {/* SMS認証 */}
                  <Route exact path="/auth_challenge_sms" component={AuthChallengeSms} />
                  {/* パスワード再設定 */}
                  <Route exact path="/auth_challenge" component={AuthChallenge} />
                  {/* パスワードリセットフロー */}
                  <Route exact path="/forgot_password" component={ForgotPassword} />
                  <Route exact path="/confirm_forgot_password" component={ConfirmForgotPassword} />
                  <Auth>
                    <InitApiAuth />
                    <Switch>
                      <Route exact path="/" component={Insurances} />
                      <Route exact path="/insurances" component={Insurances} />
                      <Route exact path="/insurance/:insuranceId" component={Insurance} />
                      <Route exact path="/csv_download" component={CsvDownload} />
                      <Route exact path="/csv_download/csv_templates" component={CsvTemplates} />
                      <Route exact path="/email_texts" component={EmailTexts} />
                      <Route exact path="/email_text/new" component={EmailText} />
                      <Route exact path="/email_text/:emailTextId/edit" component={EmailText} />
                      <Route exact path="/policy_numbers" component={PolicyNumbers} />
                      <Route exact path="/policy_number/new" component={PolicyNumbersInput} />
                      <Route
                        exact
                        path="/policy_number/:policyNumberId/edit"
                        component={PolicyNumbersInput}
                      />

                      <Route exact path="/platforms" component={Platforms} />
                      <Route exact path="/platform/new" component={PlatformsInput} />
                      <Route exact path="/platform/:platformId/edit" component={PlatformsInput} />
                      <Route exact path="/sales_offices/:platformId" component={SalesOffices} />
                      <Route exact path="/sales_office/:platformId/new" component={SalesOffice} />
                      <Route
                        exact
                        path="/sales_office/:platformId/:salesOfficeId/edit"
                        component={SalesOffice}
                      />
                      <Route exact path="/agencies/:platformId" component={Agencies} />
                      <Route exact path="/agency/:platformId/new" component={Agency} />
                      <Route exact path="/agency/:platformId/:agencyId/edit" component={Agency} />
                      <Route
                        exact
                        path="/insurance_offices/:platformId"
                        component={InsuranceOffices}
                      />
                      <Route
                        exact
                        path="/insurance_office/:platformId/new"
                        component={InsuranceOffice}
                      />
                      <Route
                        exact
                        path="/insurance_office/:platformId/:insuranceOfficeId/edit"
                        component={InsuranceOffice}
                      />
                      {/* appの方ではfaqをベタ書きにしている。機能復活する可能性もあるのでコメントアウト */}
                      {/* <Route exact path="/question_answers" component={QuestionAnswers} />

                      <Route exact path="/question_answer/new" component={QuestionAnswerCreate} />
                      <Route
                        exact
                        path="/question_answer/:questionAnswerId/edit"
                        component={QuestionAnswerEdit}
                      /> */}
                      <Route exact path="/insurance/:insuranceId/edit" component={Change} />
                      <Route
                        exact
                        path="/insurance/:insuranceId/cancel"
                        component={InsuranceCancel}
                      />
                      <Route exact path="/admin/create_user" component={AdminCreateUser} />
                      <Route exact path="/admin/users" component={AdminUsers} />
                      <Route exact path="/error" component={Error} />
                    </Switch>
                  </Auth>
                </Switch>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </ConnectedRouter>
      </Provider>
    </div>
  )
}

export default App
