// メッセージ関係
import {
  InsuranceIsSalesEnum,
  InsuranceProductEnum,
  EsiredInsuranceStartDateEnum,
} from './types/insurance'
import { EmailTextsDestinationEnum, EmailTextsTypeEnum } from './types/emailText'

export const INTERNAL_SERVER_ERROR_MESSAGE = 'サーバーエラーが発生しました。'
export const DUPLICATE_ENTRY_MESSAGE = '既に登録済みの証券番号です。'
export const PERMISSION_ERROR_MESSAGE = '権限がありません。'

// Dateを日本時間に合わせるための日付フォーマット
export const ISO_YYYYMMDD = 'yyyy-MM-dd'

export const DATETIME_FORMAT = 'yyyy年MM月dd日HH時mm分'
export const DATE_FORMAT = 'yyyy年MM月dd日'

// 口座情報
export const ACCOUNT_OPTIONS = [
  // 取り消し時に空にできるように空文字のoptionを追加
  { label: 'なし（取り消し時に使用）', value: '' },
  { label: '普通', value: '普通' },
  { label: '当座', value: '当座' },
]

// 保険商品
export const INSURANCE_PRODUCT_NAME = {
  [InsuranceProductEnum.LITE]: 'ライトプラン',
  [InsuranceProductEnum.STANDARD]: 'スタンダードプラン',
  [InsuranceProductEnum.PREMIUM]: 'プレミアムプラン',
}

// 決済状況
export const INSURANCE_IS_SALES_NAME = {
  [InsuranceIsSalesEnum.TEMPORARY_SALES]: '未入金',
  [InsuranceIsSalesEnum.ACTUAL_SALES]: '入金済',
  [InsuranceIsSalesEnum.CANCEL]: '解約済',
}

// 保険始期
export const INSURANCE_ESIRED_START_DATE = {
  [EsiredInsuranceStartDateEnum.TODAY]: '当月始期',
  [EsiredInsuranceStartDateEnum.NEXT_MONTH]: '翌月始期',
}

export const EMAI_TEXT_DESTINATION = {
  [Number(EmailTextsDestinationEnum.CUSTOMER)]: 'お客様',
  [Number(EmailTextsDestinationEnum.SALES_OFFICES_ETC)]: '営業店、代理店、保険サービス課など',
}

export const EMAI_TEXT_TYPE = {
  [Number(EmailTextsTypeEnum.APPLICATION)]: '申込',
  [Number(EmailTextsTypeEnum.ACCIDENT_REPORT)]: '事故連絡',
  [Number(EmailTextsTypeEnum.CHANGE)]: '契約変更',
  [Number(EmailTextsTypeEnum.CANCEL)]: '解約',
  [Number(EmailTextsTypeEnum.INQUIRY)]: 'お問い合わせ',
}

export const EMAI_TEXT_DESTINATION_ARRAY = [
  { id: EmailTextsDestinationEnum.CUSTOMER, name: 'お客様' },
  {
    id: EmailTextsDestinationEnum.SALES_OFFICES_ETC,
    name: '営業店、代理店、保険サービス課など',
  },
]

export const EMAI_TEXT_TYPE_ARRAY = [
  { id: Number(EmailTextsTypeEnum.APPLICATION), name: '申込' },
  { id: Number(EmailTextsTypeEnum.ACCIDENT_REPORT), name: '事故連絡' },
  { id: Number(EmailTextsTypeEnum.CHANGE), name: '契約変更' },
  { id: Number(EmailTextsTypeEnum.CANCEL), name: '解約' },
  { id: Number(EmailTextsTypeEnum.INQUIRY), name: 'お問い合わせ' },
]
