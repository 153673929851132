import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from './error'
import { AppThunk } from './index'
import { INTERNAL_SERVER_ERROR_MESSAGE } from '../const'
import { InsuranceOffice } from '../types/insuranceOffice'
import {
  callFindInsuranceOffice,
  callPutInsuranceOfficeInfo,
  callPostInsuranceOfficeInfo,
} from '../utils/apis/insuranceOffice'
import {
  PutInsuranceOfficeRequestParams,
  PostInsuranceOfficeRequestParams,
} from '../types/insuranceOffice'

export interface InsuranceOfficeState {
  isLoading: boolean
  isChangeSuccess: boolean
  isRegistSuccess: boolean
  insuranceOffice?: InsuranceOffice
  error?: ErrorResponse
}

export const InsuranceOfficeState: InsuranceOfficeState = {
  isLoading: false,
  isChangeSuccess: false,
  isRegistSuccess: false,
}

const InsuranceOfficeModules = createSlice({
  name: 'insuranceOffice',
  initialState: InsuranceOfficeState,
  reducers: {
    callApiStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    callFindInsuranceOfficeSuccess: (state, action: PayloadAction<InsuranceOffice>) => ({
      ...state,
      isLoading: false,
      insuranceOffice: action.payload,
    }),
    callPutInsuranceOfficeInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isChangeSuccess: true,
    }),
    callPostInsuranceOfficeInfoSuccess: (state) => ({
      ...state,
      isLoading: false,
      isRegistSuccess: true,
    }),
    callApiError: (state, action: PayloadAction<ErrorResponse>) => ({
      ...state,
      isLoading: false,
      error: action.payload,
    }),
    resetError: (state) => {
      const newState = { ...state }
      delete newState.error
      return newState
    },
    resetInsuranceOffice: (state) => {
      const newState = { ...state }
      delete newState.insuranceOffice
      return newState
    },
    resetIsChangeSuccess: (state) => ({
      ...state,
      isChangeSuccess: false,
    }),
    resetIsRegistSuccess: (state) => ({
      ...state,
      isRegistSuccess: false,
    }),
    resetIsLoading: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetAll: () => {
      return InsuranceOfficeState
    },
  },
})

//営業店情報の取得処理
export const fetchGetInsuranceOffice = (
  platformId: string,
  insuranceOfficeId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceOfficeModules.actions.resetError())
    dispatch(InsuranceOfficeModules.actions.callApiStart())
    const res = await callFindInsuranceOffice(platformId, insuranceOfficeId)
    dispatch(InsuranceOfficeModules.actions.callFindInsuranceOfficeSuccess(res))

    dispatch(InsuranceOfficeModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchFindInsurance error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceOfficeModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 営業店情報の変更処理
export const fetchPutInsuranceOfficeInfo = (
  platformId: string,
  insuranceOfficeId: string,
  params: PutInsuranceOfficeRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceOfficeModules.actions.resetError())
    dispatch(InsuranceOfficeModules.actions.callApiStart())
    await callPutInsuranceOfficeInfo(platformId, insuranceOfficeId, params)
    dispatch(InsuranceOfficeModules.actions.callPutInsuranceOfficeInfoSuccess())

    // 更新後に営業店データをセットする
    const res = await callFindInsuranceOffice(platformId, insuranceOfficeId)
    dispatch(InsuranceOfficeModules.actions.callFindInsuranceOfficeSuccess(res))
    dispatch(InsuranceOfficeModules.actions.resetIsLoading())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutInsuranceOffice error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceOfficeModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

// 営業店情報の登録処理
export const fetchPostInsuranceOfficeInfo = (
  platformId: string,
  params: PostInsuranceOfficeRequestParams
): AppThunk => async (dispatch) => {
  try {
    dispatch(InsuranceOfficeModules.actions.resetIsChangeSuccess())
    dispatch(InsuranceOfficeModules.actions.resetError())
    dispatch(InsuranceOfficeModules.actions.callApiStart())
    await callPostInsuranceOfficeInfo(platformId, params)
    dispatch(InsuranceOfficeModules.actions.callPostInsuranceOfficeInfoSuccess())

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log('fetchPutInsuranceOffice error')
    console.log(e)

    const status = e.response && e.response.status ? e.response.status : 500
    const error =
      e.response && e.response.data && e.response.data.error ? e.response.data.error : ''
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : INTERNAL_SERVER_ERROR_MESSAGE
    dispatch(
      InsuranceOfficeModules.actions.callApiError({
        status: status,
        error: error,
        message: message,
      })
    )
  }
}

export default InsuranceOfficeModules
